import numeral from 'numeral';

class Category {
  constructor(
    id,
    title,
    SumFeedbackCount,
    AvgRating,
    Products,
    SumSellQty,
    SumRevenue,
    parentId,
    url
  ) {
    this.id = id;
    this.title = title;
    this.SumFeedbackCount = SumFeedbackCount;
    this.AvgRating = AvgRating;
    this.Products = Products;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
    this.parentId = parentId;
    this.url = url;
  }
}

export default Category;

// export const newCategory = (category) => {
//   return new Category(
//     category.id,
//     category.name,
//     category.parentId,
//     category.stringId,
//     category.title,
//     category.url,
//     false
//   );
// };

export const newCategory = (cat) => {
  return new Category(
    cat.id,
    cat.title ? cat.title : cat.name,
    cat.SumFeedbackCount === null ? 0 : cat.SumFeedbackCount,
    numeral(cat.AvgRating).format(`0.00`),
    cat.Products,
    cat.SumSellQty === null ? 0 : cat.SumSellQty,
    cat.SumRevenue === null ? 0 : cat.SumRevenue,
    cat.parentId,
    cat.url
  );
};
