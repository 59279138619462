import React from 'react';
import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import { storeSessionChart } from '../chart/chartOptions';

const StatisticTop = ({ products, sales, topBrands, topProducts }) => {
  const Options = {
    ...storeSessionChart.options,
    colors: ['#348fe2'],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: '#348fe2',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#8753de',
            opacity: 1,
          },
        ],
      },
    },
  };

  return (
    <div className="row">
      <div
        className="col-xl-6 col-lg-12"
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
      >
        <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
          <div className="card-body" style={{ padding: ' 36px 25px' }}>
            <div className="row">
              <div className="col-xl-7 col-lg-8">
                <div className="mb-3 text-grey" style={{ fontSize: '1rem' }}>
                  <b>Товары</b>
                  <span className="ml-2">
                    <i className="fa fa-info-circle" id="popover1"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover1"
                    >
                      <PopoverHeader>{`Товары за ${products.length} дней`}</PopoverHeader>
                      <PopoverBody>
                        Вы можете увидеть как меняется количество товаров за
                        период
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="d-flex" style={{ marginBottom: '1rem' }}>
                  <h2 style={{ display: 'flex' }} className="mb-0">
                    {' '}
                    <CountUp
                      formattingFn={(value) =>
                        numeral(value).format(`0,000,000`)
                      }
                      end={products[products.length - 1]}
                    />{' '}
                    {products[products.length - 2] ===
                    products[products.length - 1] ? null : products[
                        products.length - 2
                      ] < products[products.length - 1] ? (
                      <span className="text-success pulse">
                        <i className="fa fa-arrow-up m-l-5"></i>
                      </span>
                    ) : (
                      <span className="text-danger pulse">
                        <i className="fa fa-arrow-down m-l-5"></i>
                      </span>
                    )}
                  </h2>
                  <div className="ml-auto mt-n1 mb-n1">
                    <Chart
                      type="line"
                      height={'36px'}
                      width="180px"
                      options={Options}
                      series={[{ data: products }]}
                    />
                  </div>
                </div>
                <div className="mb-3 text-grey" style={{ fontSize: '12px' }}>
                  {products[products.length - 1] - products[0] > 0 ? (
                    <span className="text-success">
                      <i className="fa fa-caret-up m-l-5"></i>
                    </span>
                  ) : (
                    <span className="text-danger">
                      <i className="fa fa-caret-down m-l-5"></i>
                    </span>
                  )}{' '}
                  {`${numeral(
                    (100 -
                      products[products.length - 1] / (products[0] / 100)) *
                      -1
                  ).format('00.00')}%  роста за период`}
                </div>

                <hr className="bg-white-transparent-2" />

                <div className="mb-3 text-grey" style={{ fontSize: '1rem' }}>
                  <b>Продажи</b>
                  <span className="ml-2">
                    <i className="fa fa-info-circle" id="popover2"></i>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="popover2"
                    >
                      <PopoverHeader>{`Продажи за ${sales.length} дней`}</PopoverHeader>
                      <PopoverBody>
                        Вы можете увидеть как меняется количество продаж за
                        период
                      </PopoverBody>
                    </UncontrolledPopover>
                  </span>
                </div>
                <div className="d-flex " style={{ marginBottom: '1rem' }}>
                  <h2 style={{ display: 'flex' }} className="mb-0">
                    {' '}
                    <CountUp
                      formattingFn={(value) =>
                        numeral(value).format(`0,000,000`)
                      }
                      end={sales[sales.length - 1]}
                    />{' '}
                    {sales[sales.length - 2] ===
                    sales[sales.length - 1] ? null : sales[sales.length - 2] <
                      sales[sales.length - 1] ? (
                      <span className="text-success ">
                        <i className="fa fa-arrow-up pulse  m-l-5"></i>
                      </span>
                    ) : (
                      <span className="text-danger ">
                        <i className="fa fa-arrow-down pulse  m-l-5"></i>
                      </span>
                    )}
                  </h2>
                  <div className="ml-auto mt-n1 mb-n1">
                    <Chart
                      type="line"
                      height={'36px'}
                      width="180px"
                      options={Options}
                      series={[{ data: sales }]}
                    />
                  </div>
                </div>
                <div className="mb-3 text-grey" style={{ fontSize: '12px' }}>
                  {sales[sales.length - 1] - sales[0] > 0 ? (
                    <span className="text-success">
                      <i className="fa fa-caret-up "></i>
                    </span>
                  ) : (
                    <span className="text-danger">
                      <i className="fa fa-caret-down"></i>
                    </span>
                  )}{' '}
                  {`${numeral(
                    (100 - sales[sales.length - 1] / (sales[0] / 100)) * -1
                  ).format('00.00')}%  роста за период`}
                </div>
              </div>
              <div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center">
                <img
                  src="/assets/img/svg/img-1.svg"
                  alt=""
                  height="150px"
                  className="d-none d-lg-block stat_image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////Brands */}

      <div className="col-xl-3 col-lg-6">
        <div className="card border-0 bg-dark-darker text-white mb-3 p-1">
          <div
            className="card-body"
            style={{
              background: 'no-repeat bottom right',
              backgroundImage: 'url(/assets/img/svg/img-4.svg)',
              backgroundSize: 'auto 60%',
            }}
          >
            <div className="mb-3 text-grey">
              <b>Топ-5 Брендов</b>
              <span className="text-grey ml-2">
                <i className="fa fa-info-circle" id="popover6"></i>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target="popover6"
                >
                  <PopoverHeader>Топ-5 Брендов</PopoverHeader>
                  <PopoverBody>
                    Список брендов продавца Wildberries с самой большой выручкой
                    за вчерашний день
                  </PopoverBody>
                </UncontrolledPopover>
              </span>
            </div>
            <h3 className="m-b-10">
              <CountUp
                formattingFn={(value) =>
                  `${numeral(value).format(`0,000,000`)} ₽`
                }
                end={topBrands.reduce((a, b) => a + parseInt(b.SumRevenue), 0)}
              />
            </h3>
            {/* <div className='text-grey m-b-1'>
              <i className='fa fa-caret-up'></i> 45.76% increased
            </div> */}
          </div>
          <div className="widget-list widget-list-rounded inverse-mode">
            {topBrands.map((brand, i) => {
              return (
                <Link
                  key={i}
                  to={`/wildberries/brands/products/${brand.id}`}
                  className={`widget-list-item rounded-0 p-t-3 topBrands topBrands--${i}`}
                  style={{ paddingBottom: '3.5px', display: 'flex' }}
                >
                  <div
                    style={{ marginTop: '7px', width: '45px' }}
                    className="widget-img rounded-lg  height-30  m-l-10 bg-white p-3"
                  >
                    <div
                      className="h-100 w-100"
                      style={{
                        background: `url(${brand.image}) center no-repeat`,
                        backgroundSize: 'auto 75%',
                      }}
                    ></div>
                  </div>

                  <div
                    style={{ overflow: 'hidden', flex: '1' }}
                    className="widget-list-content"
                  >
                    <div
                      className="widget-list-title "
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {brand.name}
                    </div>
                  </div>
                  <div
                    className="widget-list-action text-nowrap text-grey"
                    style={{ width: '35%', fontSize: '12px' }}
                  >
                    {`${numeral(brand.SumRevenue).format(`0,000,000`)} ₽`}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////Products */}
      <div className="col-xl-3 col-lg-6">
        <div className="card border-0 bg-dark text-white mb-3">
          <div className="card-body" style={{ paddingBottom: '21px' }}>
            <div className="mb-3 text-grey">
              <b>Топ-5 товаров</b>
              <span className="ml-2">
                <i className="fa fa-info-circle" id="popover7"></i>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target="popover7"
                >
                  <PopoverHeader>Топ-5 товаров</PopoverHeader>
                  <PopoverBody>
                    Список самых покупаемых товаров продавца Wildberries за
                    вчерашний день
                  </PopoverBody>
                </UncontrolledPopover>
              </span>
            </div>
            {topProducts.map((prod, i) => {
              return (
                <Link
                  style={{ color: '#fff' }}
                  key={i}
                  to={`/wildberries/item/${prod.id}`}
                >
                  <div
                    className={` topProducts topProducts--${i} ${
                      i === 4 ? '' : 'm-b-15'
                    }`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className="widget-img rounded-lg width-30 m-r-10 bg-white p-3">
                      <div
                        className="h-100 w-100"
                        style={{
                          background: `url(${prod.images[0].small}) center no-repeat`,
                          backgroundSize: 'auto 100%',
                        }}
                      ></div>
                      {/* <div style={{ width: '30px', height: '40px' }}> */}
                      {/* <img
                        src={prod.images[0].small}
                        width={'100%'}
                        alt='logo'
                      /> */}
                    </div>

                    <div style={{ width: '100%' }} className="text-truncate">
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {prod.name}
                      </div>
                      <div className="text-grey">{`${numeral(
                        prod.finishPrice
                      ).format(`0,000,000`)} ₽`}</div>
                    </div>
                    <div className="ml-auto text-center">
                      <div className="f-s-13">{prod.lastSellQty}</div>
                      <div className="text-grey f-s-10">Продано</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StatisticTop);
