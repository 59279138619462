import React from 'react';
import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import { storeSessionChart } from '../chart/chartOptions';

const StatisticNavigation = ({ brands, categories, stocks, sellers }) => {
  const BrandsOptions = {
    ...storeSessionChart.options,
    colors: ['#fff'],
    // chart: {
    //   background: '#dee2e6',
    // border-radius: 10px;
    // overflow: hidden;
    // },
  };

  const CategoriesOptions = {
    ...storeSessionChart.options,
    // colors: ['#32a932'],
    colors: ['#fff'],
  };

  const SellersOptions = {
    ...storeSessionChart.options,
    // colors: ['#fb5597'],
    colors: ['#fff'],
  };

  return (
    <div className="row">
      <div className="col-xl-3 col-sm-6">
        <div className="widget widget-stats bg-red">
          {/* <div className='stats-icon'>
            <i className='fa fa-desktop'></i>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="stats-info m-r-20">
              <h4 style={{ display: 'flex', fontSize: '15px' }}>
                Категории{' '}
                <span className="ml-2">
                  <i className="fa fa-info-circle" id="popover11"></i>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target="popover11"
                  >
                    <PopoverHeader>{`Категории за ${categories.length} дней`}</PopoverHeader>
                    <PopoverBody>
                      Вы можете увидеть как меняется количество категорий за
                      период
                    </PopoverBody>
                  </UncontrolledPopover>
                </span>
              </h4>

              <p>
                {' '}
                <CountUp
                  formattingFn={(value) => numeral(value).format(`0,000,000`)}
                  end={categories[categories.length - 1]}
                />
              </p>
            </div>
            <Chart
              height={'60px'}
              width="200px"
              type="line"
              options={CategoriesOptions}
              series={[{ data: categories }]}
            />
          </div>
          <div className="stats-link">
            <Link to="/wildberries/categories">
              Подробнее <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="widget widget-stats bg-orange">
          {/* <div className='stats-icon'>
            <i className='fa fa-link'></i>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="stats-info m-r-10">
              <h4 style={{ display: 'flex', fontSize: '17px' }}>
                Бренды{' '}
                <span className="ml-2">
                  <i className="fa fa-info-circle" id="popover12"></i>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target="popover12"
                  >
                    <PopoverHeader>{`Бренды за ${brands.length} дней`}</PopoverHeader>
                    <PopoverBody>
                      Вы можете увидеть как меняется количество брендов за
                      период
                    </PopoverBody>
                  </UncontrolledPopover>
                </span>
              </h4>
              <p>
                {' '}
                <CountUp
                  formattingFn={(value) => numeral(value).format(`0,000,000`)}
                  end={brands[brands.length - 1]}
                />
              </p>
            </div>
            <Chart
              height={'60px'}
              width="200px"
              type="line"
              options={BrandsOptions}
              series={[{ data: brands }]}
            />
          </div>

          <div className="stats-link">
            <Link to="/wildberries/brands">
              Подробнее <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="widget widget-stats bg-grey-darker">
          {/* <div className='stats-icon'>
            <i className='fa fa-users'></i>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="stats-info m-r-10">
              <h4 style={{ display: 'flex', fontSize: '17px' }}>
                Продавцы{' '}
                <span className="ml-2">
                  <i className="fa fa-info-circle" id="popover13"></i>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target="popover13"
                  >
                    <PopoverHeader>{`Продавцы за ${sellers.length} дней`}</PopoverHeader>
                    <PopoverBody>
                      Вы можете увидеть как меняется количество продавцов за
                      период
                    </PopoverBody>
                  </UncontrolledPopover>
                </span>
              </h4>
              <p>
                {' '}
                <CountUp
                  formattingFn={(value) => numeral(value).format(`0,000,000`)}
                  end={sellers[sellers.length - 1]}
                />
              </p>
            </div>
            <Chart
              height={'60px'}
              width="200px"
              type="line"
              options={SellersOptions}
              series={[{ data: sellers }]}
            />
          </div>

          <div className="stats-link">
            <Link to="/wildberries/sellers">
              Подробнее <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="widget widget-stats bg-black-lighter">
          <div
            className="stats-icon"
            style={{ color: '#ccc', marginRight: '2rem' }}
          >
            <i className="fa fa-cubes" style={{ fontSize: '70px' }}></i>
          </div>
          <div className="stats-info m-r-10">
            <h4 style={{ display: 'flex', fontSize: '17px' }}>
              Склады{' '}
              <span className="ml-2">
                <i className="fa fa-info-circle" id="popover14"></i>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target="popover14"
                >
                  <PopoverHeader>{`Склады`}</PopoverHeader>
                  <PopoverBody>
                    Количество доступных складов на данный момент
                  </PopoverBody>
                </UncontrolledPopover>
              </span>
            </h4>
            <p>
              {' '}
              <CountUp end={+stocks} />
            </p>
          </div>

          <div className="stats-link">
            <Link to="/wildberries/stocks">
              Подробнее <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StatisticNavigation);
