import React, { useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';

import AdminPage from './pages/users/AdminPage.js';
import AdminRoute from './routers/AdminRoute.js';
import AdvRoute from './routers/AdvRoute.js';
import BaseRoute from './routers/BaseRoute.js';
import BrandsPage from './pages/wildberries/BrandsPage.js';
import BrandPage from './pages/wildberries/BrandPage.js';
import BillsPage from './pages/users/BillsPage.js';
import CatergoriesPage from './pages/wildberries/CategoriesPage.js';
import DemoFormPage from './pages/DemoFormPage.js';
import FailedPage from './pages/tariffs/FailedPage.js';
import FavoritePage from './pages/wildberries/FavoritePage.js';
import Footer from './components/footer/footer.jsx';
import Header from './components/header/header.jsx';
import LoginPage from './pages/LoginPage.js';
import MainPage from './pages/MainPage.js';
import NotAvailablePage from './pages/errors/NotAvailablePage.js';
import NotFoundPage from './pages/errors/NotFoundPage.js';
import NoSearchResultsPage from './pages/errors/NoSearchResultsPage.js';
import PayBillPage from './pages/tariffs/PayBillPage.js';
import ProductsPage from './pages/wildberries/ProductsPage.js';
import ProductPage from './pages/wildberries/ProductPage.js';
import ProfRoute from './routers/ProfRoute.js';
import PrivacyPage from './pages/termsAndPrivacy/PrivacyPage.js';
import PrivateRoute from './routers/PrivateRoute.js';
import TariffPage from './pages/tariffs/TariffPage.js';
import TermsPage from './pages/termsAndPrivacy/TermsPage.js';
import TestShowPage from './pages/users/TestShowPage.js';
import UserPage from './pages/users/UserPage';
import UserChangePage from './pages/users/UserChangePage.js';
import UserCreatePage from './pages/users/UserCreatePage.js';
import RegistrationPage from './pages/RegistrationPage.js';
import RecoveryPage from './pages/RecoveryPage';
import Sidebar from './components/sidebar/sidebar.jsx';
import SellersPage from './pages/wildberries/SellersPage.js';
import SellerPage from './pages/wildberries/SellerPage.js';
import StocksPage from './pages/wildberries/StocksPage.js';
import ScrollToTop from './components/scrollTop/scrollToTop.js';
import SuccessPage from './pages/tariffs/SuccessPage.js';
import SearchPage from './pages/wildberries/SearchPage.js';
import NewPage from './pages/wildberries/NewPage.js';

export const history = createBrowserHistory();

const App = () => {
  const [hasScroll] = useState(false);
  const [sideBarToogle, setSideBarToogle] = useState(false);
  const sidebar = useSelector((state) => !!state.dspl.pageSidebar);
  const header = useSelector((state) => !!state.dspl.pageHeader);
  const footer = useSelector((state) => !!state.dspl.pageFooter);

  const contentFullWidth = useSelector(
    (state) => !!state.dspl.pageContentFullWidth
  );
  const contentFullHeight = useSelector(
    (state) => !!state.dspl.pageContentFullHeight
  );

  const handlerSidebar = (toogle) => {
    setSideBarToogle(toogle);
  };

  return (
    <div
      className={`fade page-sidebar-fixed show page-container 
          ${header ? 'page-header-fixed ' : ''}
          ${sidebar ? '' : 'page-without-sidebar '}
          ${sideBarToogle ? 'page-sidebar-toggled ' : ''}
          ${contentFullWidth ? 'pcontent-full-width  ' : ''}
          ${contentFullHeight ? 'page-content-full-height ' : ''}
          ${hasScroll ? 'has-scroll ' : ''}
         
				`}
    >
      <Router history={history}>
        <div>
          {header && <Header handlerSidebar={handlerSidebar} />}
          {sidebar && <Sidebar />}
          <div
            className={`content 
             ${contentFullWidth ? 'content-full-width ' : ''}`}
          >
            <Switch>
              <Route
                path='/'
                exact={true}
                component={() => <Redirect to='/main' />}
              />
              <PrivateRoute path='/main' component={MainPage} />
              <Route path='/login' component={LoginPage} />
              <Route path='/register' component={RegistrationPage} />
              <Route path='/password_recovery' component={RecoveryPage} />
              <Route path='/tariffs' component={TariffPage} />
              <Route path='/demo' component={DemoFormPage} />
              <Route path='/terms' component={TermsPage} />
              <Route path='/privacy' component={PrivacyPage} />
              <Route path='/presentation_for_resume' component={TestShowPage} />
              <PrivateRoute path='/pay_success' component={SuccessPage} />
              <PrivateRoute path='/pay_fail' component={FailedPage} />
              <PrivateRoute
                path='/bill/:id'
                exact={true}
                component={PayBillPage}
              />
              <PrivateRoute path='/user' exact={true} component={UserPage} />

              <PrivateRoute
                path='/user/bills'
                exact={true}
                component={BillsPage}
              />
              <AdminRoute
                path='/user/create'
                exact={true}
                component={UserCreatePage}
              />
              <AdminRoute
                path='/user/:id'
                exact={true}
                component={UserChangePage}
              />
              <Route path='/admin'>
                <AdminRoute
                  path='/admin/users_list'
                  exact={true}
                  component={AdminPage}
                />
                <AdminRoute
                  path='/admin/bills_list'
                  exact={true}
                  component={AdminPage}
                />
              </Route>

              <AdvRoute
                path='/wildberries/brands'
                exact
                component={BrandsPage}
              />
              <Route path='/wildberries/brands'>
                <AdvRoute
                  path='/wildberries/brands/products/:id'
                  component={BrandPage}
                />
                <AdvRoute
                  path='/wildberries/brands/categories/:id'
                  component={BrandPage}
                />
                <AdvRoute
                  path='/wildberries/brands/sellers/:id'
                  component={BrandPage}
                />
                <AdvRoute
                  path='/wildberries/brands/history/:id'
                  component={BrandPage}
                />
                <AdvRoute
                  path='/wildberries/brands/trends/:id'
                  component={BrandPage}
                />
              </Route>
              <BaseRoute
                path='/wildberries/categories'
                exact
                component={CatergoriesPage}
              />
              <Route path='/wildberries/categories/'>
                <BaseRoute
                  path='/wildberries/categories/products/:id'
                  exact={true}
                  component={ProductsPage}
                />
                <BaseRoute
                  path='/wildberries/categories/subcategories/:id'
                  exact={true}
                  component={ProductsPage}
                />
                <BaseRoute
                  path='/wildberries/categories/brands/:id'
                  exact={true}
                  component={ProductsPage}
                />
                <BaseRoute
                  path='/wildberries/categories/sellers/:id'
                  exact={true}
                  component={ProductsPage}
                />
                <BaseRoute
                  path='/wildberries/categories/trends/:id'
                  exact={true}
                  component={ProductsPage}
                />
                <BaseRoute
                  path='/wildberries/categories/history/:id'
                  exact={true}
                  component={ProductsPage}
                />
              </Route>
              <BaseRoute
                path='/wildberries/favorites'
                exact
                component={FavoritePage}
              />
              <ProfRoute path='/wildberries/search'
                exact
                component={SearchPage}/>
              <ProfRoute path='/wildberries/new'
                exact
                component={NewPage}/>
              <ProfRoute
                path='/wildberries/sellers'
                exact
                component={SellersPage}
              />
              <Route path='/wildberries/sellers'>
                <ProfRoute
                  path='/wildberries/sellers/products/:id'
                  exact={true}
                  component={SellerPage}
                />
                <ProfRoute
                  path='/wildberries/sellers/categories/:id'
                  exact={true}
                  component={SellerPage}
                />
                <ProfRoute
                  path='/wildberries/sellers/brands/:id'
                  exact={true}
                  component={SellerPage}
                />
                <ProfRoute
                  path='/wildberries/sellers/history/:id'
                  exact={true}
                  component={SellerPage}
                />
                <ProfRoute
                  path='/wildberries/sellers/trends/:id'
                  exact={true}
                  component={SellerPage}
                />
              </Route>

              <BaseRoute
                path='/wildberries/item/:id'
                exact={true}
                component={ProductPage}
              />
              <PrivateRoute path='/wildberries/stocks' component={StocksPage} />
              <Route path='/notavailable' component={NotAvailablePage} />
              <Route path='/notfound' component={NoSearchResultsPage} />
              <Route exact component={NotFoundPage} />
            </Switch>
          </div>
          <ScrollToTop />
          {footer && <Footer />}
        </div>
      </Router>
    </div>
  );
};

export default React.memo(App);
