export const SETPAGESIDEBAR = 'SETPAGESIDEBAR';
export const SETPAGEHEADER = 'SETPAGEHEADER';
export const SETPAGECONTENTFULLWIDTH = 'SETPAGECONTENTFULLWIDTH';
export const SETPAGECONTENTFULLHEIGHT = 'SETPAGECONTENTFULLHEIGHT';
export const SETPAGEFOOTER = 'SETPAGEFOOTER';

export const setPageSidebar = (value) => {
  return { type: SETPAGESIDEBAR, payload: value };
};

export const setPageHeader = (value) => {
  return { type: SETPAGEHEADER, payload: value };
};

export const setPageContentFullWidth = (value) => {
  return { type: SETPAGECONTENTFULLWIDTH, payload: value };
};

export const setPageFooter = (value) => {
  return { type: SETPAGEFOOTER, payload: value };
};

export const setPageContentFullHeight = (value) => {
  return { type: SETPAGECONTENTFULLHEIGHT, payload: value };
};
