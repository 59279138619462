import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../components/error/errorNotification';
import { history } from '../app';
import Loader from '../components/loaders/Loader';
import { login } from '../store/actions/auth';

import * as displayActions from '../store/actions/display';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      history.push('/main');
    }
  }, [isLogin]);

  useEffect(() => {
    dispatch(displayActions.setPageSidebar(false));
    dispatch(displayActions.setPageHeader(false));
    dispatch(displayActions.setPageFooter(false));
    dispatch(displayActions.setPageContentFullWidth(true));
    return () => {
      dispatch(displayActions.setPageSidebar(true));
      dispatch(displayActions.setPageHeader(true));
      dispatch(displayActions.setPageFooter(true));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(login(email, password, remember));
      setIsLoading(false);
      history.push('/main');
    } catch (err) {
      setPassword('');
      error('Неверный пароль или адрес');
      setIsLoading(false);
    }
  };

  return (
    <div className='login login-v1'>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='login-container'>
        <div className='login-header'>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <div className='brand'>
              {/* <span className='logo'></span> */}
              <b>Market</b> Profit
              <small></small>
            </div>
          </Link>

          <div className='icon'>
            <i className='fa fa-lock'></i>
          </div>
        </div>
        <div className='login-body'>
          <div className='login-content'>
            <form className='margin-bottom-0' onSubmit={submitHandler}>
              <div className='form-group m-b-20'>
                <input
                  type='text'
                  className='form-control form-control-lg inverse-mode'
                  placeholder='Email адрес'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div className='form-group m-b-20'>
                <input
                  type='password'
                  className='form-control form-control-lg inverse-mode'
                  placeholder='Пароль'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              </div>

              <div className='checkbox checkbox-css m-b-20'>
                <input
                  value={remember}
                  onChange={(e) => {
                    setRemember(!remember);
                  }}
                  defaultChecked={remember}
                  type='checkbox'
                  id='remember_checkbox'
                />
                <label htmlFor='remember_checkbox'>Запомнить меня</label>
              </div>
              <div className='login-buttons'>
                <button
                  type='submit'
                  className='btn btn-success btn-block btn-lg'
                  onSubmit={submitHandler}
                >
                  Войти
                </button>
                <p>Или зарегистрироваться </p>
                <Link to='/register'>
                  <button className='btn btn-success btn-block btn-lg'>
                    Регистрация
                  </button>
                </Link>
                <p>
                  Или восстановить пароль, если вы его забыли,
                  <Link to='/password_recovery'>
                    <span> тут</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
