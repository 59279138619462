import marketprofit from '../../apis/marketprofit';

export const REQUESTS_AVAILABLE = 'REQUESTS_AVAILABLE';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY_INFO = 'GET_CATEGORY_INFO';
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const CLEAR_SUBCATEGORIES = 'CLEAR_SUBCATEGORIES';
export const GET_SUBCATEGORIES_BRANDS = 'GET_SUBCATEGORIES_BRANDS';
export const GET_SUBCATEGORIES_HISTORY = 'GET_SUBCATEGORIES_HISTORY';
export const GET_SUBCATEGORIES_SELLERS = 'GET_SUBCATEGORIES_SELLERS';
export const GET_SUBCATEGORIES_TRENDS = 'GET_SUBCATEGORIES_TRENDS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_BRANDS = 'GET_BRANDS';
export const SEARCH_BRANDS = 'SEARCH_BRANDS';
export const GET_BRAND_INFO = 'GET_BRAND_INFO';
export const GET_BRANDS_PRODUCTS = 'GET_BRANDS_PRODUCTS';
export const GET_BRANDS_CATEGORIES = 'GET_BRANDS_CATEGORIES';
export const GET_BRANDS_HISTORY = 'GET_BRANDS_HISTORY';
export const GET_BRANDS_SELLERS = 'GET_BRANDS_SELLERS';
export const GET_BRANDS_TRENDS = 'GET_BRANDS_TRENDS';
export const GET_SELLERS = 'GET_SELLERS';
export const SEARCH_SELLERS = 'SEARCH_SELLERS';
export const GET_SELLER_INFO = 'GET_SELLER_INFO';
export const GET_SELLERS_CATEGORIES = 'GET_SELLERS_CATEGORIES';
export const GET_SELLERS_BRANDS = 'GET_SELLERS_BRANDS';
export const GET_SELLERS_HISTORY = 'GET_SELLERS_HISTORY';
export const GET_SELLERS_PRODUCTS = 'GET_SELLERS_PRODUCTS';
export const GET_SELLERS_TRENDS = 'GET_SELLERS_TRENDS';
export const GET_STOCKS_REGIONS = 'GET_STOCKS_REGIONS';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const GET_FAVORITES = 'GET_FAVORITES';
export const DELETE_FAVORITES = 'DELETE_FAVORITES';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const FETCH_NEW = 'FETCH_NEW';

export const requestAvailable = () => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/wb/requests');

      if (!!response.data.success) {
        dispatch({ type: REQUESTS_AVAILABLE, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getCategories = (date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }
      const response = await marketprofit.get('/wb/categories', {
        params: {
          order_by: date_from ? 'SumRevenue' : null,
          dir: date_from ? 'desc' : null,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_CATEGORIES, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getCategoryInfo = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/category/${id}`);

      if (!!response.data.success) {
        dispatch({ type: GET_CATEGORY_INFO, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSubcategories = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      dispatch(clearSubcategories());

      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/category/${id}/categories`, {
        params: {
          order_by: date_from ? 'SumRevenue' : null,
          dir: date_from ? 'desc' : null,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({
          type: GET_SUBCATEGORIES,
          payload: response.data,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const clearSubcategories = () => {
  return { type: CLEAR_SUBCATEGORIES };
};

export const getBrands = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  filter
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }
      const response = await marketprofit.get(`/wb/brands`, {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({
          type: GET_BRANDS,
          payload: response.data,
          offset: response.config.params.offset,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSubcategoriesBrands = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/category/${id}/brands/`, {
        params: {
          limit: 100,
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_SUBCATEGORIES_BRANDS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSubcategoriesHistory = (id, date_from, date_to) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/category/${id}`, {
        params: {
          date_from,
          date_to,
        },
      });
      if (!!response.data.success) {
        dispatch({ type: GET_SUBCATEGORIES_HISTORY, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSubcategoriesSellers = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/category/${id}/sellers`, {
        params: {
          limit: 100,
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_SUBCATEGORIES_SELLERS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSubcategoriesTrends = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/category/${id}/trends`);

      if (!!response.data.success) {
        dispatch({ type: GET_SUBCATEGORIES_TRENDS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const searchBrands = (
  filter,
  limit,
  offset,
  searchBy,
  order_by,
  dir,
  date_from,
  date_to
) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/wb/brands', {
        params: {
          filter: searchBy ? `${filter}%` : `%${filter}%`,
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
        },
      });

      if (!!response.data.success) {
        dispatch({
          type: SEARCH_BRANDS,
          payload: response.data,
          offset: response.config.params.offset,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getProducts = (
  id,
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  filter
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/category/${id}/products`, {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({
          type: GET_PRODUCTS,
          payload: response.data,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getProduct = (id, date_from, date_to) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/product/${id}`, {
        params: {
          date_from,
          date_to,
        },
      });
      if (!!response.data.success) {
        dispatch({ type: GET_PRODUCT, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandInfo = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/brand/${id}`);

      if (!!response.data.success) {
        dispatch({ type: GET_BRAND_INFO, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandsProducts = (
  id,
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  filter
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`wb/brand/${id}/products`, {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });
      if (!!response.data.success) {
        dispatch({ type: GET_BRANDS_PRODUCTS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandsCategories = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/brand/${id}/categories`, {
        params: {
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_BRANDS_CATEGORIES, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandsHistory = (id, date_from, date_to) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/brand/${id}`, {
        params: {
          date_from,
          date_to,
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_BRANDS_HISTORY, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandsSellers = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/brand/${id}/sellers`, {
        params: {
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_BRANDS_SELLERS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getBrandsTrends = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/brand/${id}/trends`);

      if (!!response.data.success) {
        dispatch({ type: GET_BRANDS_TRENDS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellers = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  filter
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get('/wb/sellers', {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });
      if (!!response.data.success) {
        dispatch({
          type: GET_SELLERS,
          payload: response.data,
          offset: response.config.params.offset,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const searchSellers = (
  filter,
  limit,
  offset,
  searchBy,
  order_by,
  dir,
  date_from,
  date_to
) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/wb/sellers', {
        params: {
          filter: searchBy ? `${filter}%` : `%${filter}%`,
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
        },
      });

      if (!!response.data.success) {
        dispatch({
          type: SEARCH_SELLERS,
          payload: response.data,
          offset: response.config.params.offset,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellerInfo = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/seller/${id}`);

      if (!!response.data.success) {
        dispatch({ type: GET_SELLER_INFO, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellersCategories = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/seller/${id}/categories`, {
        params: {
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_SELLERS_CATEGORIES, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellersBrands = (id, date_from, date_to, filter) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/seller/${id}/brands`, {
        params: {
          limit: 100,
          order_by: 'SumRevenue',
          dir: 'desc',
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_SELLERS_BRANDS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellersHistory = (id, date_from, date_to) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/seller/${id}`, {
        params: {
          date_from,
          date_to,
        },
      });
      if (!!response.data.success) {
        dispatch({ type: GET_SELLERS_HISTORY, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellersProducts = (
  id,
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  filter
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      for (let fil in filter) {
        filter[fil].value &&
          filters.push({
            name: fil,
            operator: filter[fil].operator,
            value: filter[fil].value,
          });
      }

      const response = await marketprofit.get(`/wb/seller/${id}/products`, {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });
      if (!!response.data.success) {
        dispatch({ type: GET_SELLERS_PRODUCTS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getSellersTrends = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/seller/${id}/trends`);

      if (!!response.data.success) {
        dispatch({ type: GET_SELLERS_TRENDS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getStocksRegions = () => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/wb/regions');

      if (!!response.data.success) {
        dispatch({ type: GET_STOCKS_REGIONS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addFavorites = (ids) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.post(`/wb/favorities`, '', {
        params: {
          ids,
        },
      });
      if (!!response.data.success) {
        dispatch({ type: ADD_FAVORITES });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const getFavorites = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to
) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/wb/favorities/`, {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
        },
      });

      if (!!response.data.success) {
        dispatch({ type: GET_FAVORITES, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const deleteFavorites = (ids) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.delete(`/wb/favorities`, {
        params: {
          ids,
        },
      });

      if (!!response.data.success) {
        dispatch({ type: DELETE_FAVORITES });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const searchProduct = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  value
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      filters.push({
        name: 'name',
        operator: 'like',
        value: value,
      });

      const response = await marketprofit.get('/wb/products', {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (response.data.success) {
        dispatch({ type: SEARCH_PRODUCT, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const fetchNew = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  value,
  operator
) => {
  return async (dispatch) => {
    try {
      let filters = [];

      filters.push({
        name: 'firstseen',
        operator: operator,
        value: value,
      });

      const response = await marketprofit.get('/wb/products', {
        params: {
          limit,
          offset,
          order_by,
          dir,
          date_from,
          date_to,
          filters: JSON.stringify(filters),
        },
      });

      if (response.data.success) {
        dispatch({ type: FETCH_NEW, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};
