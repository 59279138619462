import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const FiltersModule = ({
  isOpen,
  setIsOpen,
  setFilterHandler,
  filters,
  setFilters,
  id,
}) => {
  return (
    <Modal
      modalClassName='modal-table'
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
    >
      <ModalBody>
        <div className='form-group row m-b-10'>
          <label className='col-md-3 col-form-label'>Выбор Фильтра</label>
          <div className='col-md-9'>
            <div className='radio radio-css is-valid'>
              <input
                onChange={(e) => setFilterHandler(e)}
                type='radio'
                name='radio_css'
                id='cssRadio1'
                value='=='
                checked={filters[id] === '=='}
              />
              <label htmlFor='cssRadio1'>
                Равно заданному значению(по умолчанию)
              </label>
            </div>
            <div className='radio radio-css is-valid'>
              <input
                onChange={(e) => setFilterHandler(e)}
                type='radio'
                name='radio_css'
                id='cssRadio6'
                checked={filters[id] === '>'}
                value='>'
              />
              <label htmlFor='cssRadio6'>Больше заданного значения</label>
            </div>
            <div className='radio radio-css is-valid '>
              <input
                onChange={(e) => setFilterHandler(e)}
                type='radio'
                name='radio_css'
                id='cssRadio2'
                checked={filters[id] === '>='}
                value='>='
              />
              <label htmlFor='cssRadio2'>
                Больше либо равно заданному значению
              </label>
            </div>
            <div className='radio radio-css is-valid '>
              <input
                onChange={(e) => setFilterHandler(e)}
                type='radio'
                name='radio_css'
                id='cssRadio3'
                checked={filters[id] === '<'}
                value='<'
              />
              <label htmlFor='cssRadio3'>Меньше заданного значения</label>
            </div>
            <div className='radio radio-css is-valid '>
              <input
                onChange={(e) => setFilterHandler(e)}
                type='radio'
                name='radio_css'
                id='cssRadio5'
                checked={filters[id] === '<='}
                value='<='
              />
              <label htmlFor='cssRadio5'>
                Меньше либо равно заданному значению
              </label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-primary'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Применить
        </button>
        <button
          className='btn btn-danger'
          onClick={() => {
            let newState = { ...filters };
            newState[id] = '==';
            setFilters(newState);
            setIsOpen(false);
          }}
        >
          Сбросить
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default FiltersModule;
