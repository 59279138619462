import numeral from 'numeral';

class BrandHistory {
  constructor(
    createdAt,
    SumFeedbackCount,
    AvgRating,
    Products,
    SumQty,
    SumQtyPrice,
    AvgPrice,
    AvgSellPrice,
    SumSellQty,
    SumRevenue
  ) {
    this.createdAt = createdAt;
    this.SumFeedbackCount = SumFeedbackCount;
    this.AvgRating = AvgRating;
    this.Products = Products;
    this.SumQty = SumQty;
    this.SumQtyPrice = SumQtyPrice;
    this.AvgPrice = AvgPrice;
    this.AvgSellPrice = AvgSellPrice;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
  }
}

export default BrandHistory;

export const newBrandHistory = (brand) => {
  return new BrandHistory(
    brand.createdAt,
    brand.SumFeedbackCount === null ? 0 : brand.SumFeedbackCount,
    numeral(brand.AvgRating).format(`0.00`),
    `${brand.Products}`,
    `${brand.SumQty}`,
    `${numeral(brand.SumQtyPrice).format(`0,000,000`)}`,
    `${numeral(brand.AvgPrice).format(`0,000,000`)}`,
    `${numeral(brand.AvgSellPrice).format(`0,000,000`)}`,
    brand.SumSellQty === null ? 0 : brand.SumSellQty,
    brand.SumRevenue === null ? 0 : brand.SumRevenue
  );
};
