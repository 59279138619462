import React from 'react';
import numeral from 'numeral';
import moment from 'moment';

export const productInfoColumns = [
  {
    Header: 'Information',
    Footer: '',

    columns: [
      {
        Header: 'Дата',
        accessor: 'createdAt',
        tipText: 'Дата',
        sortable: true,
        width: 100,

        Cell: ({ row }) => {
          return moment(row.values.createdAt).format('DD/MM/yyyy');
        },
      },

      {
        Header: 'Продано',
        accessor: 'sellQty',
        tipText: 'Продано',
        sortable: true,
        width: 160,
        Cell: ({ row }) => {
          return `${row.values.sellQty} шт.`;
        },
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => +row.values.sellQty + sum, 0),
            [info.rows]
          );

          return <>{`Итого ${total} шт.(за ${info.rows.length} дней)`}</>;
        },
      },
      {
        Header: 'Остаток',
        accessor: 'qty',
        tipText: 'Остаток на складах',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${row.values.qty} шт.`;
        },
      },
      {
        Header: 'Цена',
        accessor: 'price',
        tipText: 'Цена товара',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.price).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Со скидкой',
        accessor: 'basicPrice',
        tipText: 'Цена товара со скидкой',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.basicPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Промо-цена',
        accessor: 'promoPrice',
        tipText: 'Цена товара по промокоду',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.promoPrice).format(`0,000,000`)} ₽`;
        },
      },

      {
        Header: 'СПП',
        accessor: 'clientPrice',
        tipText: 'Скидка Постоянного Покупателя',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.clientPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Окончательная цена',
        tipText: 'Цена со всеми скидками',
        accessor: 'finishPrice',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.finishPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Выручка',
        accessor: 'revenue',
        tipText: 'Сумма выручки',
        sortable: true,
        width: 160,
        Cell: ({ row }) => {
          return `${numeral(row.values.revenue).format(`0,000,000`)} ₽`;
        },
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => +row.values.revenue + sum, 0),
            [info.rows]
          );

          return (
            <>{`Итого ${numeral(total).format(`0,000,000`)} ₽ (за ${
              info.rows.length
            } дней)`}</>
          );
        },
      },
    ],
  },
];
