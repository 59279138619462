import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';

export const brandsColumns = [
  {
    Header: 'Brands',

    columns: [
      {
        Header: '№',
        accessor: 'num',
        tipText: '№',
        disableSortBy: true,
        disableFilters: true,
        width: 50,
      },
      {
        Header: 'Лого',
        accessor: 'img',
        tipText: 'Логотип бренда',
        disableSortBy: true,
        disableFilters: true,
        width: 50,
        Cell: ({ row }) => {
          return <img src={row.values.img} height={20} alt="" />;
        },
      },

      {
        Header: 'Название',
        accessor: 'name',
        tipText: 'Название бренда',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <Link
              to={{
                pathname: `/wildberries/brands/products/${row.original.id}`,
                state: row.values.name,
              }}
            >
              {row.values.name}
            </Link>
          );
        },
      },

      {
        Header: 'Рейтинг',
        accessor: 'AvgRating',
        tipText: 'Рейтинг бренда',
        sortable: true,
        width: 75,
      },

      {
        Header: 'Комментарии',
        accessor: 'SumFeedbackCount',
        tipText: 'Количество комментариев бренда',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumFeedbackCount).format(`0,0`)} шт.`;
        },
      },
      {
        Header: 'Впервые появился',
        accessor: 'firstseen',
        tipText: 'Дата первого появления',
        sortable: true,
        width: 100,
        Cell: ({ row }) => {
          return moment(row.values.firstseen).format('DD/MM/yyyy');
        },
      },
      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Количество видов товаров бренда',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,0`)} ед.`;
        },
      },
      {
        Header: 'Продажи',
        accessor: 'SumSellQty',
        tipText: 'Сумма продаж за период',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,0`)} шт.`;
        },
      },
      {
        Header: 'Выручка за период',
        accessor: 'SumRevenue',
        tipText: 'Общая выручка за период',
        sortable: true,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'URL',
        accessor: 'url',
        tipText: 'Ссылка на wildberries',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <a href={row.values.url} target="_blank" rel="noreferrer">
              Wildberries
            </a>
          );
        },
      },
    ],
  },
];
