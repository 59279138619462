import numeral from 'numeral';
import moment from 'moment';

class Bill {
  constructor(id, user_id, tariff, code, period, status, amount, ctime) {
    this.id = id;
    this.user_id = user_id;
    this.tariff = tariff;
    this.code = code;
    this.period = period;
    this.status = status;
    this.amount = amount;
    this.ctime = ctime;
  }
}

export default Bill;

export const newBill = (bill) => {
  return new Bill(
    bill.id,
    bill.user_id,
    bill.tariff.title,
    bill.code ? bill.code : '-',
    bill.period,
    bill.status === 'payd' ? 'Оплачено' : 'Новый',
    `${numeral(bill.amount).format(`0,000,000`)}`,
    moment(bill.ctime).format(' DD/MM/YYYY ')
  );
};
