import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { calendarOptions } from '../calendar/filterCalendarOptions';
import moment from 'moment';
import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useFilters,
  useRowSelect,
} from 'react-table';
import { CSVLink } from 'react-csv';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import Calendar from '../calendar/calendar.js';
import FiltersModule from './filtersModule.js';

const Table = (props) => {
  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(() => props.columns, [props.columns]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');
  const [date_from, setDate_from] = useState(moment());
  const [date_to, setDate_to] = useState(moment());
  const [dateNew, setDateNew] = useState(moment().subtract(1, 'd'));
  const [calendarValue, setCalendarValue] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [activeDateRange, setActiveDateRange] = useState('1day');
  const [filters, setFilters] = useState({
    feedbackCount: '==',
    qty: '==',
    rating: '==',
    finishPrice: '==',
    clientPrice: '==',
    NumbDaysWithQty: '==',
    SumSellQty: '==',
    NumbDaysWithSell: '==',
    SumRevenue: '==',
    id: '==',
    AvgRating: '==',
    firstseen: '==',
    SumFeedbackCount: '==',
    AvgFeedbackCount: '==',
    Products: '==',
    name: 'like',
    brand: 'like',
    seller: 'like',
    title: 'like',
  });

  const { filterHandler, filterSelect } = props;

  const saveAll = async () => {
    try {
      setDownloading(true);
      await props.saveAll();
    } catch (e) {
      console.log(e.message);
    }
    setDownloading(false);
  };

  useEffect(() => {
    props.newDateHander && props.newDateHander(dateNew);
  }, [dateNew, props]);

  const handleDateApplyEvent = (event, picker) => {
    setDateNew(moment(picker.startDate));
  };

  useEffect(() => {
    switch (true) {
      case activeDateRange === '1day':
        setDateNew(moment().subtract(1, 'd'));

        setShowCalendar(false);
        return;
      case activeDateRange === '7days':
        setDateNew(moment().subtract(7, 'd'));

        setShowCalendar(false);

        return;
      case activeDateRange === '14days':
        setDateNew(moment().subtract(14, 'd'));

        setShowCalendar(false);

        return;
      case activeDateRange === 'my':
        setShowCalendar(true);
        return;

      default:
        return;
    }
  }, [activeDateRange]);

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const modelHandler = (id) => {
    setId(id);
    setIsOpen(true);
  };

  const filterAcceptHandler = (column) => {
    const allFilter =
      column.parent &&
      column.parent.columns.map((col) => {
        return {
          id: col.id,
          value:
            col.id === 'firstseen'
              ? calendarValue
                ? moment(calendarValue).format('YYYY-MM-DD')
                : ''
              : col.filterValue
              ? col.filterValue
              : '',
          operator: filters[col.id],
        };
      });

    filterHandler(allFilter);
    setId('');
  };

  const DefaultColumnFilter = useCallback(
    (props) => {
      const handleDateApplyEvent = (event, picker) => {
        setDate_from(moment(picker.startDate));
        setDate_to(moment(picker.endDate));
        setShowCalendar(true);

        filterHandler(props.column.id, {
          date_from: moment(picker.startDate).format(`YYYY-MM-DD`),
          date_to: moment(picker.endDate).format(`YYYY-MM-DD`),
        });
      };

      const clearHandler = (e, picker) => {
        setDate_from(moment());
        setDate_to(moment());
        setShowCalendar(false);

        filterHandler(props.column.id, {
          date_from: '',
          date_to: '',
        });
      };

      return (
        <>
          {props.column.id === 'role' && (
            <select
              className="form-control"
              value={props.column.filterValue || ''}
              onChange={(e) => {
                props.column.setFilter(e.target.value || '');

                filterSelect(props.column.id, e.target.value || '');
              }}
            >
              <option value="" defaultChecked>
                Все
              </option>
              <option value="client">Клиент</option>
              <option value="admin">Админ</option>
            </select>
          )}

          {props.column.id === 'tariff' && (
            <select
              className="form-control"
              value={props.column.filterValue || ''}
              onChange={(e) => {
                props.column.setFilter(e.target.value || '');
                filterSelect(props.column.id, e.target.value || '');
              }}
            >
              <option value="" defaultChecked>
                Все
              </option>
              <option value="base">Базовый</option>
              <option value="advanced">Расширенный</option>
              <option value="professional">Профессиональный</option>
            </select>
          )}

          {props.column.id === 'status' && (
            <select
              className="form-control"
              value={props.column.filterValue || props.billsPaid ? 'payd' : ''}
              onChange={(e) => {
                props.column.setFilter(e.target.value || '');
                filterSelect(props.column.id, e.target.value || '');
              }}
            >
              <option value="">Все</option>

              <option value="payd">Оплачено</option>

              <option value="new">Новый</option>
            </select>
          )}

          {props.column.id === 'ctime' && (
            <>
              <DateRangePicker
                initialSettings={{
                  autoUpdateInput: showCalendar,
                  startDate: date_from,
                  endDate: date_to,
                  ...calendarOptions,
                }}
                onApply={handleDateApplyEvent}
                onCancel={clearHandler}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </>
          )}

          {props.column.id !== 'role' &&
            props.column.id !== 'tariff' &&
            props.column.id !== 'ctime' &&
            props.column.id !== 'status' &&
            props.column.id !== 'firstseen' && (
              <>
                <input
                  className="form-control"
                  value={props.column.filterValue || ''}
                  onChange={(e) => {
                    if (
                      props.column.id === 'feedbackCount' ||
                      props.column.id === 'qty' ||
                      props.column.id === 'rating' ||
                      props.column.id === 'finishPrice' ||
                      props.column.id === 'clientPrice' ||
                      props.column.id === 'NumbDaysWithQty' ||
                      props.column.id === 'SumSellQty' ||
                      props.column.id === 'NumbDaysWithSell' ||
                      props.column.id === 'SumRevenue' ||
                      props.column.id === 'id' ||
                      props.column.id === 'AvgFeedbackCount' ||
                      props.column.id === 'SumFeedbackCount' ||
                      props.column.id === 'Products' ||
                      props.column.id === 'SumFeedbackCount' ||
                      props.column.id === 'id'
                    ) {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        return props.column.setFilter(e.target.value);
                      } else {
                        return;
                      }
                    } else if (props.column.id === 'AvgRating') {
                      const re = /^[0-9.]*$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        return props.column.setFilter(e.target.value);
                      } else {
                        return;
                      }
                    }

                    props.column.setFilter(e.target.value || '');
                  }}
                  style={{ padding: '0.4375rem 5px' }}
                />
              </>
            )}
        </>
      );
    },
    [filterHandler, filterSelect, date_to, date_from, showCalendar]
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 600,
      Filter: DefaultColumnFilter,
    }),
    [DefaultColumnFilter]
  );
  const [index, setIndex] = useState(0);
  const [inputValue, setInputValue] = useState(1);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualFilters: true,
      manualSortBy: props.sortHandler ? true : false,
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSize || 10,
        sortBy: props.sortBy
          ? props.sortBy
          : props.sortColumn
          ? [{ id: props.sortColumn, desc: true }]
          : [{ id: 'SumRevenue', desc: true }],
      },
      getRowId: (row, relativeIndex, parent) => {
        return row.id
          ? row.id
          : parent
          ? [parent.id, relativeIndex].join('.')
          : relativeIndex;
      },

      // manualPagination: true,
      autoResetSelectedRows: props.isLoading ? true : false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      props.products &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            width: 45,

            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  title="Выбрать все"
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  title="Выбрать строку"
                />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    if (props.sortHandler) {
      if (sortBy.length > 0) {
        props.sortHandler(sortBy[0].id, sortBy[0].desc);
      }
    }
  }, [props, sortBy]);

  const setFilterHandler = (e) => {
    let newState = { ...filters };

    newState[id] = e.target.value;
    return setFilters(newState);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////TABLE START
  return (
    <div className="table-div">
      <FiltersModule
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFilterHandler={setFilterHandler}
        filters={filters}
        setFilters={setFilters}
        id={id}
      />

      <Panel table={true}>
        <PanelHeader>
          {props.title}
          {props.download ? (
            <CSVLink
              className="m-l-auto "
              filename={`${props.title}.csv`}
              data={data}
            >
              <i className="table__download  fa fa-download m-r-5"></i>
            </CSVLink>
          ) : null}
        </PanelHeader>
        {props.dateHandler && (
          <div className="table__navigation">
            <Calendar dateHandler={props.dateHandler} />
          </div>
        )}

        {props.newDateHander && (
          <div className="table__navigation" style={{ padding: '10px 0' }}>
            <button
              onClick={() => setActiveDateRange('1day')}
              style={{ margin: '0 5px' }}
              className={`btn btn-dark ${
                activeDateRange === '1day' ? 'active' : ''
              }`}
            >
              Вчера
            </button>
            <button
              onClick={() => setActiveDateRange('7days')}
              style={{ margin: '0 5px' }}
              className={`btn btn-dark ${
                activeDateRange === '7days' ? 'active' : ''
              }`}
            >
              7 дней
            </button>
            <button
              onClick={() => setActiveDateRange('14days')}
              style={{ margin: '0 5px' }}
              className={`btn btn-dark ${
                activeDateRange === '14days' ? 'active' : ''
              }`}
            >
              14 дней
            </button>
            {!showCalendar ? (
              <button
                onClick={() => setActiveDateRange('my')}
                style={{ margin: '0 5px' }}
                className="btn btn-dark"
              >
                Свой
              </button>
            ) : (
              <DateRangePicker
                initialSettings={{
                  ...calendarOptions,
                  singleDatePicker: true,
                  autoUpdateInput: showCalendar,
                  startDate: dateNew ? moment(dateNew).toDate() : moment(),
                  endDate: dateNew ? moment(dateNew).toDate() : moment(),
                }}
                onApply={handleDateApplyEvent}
                onCancel={() => {
                  setShowCalendar(false);
                }}
              >
                <input type="text" className="report_date-calendar" />
              </DateRangePicker>
            )}
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-bordered" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="header__title"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => {
                    return (
                      <th className="new-tooltip" {...column.getHeaderProps()}>
                        <div
                          className=""
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              title: `${
                                headerGroup.headers[index].tipText
                                  ? headerGroup.headers[index].tipText
                                  : 'Сортировать'
                              }`,
                            })
                          )}
                          style={{
                            width: '100%',
                            // flex: '0 0 1rem',
                            // margin: '0 0.5rem 0 0 ',
                            cursor: column.sortable ? 'pointer' : 'auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row-reverse',
                          }}
                        >
                          <small className="table__header-sorter" id="sorter">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-blue"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-blue"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : (
                              ''
                            )}
                          </small>
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {column.render('Header')}
                          </span>
                        </div>
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />

                        {props.filterable && (
                          <form
                            onBlur={(e) => {
                              e.preventDefault();
                              if (column.id !== 'firstseen') {
                                filterAcceptHandler(column);
                              }
                              return;
                            }}
                            style={{
                              paddingTop: '5px',
                              marginTop: '5px',
                              borderTop: '1px solid #ccc',
                              display: 'flex',
                            }}
                            onSubmit={(e) => {
                              e.preventDefault();

                              filterAcceptHandler(column);
                            }}
                          >
                            {column.canFilter && column.id !== 'firstseen' ? (
                              column.render('Filter')
                            ) : column.id === 'firstseen' ? (
                              <DateRangePicker
                                initialSettings={{
                                  ...calendarOptions,
                                  showDropdowns: true,
                                  autoUpdateInput: false,
                                  singleDatePicker: true,
                                  startDate: date_to,
                                  endDate: date_to,
                                }}
                                onApply={(e, picker) => {
                                  e.preventDefault();

                                  setDate_to(moment(picker.endDate));
                                  setCalendarValue(picker.startDate);

                                  filterHandler([
                                    {
                                      id: column.id,
                                      value: moment(picker.startDate).format(
                                        `YYYY-MM-DD`
                                      ),
                                      operator: filters[column.id],
                                    },
                                  ]);
                                  setId('');
                                }}
                                onCancel={(e, picker) => {
                                  e.preventDefault();

                                  filterHandler([
                                    {
                                      id: column.id,
                                      value: '',
                                      operator: filters[column.id],
                                    },
                                  ]);
                                  setId('');

                                  setDate_to(moment());
                                  setCalendarValue('');
                                  // filterDate(props.column.id, '');
                                }}
                              >
                                <input
                                  type="text"
                                  value={
                                    calendarValue
                                      ? moment(calendarValue).format(
                                          `DD/MM/YYYY`
                                        )
                                      : ''
                                  }
                                  onChange={(e) => {
                                    return;
                                  }}
                                  className="form-control"
                                />
                              </DateRangePicker>
                            ) : null}
                            {column.canFilter &&
                              column.id !== 'phone' &&
                              column.id !== 'email' &&
                              column.id !== 'brand' &&
                              column.id !== 'seller' &&
                              column.id !== 'name' &&
                              column.id !== 'title' &&
                              column.id !== 'role' &&
                              column.id !== 'tariff' &&
                              column.id !== 'ctime' &&
                              column.id !== 'status' && (
                                <div
                                  className="input-group-append"
                                  style={{
                                    alignSelf: 'center',
                                    padding: '0 0 0 7px',
                                    cursor: 'pointer',
                                    color:
                                      // '#000',
                                      filters[column.id] === '=='
                                        ? '#b8c1ca'
                                        : '#000',
                                  }}
                                  onClick={() => {
                                    modelHandler(column.id);
                                  }}
                                >
                                  <i className="fa fa-filter"></i>
                                </div>
                              )}
                          </form>
                        )}

                        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps({
                      style:
                        row.index % 2
                          ? { background: '#f2f4f5' }
                          : { background: '#fff' },
                    })}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: cell.column.id === 'img' && {
                              display: 'flex',
                              justifyContent: 'center',
                            },
                          })}
                          className="tbody__cell"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {props.footer && (
              <tfoot>
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td
                        className="footer__title"
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
        <hr className="m-0" />

        <PanelBody>
          <div className="d-flex align-items-center justify-content-center table__bottom">
            {props.products ? (
              !props.remove ? (
                <div className="page-item m-r-5 table__bottom-button  ">
                  <button
                    className="page-link "
                    onClick={() =>
                      props.saveFavoritesHandler(
                        Object.keys(selectedRowIds).toString()
                      )
                    }
                    disabled={
                      Object.keys(selectedRowIds).length === 0 ||
                      Object.keys(selectedRowIds).length > 50
                    }
                  >
                    {props.isLoading ? (
                      <i className="fas fa-spinner fa-pulse"></i>
                    ) : (
                      `${
                        Object.keys(selectedRowIds).length > 50
                          ? 'Превышен лимит '
                          : 'Сохранить'
                      } ${
                        Object.keys(selectedRowIds).length === 0
                          ? ''
                          : Object.keys(selectedRowIds).length
                      }`
                    )}
                  </button>
                </div>
              ) : (
                <div className="page-item m-r-5 table__bottom-button ">
                  <button
                    className="page-link "
                    onClick={() =>
                      props.deleteFavoritesHandler(
                        Object.keys(selectedRowIds).toString()
                      )
                    }
                    disabled={
                      Object.keys(selectedRowIds).length === 0 ||
                      Object.keys(selectedRowIds).length > 50
                    }
                  >
                    {props.isLoading ? (
                      <i className="fas fa-spinner fa-pulse"></i>
                    ) : (
                      `${
                        Object.keys(selectedRowIds).length > 50
                          ? 'Превышен лимит '
                          : 'Удалить'
                      } ${
                        Object.keys(selectedRowIds).length === 0
                          ? ''
                          : Object.keys(selectedRowIds).length
                      }`
                    )}
                  </button>
                </div>
              )
            ) : null}

            <ul className="pagination mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    setIndex(0);
                    if (props.cleanOffset) {
                      props.cleanOffset();
                    }
                    gotoPage(0);
                  }}
                  disabled={index === 0}
                >
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    setIndex(index - 1);

                    if (props.offsetHandler) {
                      props.offsetHandler(-pageSize);
                    }
                    previousPage();
                  }}
                  disabled={index === 0}
                >
                  <i className="fa fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item numaration px-2">
                <strong>
                  {`Ст. `}
                  {!props.offsetHandler ? pageIndex + 1 : index + 1}
                  {` из `}
                  {Math.ceil(props.count / pageSize) || pageOptions.length}
                </strong>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    setIndex(index + 1);

                    if (props.offsetHandler) {
                      props.offsetHandler(pageSize);
                    }
                    nextPage();
                  }}
                  disabled={
                    props.count
                      ? index === Math.ceil(props.count / pageSize) - 1
                      : !canNextPage
                  }
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    if (props.lastPage && props.count) {
                      props.lastPage(
                        Math.ceil(props.count / pageSize) * pageSize - pageSize
                      );
                      setIndex(Math.ceil(props.count / pageSize) - 1);
                    } else {
                      setIndex(pageCount - 1);
                      gotoPage(pageCount - 1);
                    }
                  }}
                  disabled={
                    props.count
                      ? index === Math.ceil(props.count / pageSize) - 1
                      : !canNextPage
                  }
                >
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
            <div className="ml-3 mr-1">Перейти на ст.</div>
            <div className="width-60 mx-2">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const page = inputValue ? Number(inputValue) - 1 : 0;
                  setIndex(page);
                  if (props.lastPage) {
                    props.lastPage(page * pageSize);
                  } else {
                    gotoPage(page);
                  }
                }}
              >
                <input
                  className="form-control "
                  type="text"
                  defaultValue={inputValue}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      return setInputValue(e.target.value);
                    } else {
                      return;
                    }
                  }}
                />
              </form>
            </div>
            <div>
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setIndex(
                    Math.round(
                      (index / Math.ceil(props.count / pageSize)) *
                        (props.count / e.target.value)
                    )
                  );

                  setPageSize(Number(e.target.value));

                  if (props.limitHandler) {
                    props.limitHandler(e.target.value);
                    props.onOffset(
                      Math.round(
                        (index / Math.ceil(props.count / pageSize)) *
                          (props.count / e.target.value)
                      ) * e.target.value
                    );
                  }
                }}
              >
                {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Кол-во {pageSize}
                  </option>
                ))}
              </select>
            </div>
            {props.saveAll && (
              <div>
                <button
                  style={{ marginLeft: '10px', color: 'black' }}
                  onClick={saveAll}
                  className="page-link"
                  disabled={downloading}
                >
                  {!downloading ? (
                    'Скачать таблицу'
                  ) : (
                    <i className="fas fa-spinner fa-pulse"></i>
                  )}
                </button>
              </div>
            )}
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(Table);
