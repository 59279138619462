import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import * as wbActions from '../../store/actions/wb';

const StocksPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [available, setAvailable] = useState([]);
  const [active, setActive] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const stocks = useSelector((state) => state.wb.stocks);
  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        await dispatch(wbActions.getStocksRegions());
      } catch (e) {
        error('Что-то пошло не так... Попробуйте еще позже');
      }

      setIsLoading(false);
    };
    rendering();
  }, [dispatch]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const selectStock = (id) => {
    const avb = stocks.region_stocks
      .filter((stock) => {
        return stock.stockId === id;
      })
      .map((st) => {
        return st.regionId;
      });

    setAvailable(avb);
  };

  const selectRegion = (id) => {
    const avb = stocks.region_stocks
      .filter((reg) => {
        return reg.regionId === id;
      })
      .map((rg) => {
        return rg.stockId;
      });

    setAvailable(avb);
  };

  // const names = stocks.regions
  //   .filter((reg) => !available.find((av) => av === reg.id))
  //   .map((n) => n.name);

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active"> Страница складов</li>
      </ol>
      <h1 className="page-header">
        Страница складов <small>{/*header small text goes here...*/}</small>
      </h1>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              setAvailable([]);
              setActive(null);
              toggleTab('1');
            }}
          >
            <span className="d-sm-none">Склады</span>
            <span className="d-sm-block d-none">Склады</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              setAvailable([]);
              setActive(null);

              toggleTab('2');
            }}
          >
            <span className="d-sm-none">Регионы</span>
            <span className="d-sm-block d-none">Регионы</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {activeTab === '1' && (
            <div className="stocks__container">
              <div>
                <ul className="m-t-10 stocks">
                  <div className="stocks__container-header"> Склады</div>
                  {stocks.stocks.map((stock, i) => {
                    return (
                      <li
                        className={active === i ? 'active' : ''}
                        onClick={() => {
                          selectStock(stock.id);
                          setActive(i);
                        }}
                        key={stock.id}
                      >
                        {stock.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <ul className="m-t-10 available">
                  <div className="stocks__container-header"> Доступно</div>
                  {available.map(
                    (stock, i) =>
                      stocks.regions.find((reg) => reg.id === stock) !==
                        undefined && (
                        <li key={i}>
                          {stocks.regions.find((reg) => reg.id === stock).name}
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div>
                <ul className="m-t-10 unavailable">
                  <div className="stocks__container-header"> Не доступно</div>
                  {active === null
                    ? null
                    : stocks.regions
                        .filter((reg) => !available.find((av) => av === reg.id))
                        .map((n, i) => <li key={i}>{n.name}</li>)}
                </ul>
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tabId="2">
          {activeTab === '2' && (
            <div className="stocks__container">
              <div>
                <ul className="m-t-10  regions">
                  <div className="stocks__container-header"> Регионы</div>
                  {stocks.regions.map((region, i) => {
                    return (
                      <li
                        className={active === i ? 'active' : ''}
                        onClick={() => {
                          selectRegion(region.id);
                          setActive(i);
                        }}
                        key={region.id}
                      >
                        {region.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <ul className="m-t-10 available">
                  <div className="stocks__container-header"> Доступно</div>
                  {available.map(
                    (reg, i) =>
                      stocks.stocks.find((stock) => stock.id === reg) !==
                        undefined && (
                        <li key={i}>
                          {stocks.stocks.find((stock) => stock.id === reg).name}
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div>
                <ul className="m-t-10 unavailable">
                  <div className="stocks__container-header"> Не доступно </div>
                  {active === null
                    ? null
                    : stocks.stocks
                        .filter((st) => !available.find((av) => av === st.id))
                        .map((n, i) => <li key={i}>{n.name}</li>)}
                </ul>
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};
export default StocksPage;
