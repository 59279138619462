import numeral from 'numeral';
import { Link } from 'react-router-dom';

export const categoriesColumn = [
  {
    Header: 'Information',

    columns: [
      {
        Header: 'Название',
        accessor: 'title',
        tipText: 'Название категории',
        sortable: true,
        width: 350,
        Cell: ({ row }) => {
          return (
            <Link
              to={`/wildberries/categories/subcategories/${row.original.id}`}
            >
              {row.original.title}
              {/* {row.original.path ? row.original.path : row.original.title} */}
            </Link>
          );
        },
      },

      {
        Header: 'Рейтинг',
        accessor: 'AvgRating',
        tipText: 'Средний рейтинг категории',
        sortable: true,
        width: 75,
      },
      {
        Header: 'Комментариев',
        accessor: 'SumFeedbackCount',
        tipText: 'Количество комментариев по категории',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumFeedbackCount).format(
            `0,000,000`
          )} шт.`;
        },
      },

      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Количество видов товаров по категории',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,000,000`)} ед.`;
        },
      },

      {
        Header: 'Продажи',
        accessor: 'SumSellQty',
        tipText: 'Общее количество продаж',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Выручка за период',
        accessor: 'SumRevenue',
        tipText: 'Выручка категории за период',
        sortable: true,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'URL',
        accessor: 'url',
        tipText: 'Ссылка на wildberries',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <a href={row.values.url} target='_blank' rel='noreferrer'>
              Wildberries
            </a>
          );
        },
      },
    ],
  },
];
