import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const ProfRoute = (props) => {
  const tariff = useSelector((state) => state.auth.user.tariff.id);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const tariffCheker = () => {
    if (!tariff) {
      return false;
    } else if (tariff === 'base') {
      return false;
    } else if (tariff === 'advanced') {
      return false;
    }
    return true;
  };

  return tariffCheker() ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : isAuthenticated ? (
    <Redirect
      to={{
        pathname: '/notavailable',
        state: {
          message:
            'Данный раздел доступен только на тарифе "Профессиональный". Но вы можете расширить свой тариф по ссылке ниже.',
        },
      }}
    />
  ) : (
    <Redirect to='/login' />
  );
};

export default ProfRoute;
