import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import { historyOptions } from './chartOptions.js';

const TrandsChart = ({ history, days }) => {
  const sales = history
    .map((day) => {
      return +day.SumSellQty;
    })
    .reverse();

  const date = history
    .map((day) => {
      return day.startWeek;
    })
    .sort();

  const revenue = history
    .map((day) => {
      return +day.SumRevenue;
    })
    .reverse();

  const products = history
    .map((day) => {
      return +day.Products;
    })
    .reverse();

  const brands = history
    .map((day) => {
      return day.Brands ? +day.Brands : 0;
    })
    .reverse();

  const avgRevenue = history
    .map((day) => {
      return +day.AvgRevenue;
    })
    .reverse();

  const areaChart = {
    options: {
      ...historyOptions,
      xaxis: {
        ...historyOptions.xaxis,
        categories: date,
      },
      yaxis: !brands.includes(0)
        ? [
            {
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} шт.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Продажи',
              // },
            },
            {
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ед.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Число товаров',
              // },
            },
            {
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} шт.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Число брендов',
              // },
            },
            {
              opposite: true,
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ₽`;
                },
                show: false,
              },
              // title: {
              //   text: 'Выручка',
              // },
            },
            {
              opposite: true,
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ₽/шт.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Выручка на товар',
              // },
            },
          ]
        : [
            {
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} шт.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Продажи',
              // },
            },
            {
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ед.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Число товаров',
              // },
            },

            {
              opposite: true,
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ₽`;
                },
                show: false,
              },
              // title: {
              //   text: 'Выручка',
              // },
            },
            {
              opposite: true,
              labels: {
                formatter: function (val, index) {
                  return `${numeral(val).format(`0,000,000`)} ₽/шт.`;
                },
                show: false,
              },
              // title: {
              //   text: 'Выручка на товар',
              // },
            },
          ],
    },
  };

  const series = brands.includes(0)
    ? [
        {
          name: 'Продажи',
          data: sales,
        },

        {
          name: 'Количество видов товаров',
          data: products,
        },

        {
          name: 'Выручка',
          data: revenue,
        },
        {
          name: 'Выручка на товар',
          data: avgRevenue,
        },
      ]
    : [
        {
          name: 'Продажи',
          data: sales,
        },

        {
          name: 'Количество видов товаров',
          data: products,
        },
        {
          name: 'Число брендов',
          data: brands,
        },
        {
          name: 'Выручка',
          data: revenue,
        },
        {
          name: 'Выручка на товар',
          data: avgRevenue,
        },
      ];

  return (
    <div className="graph">
      <Panel>
        <PanelHeader>{`Тренд по ${history.length} неделям`}</PanelHeader>
        <PanelBody className="p-0">
          <Chart
            type="area"
            options={areaChart.options}
            series={series}
            height={350}
            width={'100%'}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(TrandsChart);
