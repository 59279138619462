import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import { history } from '../../app';
// import marketprofit from '../../apis/marketprofit';
import Loader from '../../components/loaders/Loader';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel';
import marketprofit from '../../apis/marketprofit';

const PayBillPage = (props) => {
  const id = +props.match.params.id;
  const [invoice, setInvoice] = useState({});
  const [params, setParams] = useState({
    api_url: '',
    api_method: '',
    params: {},
  });

  //   const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //   const [discounts, setDiscounts] = useState();

  //   const user_id = useSelector((state) => state.auth.user.id);
  //   const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const basic14Active = async () => {
    setIsLoading(true);
    try {
      const response = await marketprofit.post(
        `https://api.marketprofit.ru/invoice/${params.params.InvId}/apply`
      );

      if (response.data.success) {
        history.push('/main');
        history.go(0);
      }
    } catch (e) {
      error(
        'Не удалось активировать данные тариф. Попробуйте еще раз позже...'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        const response = await marketprofit.get(`/invoice/${id}`);
        if (!!response.data.success) {
          setInvoice(response.data.invoice);
          const response2 = await marketprofit.post(
            `/pay/${id}/start/robokassa`
          );

          if (!!response2.data.success) {
            setParams(response2.data);
          }
        }
      } catch (e) {
        error('Не удалось загрузить счет... Попробуйте позже');
      }

      setIsLoading(false);
    };
    rendering();
  }, [id]);

  const form = () => {
    if (params.params) {
      const paramsOptions = Object.keys(params.params).map((key, i) => (
        <div key={i} className={key}>
          <input type="hidden" id={key} name={key} value={params.params[key]} />
        </div>
      ));
      return paramsOptions;
    }
  };

  return (
    <div className="">
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Счет:{id}</li>
      </ol>
      <h1 className="page-header">
        Страница Оплаты <small>{/*choose your plan*/}</small>
      </h1>

      {isLoading && <Loader />}
      <ReactNotification />

      <Panel>
        <PanelHeader>Оплата подписки</PanelHeader>
        <PanelBody>
          <h1>Сумма к оплате:</h1>
          <h1 style={{ fontWeight: '700', color: '#618633' }}>{`${numeral(
            invoice.amount
          ).format(`0,000,000`)} ₽ `}</h1>
          <h5 style={{ margin: '10px 0' }}> {params.params.Description}</h5>

          <div className="buttons">
            {invoice.code === 'BASIC14' ? (
              <button
                onClick={basic14Active}
                className="btn-new btn-primary m-r-10"
              >
                Активировать
              </button>
            ) : (
              <form action={params.api_url} method={params.api_method}>
                {form()}
                <button className="btn-new btn-primary m-r-10">
                  Оплата онлайн
                </button>
              </form>
            )}
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default PayBillPage;
