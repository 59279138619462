import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';

import App from './app.jsx';
import authReducer from './store/reducers/auth';
import displayReducer from './store/reducers/display';
import wbReducer from './store/reducers/wb';
import { fetchMe } from './store/actions/auth';
import LoadingPage from './components/loaders/LoadingPage';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-notifications-component/dist/theme.css';

// ========================================

const rootReducer = combineReducers({
  auth: authReducer,
  dspl: displayReducer,
  wb: wbReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

let hasRendered = false;

const renderApp = async () => {
  try {
    await store.dispatch(fetchMe());

    if (!hasRendered) {
      ReactDOM.render(jsx, document.getElementById('root'));
      hasRendered = true;
    }
  } catch (e) {
    throw new Error(e);
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('root'));

renderApp();
