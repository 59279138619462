import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validator from 'validator';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../components/error/errorNotification';
import { history } from '../app';
import Loader from '../components/loaders/Loader';
import { signUp, fetchMe } from '../store/actions/auth';
import * as displayActions from '../store/actions/display';

const RegistrationPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const date = new Date();

  useEffect(() => {
    dispatch(displayActions.setPageSidebar(false));
    dispatch(displayActions.setPageHeader(false));
    dispatch(displayActions.setPageFooter(false));
    dispatch(displayActions.setPageContentFullWidth(true));
    return () => {
      dispatch(displayActions.setPageSidebar(true));
      dispatch(displayActions.setPageHeader(true));
      dispatch(displayActions.setPageFooter(true));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (
      !/^[a-zA-Zа-яА-Я]*$/g.test(firstName.trim()) ||
      !/^[a-zA-Zа-яА-Я]*$/g.test(lastName.trim())
    ) {
      error('В имени и фамилии должны содержаться только буквы');
      return;
    } else if (!validator.isEmail(email)) {
      error('Введите верный почтовый адрес');
      setEmail('');
      return;
    } else if (password !== password2) {
      error('Пароли должны совпадать ');
      setPassword2('');
      setPassword('');
      return;
    } else if (phone.length < 11) {
      error('Минимум 10 цифр в номере телефона');

      return;
    } else if (password.length < 8) {
      error('Пароль очень короткий');
      return;
    } else if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      error('Простой пароль (Минимум 1 заглавная и число) ');
      return;
    } else if (!agree) {
      error('Нужно ваше согласие с нашими условиями');
      return;
    } else {
      setIsLoading(true);
      try {
        await dispatch(
          signUp(email, phone, `${firstName} ${lastName}`, password, password2)
        );
        await dispatch(fetchMe());

        history.push('/login');
      } catch (err) {
        error('Пользователь с такой почтой уже существует');
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className='register register-with-news-feed'>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='news-feed'>
        <div
          className='news-image'
          style={{
            backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)',
          }}
        ></div>
        <div className='news-caption'>
          <h4 className='caption-title'>
            <b>Market</b> Profit
          </h4>
          <p></p>
        </div>
      </div>
      <div className='right-content'>
        <h1 className='register-header'>
          Регистрация
          <Link to='/'>
            <span className='float-right'>
              <button className='btn btn-primary'>
                <i className='fas fa-home fa-fw'></i>
              </button>
            </span>
          </Link>
        </h1>
        <div className='register-content'>
          <form className='margin-bottom-0' onSubmit={submitHandler}>
            <label className='control-label'>
              Имя <span className='text-danger'>*</span>
            </label>
            <div className='row row-space-10'>
              <div className='col-md-6 m-b-15'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Имя'
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className='col-md-6 m-b-15'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Фамилия'
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <label className='control-label'>
              Email <span className='text-danger'>*</span>
            </label>
            <div className='row m-b-15'>
              <div className='col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Email адрес'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <label className='control-label'>
              Мобильный номер <span className='text-danger'>*</span>
            </label>
            <div className='row m-b-15'>
              <div className='col-md-12'>
                <InputMask
                  className='form-control'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask='+999999999999'
                  maskChar={''}
                  placeholder=''
                ></InputMask>
                {/* <input
                  type='text'
                  className='form-control'
                  placeholder='Номер'
                  value={phone}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      return setPhone(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  required
                /> */}
              </div>
            </div>
            <label className='control-label'>
              Пароль <span className='text-danger'>*</span>
            </label>
            <div className='row m-b-15'>
              <div className='col-md-12'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='Пароль'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <label className='control-label'>
              Подтвердите пароль <span className='text-danger'>*</span>
            </label>
            <div className='row m-b-15'>
              <div className='col-md-12'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='Подтвердите пароль'
                  value={password2}
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className='checkbox checkbox-css m-b-30'>
              <div className='checkbox checkbox-css m-b-30'>
                <input
                  type='checkbox'
                  id='agreement_checkbox'
                  value={agree}
                  onChange={() => {
                    setAgree(!agree);
                  }}
                />

                <label htmlFor='agreement_checkbox'>
                  Ознакомлен и согласен с условиями{' '}
                  <Link to='/terms'>
                    Договора оказания услуг (публичной оферты)
                  </Link>
                </label>
              </div>
            </div>
            <div className='register-buttons'>
              <button
                type='submit'
                className='btn btn-primary btn-block btn-lg'
                onSubmit={submitHandler}
              >
                Регистрация
              </button>
            </div>
            <div className='m-t-20 m-b-40 p-b-40 text-inverse'>
              Вы зарегистрированы? Нажмите <Link to='/login'>сюда</Link> для
              входа.
            </div>
            <hr />
            <p className='text-center'>
              &copy; MarketProfit All Right Reserved {date.getFullYear()}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
