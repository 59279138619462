import { Link } from 'react-router-dom';

export const userColumns = [
  {
    Header: 'Информация',

    columns: [
      {
        Header: 'ID',
        accessor: 'id',
        tipText: 'ID пользователя',
        sortable: true,
        disableFilters: true,
        width: 50,
      },
      {
        Header: 'ФИО',
        accessor: 'title',
        tipText: 'Фамилия Имя Отчество',
        sortable: true,
        disableFilters: true,
      },
      {
        Header: 'Роль',
        accessor: 'role',
        tipText: 'Роль пользователя на сайте',
        // sortable: true,
        disableSortBy: true,

        width: 110,
      },

      {
        Header: 'Email',
        accessor: 'email',
        tipText: 'Email',
        sortable: true,
      },
      {
        Header: 'Номер',
        accessor: 'phone',
        tipText: 'Мобильный номер',
        sortable: true,
        width: 110,
      },
      {
        Header: 'Тариф',
        accessor: 'tariff',
        tipText: 'Тариф пользователя',
        // sortable: true,
        disableSortBy: true,
      },
      {
        Header: 'Тариф истекает',
        accessor: 'tariff_expired',
        tipText: 'Дата истечения тарифа',
        sortable: true,
        disableFilters: true,

        width: 110,
      },
      {
        Header: 'Создан',
        accessor: 'ctime',
        tipText: 'Дата регистрации пользователя',
        sortable: true,
        disableFilters: true,

        width: 110,
      },

      {
        Header: 'Последний вход',
        accessor: 'last_access',
        tipText: 'Дата последнего входа',
        sortable: true,
        disableFilters: true,

        width: 110,
      },
      {
        Header: 'Отключен',
        accessor: 'is_disabled',
        tipText: 'Отключен',
        sortable: true,
        disableFilters: true,

        width: 70,
      },
      {
        Header: 'Просмотр',
        accessor: 'options',
        tipText: 'Просмотреть пользователя',
        width: 60,
        disableSortBy: true,
        disableFilters: true,

        Cell: ({ row }) => {
          return (
            <div>
              <Link
                className='btn btn-lime m-r-5 table__edit'
                to={`/user/${row.values.id}`}
              >
                <i className='fas fa-edit fa-fw'></i>
              </Link>
            </div>
          );
        },
      },
    ],
  },
];
