import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarProfile from './sidebar-profile.jsx';
import SidebarNav from './sidebar-nav.jsx';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const isAuth = useSelector((state) => !!state.auth.isLogin);

  return (
    <>
      <div id='sidebar' className='sidebar '>
        <PerfectScrollbar
          className='height-full'
          options={{ suppressScrollX: true }}
        >
          {isAuth ? <SidebarProfile /> : null}
          <SidebarNav />
        </PerfectScrollbar>
      </div>
      <div className='sidebar-bg'></div>
      <div className='sidebar-mobile-dismiss'></div>
    </>
  );
};

export default React.memo(Sidebar);
