import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import ReactNotification from "react-notifications-component";
import { Steps } from "intro.js-react";

import "intro.js/introjs.css";
import "react-notifications-component/dist/theme.css";

import error from "../components/error/errorNotification";
import Loader from "../components/loaders/Loader";
import marketprofit from "../apis/marketprofit";
import Intro from "../components/main/intro";
import { introSteps } from "../components/main/steps";
// import StatContainer from '../components/main/statContainer';
// import Analitycs from '../components/main/analitycs';
import StatisticNavigation from "../components/main/statisticNavigation";
import StatisticTop from "../components/main/statisticTop";

const MainPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [brands, setBrands] = useState(0);
  const [products, setProducts] = useState(0);
  const [categories, setCategories] = useState(0);
  const [sales, setSales] = useState(0);
  const [sellers, setSellers] = useState(0);
  const [stocks, setStocks] = useState(0);
  const [topBrands, setTopBrands] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [copied, setCopied] = useState(false);
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep] = useState(0);
  const userName = useSelector((state) => state.auth.user.title);
  // const userId = useSelector((state) => state.auth.user.id);
  const firstLogin = () => localStorage.getItem("notFirstLogin") || false;
  const [showSteps] = useState(firstLogin());
  const [steps] = useState(introSteps(userName));
  const usedPromocode = useSelector(
    (state) => state.auth.user.is_used_start_promocode
  );
  const dateOfPromotion = "2021-10-15";

  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        const response = await marketprofit.get("/wb/stat");
        if (!!response.data.success) {
          setBrands(
            response.data.stat
              .map((st) => {
                return +st.brands;
              })
              .reverse()
          );
          setProducts(
            response.data.stat
              .map((st) => {
                return +st.products;
              })
              .reverse()
          );
          setCategories(
            response.data.stat
              .map((st) => {
                return +st.categories;
              })
              .reverse()
          );
          setSales(
            response.data.stat
              .map((st) => {
                return +st.sales;
              })
              .reverse()
          );
          setSellers(
            response.data.stat
              .map((st) => {
                return +st.sellers;
              })
              .reverse()
          );

          setStocks(response.data.stocks);

          setTopBrands(response.data.top5_brands);

          setTopProducts(
            response.data.top5_products
              .sort(
                (a, b) => parseFloat(a.lastSellQty) - parseFloat(b.lastSellQty)
              )
              .reverse()
          );
        }
      } catch (e) {
        error("Что-то пошло не так... Попробуйте еще позже");
      }

      setIsRender(true);
      setIsLoading(false);
    };
    rendering();
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("notFirstLogin", true);
  }, []);

  const onExit = () => {
    setStepsEnabled(false);
  };

  const onBeforeChange = (nextStepIndex) => {
    if (nextStepIndex < 10) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  };

  return (
    <div className="main">
      {isLoading && <Loader />}

      <ReactNotification />

      {usedPromocode === 0 &&
        moment(dateOfPromotion, "YYYY-MM-DD").format() > moment().format() && (
          <div
            className="notification"
            style={{
              minHeight: "3rem",
              backgroundColor: copied ? "#32a932" : "#f59c1a",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              transition: "all 0.4s ",
              borderRadius: "4px",
            }}
          >
            <p className="m-b-0 p-10" style={{ fontWeight: "bold" }}>
              Успей купить подписку с 95% скидкой! Введите промокод{" "}
              <strong style={{ color: "red" }}>START</strong> при оформлении
              заказа. До конца акции осталось:
              <Countdown
                date={moment(dateOfPromotion, "YYYY-MM-DD").toDate()}
                renderer={({ days, hours, minutes, seconds }) => {
                  return (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >{` ${days}д, ${hours}ч, ${minutes}м, ${seconds}с. `}</span>
                  );
                }}
              />
            </p>

            <CopyToClipboard text="START" onCopy={() => setCopied(true)}>
              <div
                style={{ color: "#fff", marginRight: "10px" }}
                disabled={copied ? true : false}
                className={`btn btn-${copied ? "info" : "primary"}`}
              >
                {copied ? "Скопировано" : "Скопировать"}
              </div>
            </CopyToClipboard>
          </div>
        )}

      <div className="main__header">
        <h1 className="page-header">
          Главная страница <small>{/* header small text goes here... */}</small>
        </h1>
      </div>

      {isRender && (
        <div className="main__body boddyyy">
          {
            // (userId === 17 || userId === 7 || userId === 1)
            !showSteps && (
              <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onExit={onExit}
                onBeforeChange={onBeforeChange}
                options={{
                  nextLabel: '<i class="fas fa-angle-right"></i>',
                  prevLabel: '<i class="fas fa-angle-left"></i>',
                  doneLabel: '<i class="fas fa-check"></i>',
                  hidePrev: true,
                  scrollPadding: 0,
                  scrollToElement: true,
                  showProgress: true,
                }}
              />
            )
          }
          {/* <div className='row'> */}
          {/* <StatContainer
            brands={brands}
            products={products}
            categories={categories}
            sales={sales}
            sellers={sellers}
          /> */}
          <StatisticNavigation
            brands={brands}
            products={products}
            categories={categories}
            sales={sales}
            sellers={sellers}
            stocks={stocks}
          />

          <StatisticTop
            products={products}
            sales={sales}
            topBrands={topBrands}
            topProducts={topProducts}
          />

          {/* <div className='col-xl-4 col-lg-6'>
              <Analitycs
                brands={brands}
                products={products}
                categories={categories}
                sales={sales}
                sellers={sellers}
              />
            </div> */}
          {/* <div className='col-xl-8 col-lg-6'> */}
          <Intro />
          {/* </div> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default MainPage;
