export const calendarOptions = {
  locale: {
    cancelLabel: 'Очистить',
    applyLabel: 'Выбрать',

    monthNames: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноб',
      'Дек',
    ],
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    format: 'DD/MM/YYYY',
  },
};
