import React from 'react';
import Chart from 'react-apexcharts';

import { brandsCategoriesOptions } from '../chart/chartOptions';

const StockDonutChart = ({ data, title }) => {
  const optionsSales = {
    ...brandsCategoriesOptions.options,
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            width: 700,
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 700,
          },
        },
      },
      {
        breakpoint: 1700,
        options: {
          chart: {
            width: 600,
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 500,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            width: 400,
          },
        },
      },

      {
        breakpoint: 1000,
        options: {
          chart: {
            width: 650,
          },
        },
      },
      {
        breakpoint: 920,
        options: {
          chart: {
            width: 450,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 600,
          },
        },
      },
      {
        breakpoint: 650,
        options: {
          chart: {
            width: 450,
          },
        },
      },
      {
        breakpoint: 500,
        options: {
          chart: {
            width: 350,
          },
        },
      },
    ],
    labels: [
      ...data.map((d) => {
        return d.name;
      }),
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,

            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val.length >= 7 ? val.slice(0, 7) + '...' : val;
              },
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: 0,
              formatter: function (val) {
                return `${val} шт.`;
              },
            },
            total: {
              show: true,
              label: 'Всего',
              color: '#373d3f',
              formatter: function (w) {
                return `${w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0)} шт.`;
              },
            },
          },
        },
      },
    },
    title: {
      text: title ? title : 'Наличие на складах',
    },
  };

  return (
    <Chart
      type="donut"
      options={optionsSales}
      series={[
        ...data.map((d) => {
          return +d.qty;
        }),
      ]}
    />
  );
};

export default React.memo(StockDonutChart);
