import numeral from 'numeral';

class Seller {
  constructor(
    num,
    id,
    name,
    AvgRating,
    firstseen,
    SumFeedbackCount,
    AvgFeedbackCount,
    Products,
    SumSellQty,
    SumRevenue,
    url
  ) {
    this.num = num;
    this.id = id;
    this.name = name;
    this.AvgRating = AvgRating;
    this.firstseen = firstseen;
    this.SumFeedbackCount = SumFeedbackCount;
    this.AvgFeedbackCount = AvgFeedbackCount;
    this.Products = Products;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
  }
}

export default Seller;

export const newSeller = (seller, i) => {
  return new Seller(
    i + 1,
    seller.sellerId,
    seller.name ? seller.name : '-',
    seller.AvgRating === 'nan' ? '-' : numeral(seller.AvgRating).format(`0.00`),
    seller.firstseen,
    seller.SumFeedbackCount === null ? 0 : seller.SumFeedbackCount,
    seller.AvgFeedbackCount === null ? 0 : seller.AvgFeedbackCount,
    seller.Products,
    seller.SumSellQty === null ? 0 : seller.SumSellQty,
    seller.SumRevenue === null ? 0 : seller.SumRevenue
  );
};
