import numeral from 'numeral';

class SellersCategory {
  constructor(
    id,
    title,
    // path,
    SumFeedbackCount,
    AvgRating,
    Products,
    SumSellQty,
    SumRevenue,
    url
  ) {
    this.id = id;
    this.title = title;
    // this.path = path;
    this.SumFeedbackCount = SumFeedbackCount;
    this.AvgRating = AvgRating;
    this.Products = Products;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
    this.url = url;
  }
}

export default SellersCategory;

export const newSellersCategory = (cat) => {
  return new SellersCategory(
    cat.id,
    cat.title === null
      ? cat.name
      : `${
          cat.parentId && cat.parent.parentId
            ? `${cat.parent.parent.name}/`
            : ''
        }${cat.parentId ? `${cat.parent.name}/` : ''}${cat.title}`,
    // cat.title === null
    //   ? cat.name
    //   : `${
    //       cat.parentId && cat.parent.parentId
    //         ? `${cat.parent.parent.name}/`
    //         : ''
    //     }${cat.parentId ? `${cat.parent.name}/` : ''}${cat.title}`,
    cat.SumFeedbackCount === null ? 0 : cat.SumFeedbackCount,
    numeral(cat.AvgRating).format(`0.00`),
    cat.Products,
    cat.SumSellQty === null ? 0 : cat.SumSellQty,
    cat.SumRevenue === null ? 0 : cat.SumRevenue,
    cat.url
  );
};
