import React from 'react';
import Confetti from 'react-confetti';
import { Link } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';

const SuccessPage = () => {
  const { width, height } = useWindowSize();
  return (
    <div className="">
      <Confetti width={width} height={height} numberOfPieces={500} />
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Оплачено</li>
      </ol>
      <h1 className="page-header">
        Оплата прошла успешно! <small>{/*choose your plan*/}</small>
      </h1>

      <div className="success">
        <h1>Большое Спасибо!</h1>
        <Link to="/main">
          <div className="success__logo">
            <i className="fas fa-check-circle fa-10x"></i>
          </div>
        </Link>
        <h3>
          Мы приняли вашу оплату, и теперь у вас появилась возможность
          воспользоваться нашими услугами, согласно вашему тарифу
        </h3>
      </div>
    </div>
  );
};

export default SuccessPage;
