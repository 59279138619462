import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import { productColumns } from '../../components/tables/productColumns';
import Table from '../../components/tables/Table';
import * as wbActions from '../../store/actions/wb';
import { saveAll } from '../../components/tables/saveAll';

const FavoritePage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offSet, setOffset] = useState(0);
  const [order_by, setOrder_by] = useState('SumRevenue');
  const [dir, setDir] = useState('desc');
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const productsList = useSelector((state) => state.wb.favoriteProducts);
  const count = useSelector((state) => state.wb.counts.favoriteCount);
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;
  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          wbActions.getFavorites(
            limit,
            offSet,
            order_by,
            dir,
            date_from,
            date_to
          )
        );
      } catch (e) {
        error('Не удалось загрузить названия');
      }
      setIsLoading(false);
    };

    rendering();
  }, [dispatch, limit, offSet, order_by, dir, date_from, date_to]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const deleteFavoritesHandler = async (ids) => {
    setIsLoading2(true);
    try {
      await dispatch(wbActions.deleteFavorites(ids));
      await dispatch(
        wbActions.getFavorites(limit, offSet, order_by, dir, date_from, date_to)
      );
    } catch (e) {
      error('Не удалось удалить, попробуйте еще раз позже...');
    }
    setIsLoading2(false);
  };

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active"> Избранные товары</li>
      </ol>
      <h1 className="page-header">{'Страница избранного '}</h1>

      <Table
        products
        remove
        isLoading={isLoading2}
        deleteFavoritesHandler={deleteFavoritesHandler}
        count={count}
        limitHandler={limitHandler}
        offsetHandler={offsetHandler}
        onOffset={onOffset}
        cleanOffset={cleanOffset}
        lastPage={lastPage}
        sortHandler={sortHandler}
        pageSize={20}
        dateHandler={dateHandler}
        title={
          isLoading
            ? ''
            : `Количество товаров - ${count}, за количество дней - ${days}`
        }
        data={isLoading ? [] : productsList}
        columns={productColumns}
        saveAll={() =>
          saveAll(
            `/wb/favorities/`,
            order_by,
            dir,
            date_from,
            date_to,
            [],
            `Отчет по избранным товарам за ${days} дней`
          )
        }
      />
    </div>
  );
};
export default FavoritePage;
