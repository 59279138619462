import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import { historyOptions } from '../chart/chartOptions';

const ProductHistoryChart = ({ history, days }) => {
  const sales = history.map((day) => {
    return +day.sellQty;
  });
  const qty = history.map((day) => {
    return +day.qty;
  });

  const date = history.map((day) => {
    return day.createdAt;
  });

  const price = history.map((day) => {
    return +day.finishPrice;
  });

  const revenue = history.map((day) => {
    return +day.revenue;
  });

  const areaChart = {
    options: {
      ...historyOptions,
      xaxis: {
        ...historyOptions.xaxis,
        categories: date,
      },
      yaxis: [
        {
          seriesName: 'Продажи',
          // title: {
          //   text: 'Продажи',
          //   style: {
          //     fontSize: '10px',
          //   },
          //        },
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0a`)} шт.`;
            },
            show: false,
            maxWidth: '30',
            style: {
              fontSize: '8px',
            },
            // rotate: 65,
          },
        },
        {
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0a`)} шт.`;
            },
            show: false,

            maxWidth: '30',
            style: {
              fontSize: '8px',
            },
            // rotate: 65,
          },
          seriesName: 'Остаток',
          // title: {
          //   text: 'Остаток',
          //   show: false,
          //   style: {
          //     fontSize: '10px',
          //   },
          //        },
        },
        {
          opposite: true,
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0a`)} ₽`;
            },
            show: false,

            maxWidth: '30',
            style: {
              fontSize: '8px',
            },
            // rotate: 65,
          },
          seriesName: 'Цена',
          // title: {
          //   text: 'Цена',

          //   style: {
          //     fontSize: '10px',
          //   },

          // },
        },
        {
          opposite: true,
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0a`)} ₽`;
            },
            show: false,

            maxWidth: '30',
            style: {
              fontSize: '8px',
            },
            // rotate: 65,
          },
          // title: {
          //  text: 'Выручка',
          //       style: {
          //     fontSize: '10px',
          //   },
          //                  },
          seriesName: 'Выручка',
        },
      ],
    },
  };

  const series = [
    {
      name: 'Продажи',
      data: sales,
    },
    {
      name: 'Остаток',
      data: qty,
    },

    {
      name: 'Цена',
      data: price,
    },
    {
      name: 'Выручка',
      data: revenue,
    },
  ];

  return (
    <div className="graph">
      <Panel>
        <PanelHeader>{`Продажи и остатки за ${days} дней`}</PanelHeader>
        <PanelBody className="p-0">
          <Chart type="area" options={areaChart.options} series={series} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(ProductHistoryChart);
