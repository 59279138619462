import numeral from 'numeral';

class Trend {
  constructor(startWeek, SumSellQty, SumRevenue, Products, Brands, AvgRevenue) {
    this.startWeek = startWeek;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
    this.Products = Products;
    this.Brands = Brands;
    this.AvgRevenue = AvgRevenue;
  }
}

export default Trend;

export const newTrend = (trend, i) => {
  return new Trend(
    trend.startWeek,
    trend.SumSellQty,
    trend.SumRevenue === null ? 0 : trend.SumRevenue,
    trend.Products,
    trend.Brands,
    trend.AvgRevenue === null ? 0 : numeral(trend.AvgRevenue).format(`0`)
  );
};
