import numeral from 'numeral';
import { Link } from 'react-router-dom';

class Product {
  constructor(
    img,
    imgBig,
    id,
    name,
    category,
    brand,
    siteBrandId,
    colors,
    seller,
    sellerId,
    qty,
    feedbackCount,
    rating,
    firstseen,
    finishPrice,
    clientPrice,
    NumbDaysWithQty,
    SumSellQty,
    NumbDaysWithSell,
    SumRevenue,
    history
  ) {
    this.img = img;
    this.imgBig = imgBig;
    this.id = id;
    this.name = name;
    this.category = category;
    this.brand = brand;
    this.siteBrandId = siteBrandId;
    this.colors = colors;
    this.seller = seller;
    this.sellerId = sellerId;
    this.qty = qty;
    this.feedbackCount = feedbackCount;
    this.rating = rating;
    this.firstseen = firstseen;
    this.finishPrice = finishPrice;
    this.clientPrice = clientPrice;
    this.NumbDaysWithQty = NumbDaysWithQty;
    this.SumSellQty = SumSellQty;
    this.NumbDaysWithSell = NumbDaysWithSell;
    this.SumRevenue = SumRevenue;
    this.history = history;
  }
}

export default Product;

export const newProduct = (product, params) => {
  return new Product(
    product.images !== undefined ? product.images[0].small : 0,
    product.images !== undefined ? product.images[0].big : 0,
    product.id,
    product.name,
    product.categories
      ? product.categories.map((cat, i) => {
          return (
            <Link
              key={product.id + i}
              to={{
                pathname: `/wildberries/categories/products/${cat.categoryId}`,
                state: cat.title ? cat.title : cat.name,
              }}
            >
              {cat.title ? cat.title : cat.name}
              <br />
            </Link>
          );
        })
      : '-',
    product.brand,
    product.siteBrandId,
    !product.colors || product.colors === "['']" || product.colors === '[]'
      ? '-'
      : product.colors
          .split('[')
          .join('')
          .split(']')
          .join('')
          .split("'")
          .join(''),
    product.seller,
    product.sellerId,
    product.qty === null ? 0 : product.qty,
    product.feedbackCount === null ? 0 : product.feedbackCount,
    product.rating === null ? '-' : product.rating,
    product.firstseen,
    `${numeral(product.finishPrice).format(`0,000,000`)}`,
    product.clientPrice === null
      ? `${numeral(product.finishPrice).format(`0,000,000`)}`
      : `${numeral(product.clientPrice).format(`0,000,000`)}`,
    product.NumbDaysWithQty === null ? 0 : product.NumbDaysWithQty,
    product.SumSellQty === null ? 0 : product.SumSellQty,
    product.NumbDaysWithSell === null ? 0 : product.NumbDaysWithSell,
    product.SumRevenue === null
      ? 0
      : `${numeral(product.SumRevenue).format(`0,000,000`)}`,
    product.history
  );
};
