import { newBrand } from '../../models/Brand';
import { newBrandHistory } from '../../models/BrandHistory';
import { newBrandCategorie } from '../../models/BrandsCategory';
import { newCategory } from '../../models/Category';
import { newOptionsProductHistory } from '../../models/OptionProductHistory';
import { newOptionProductTotal } from '../../models/OptionProductTotal';
import { newProduct } from '../../models/Product';
import { newProductHistory } from '../../models/ProductHistory';
import { newProductPosition } from '../../models/ProductPosition';
import { newSeller } from '../../models/Seller';
import { newSellersCategory } from '../../models/SellersCategory';
import { newSellersHistory } from '../../models/SellersHistory';
import { newStocksProductHistory } from '../../models/StocksProductHistory';
import { newSubcategory } from '../../models/Subcategory';
import { newSubcategoryHistory } from '../../models/SubcategoryHistory';
import { newTrend } from '../../models/Trend';
import {
  ADD_FAVORITES,
  CLEAR_SUBCATEGORIES,
  DELETE_FAVORITES,
  FETCH_NEW,
  GET_BRANDS,
  GET_BRANDS_CATEGORIES,
  GET_BRANDS_HISTORY,
  GET_BRANDS_PRODUCTS,
  GET_BRANDS_SELLERS,
  GET_BRANDS_TRENDS,
  GET_BRAND_INFO,
  GET_CATEGORIES,
  GET_CATEGORY_INFO,
  GET_FAVORITES,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_SELLERS,
  GET_SELLERS_BRANDS,
  GET_SELLERS_CATEGORIES,
  GET_SELLERS_HISTORY,
  GET_SELLERS_PRODUCTS,
  GET_SELLERS_TRENDS,
  GET_SELLER_INFO,
  GET_STOCKS_REGIONS,
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_BRANDS,
  GET_SUBCATEGORIES_HISTORY,
  GET_SUBCATEGORIES_SELLERS,
  GET_SUBCATEGORIES_TRENDS,
  REQUESTS_AVAILABLE,
  SEARCH_BRANDS,
  SEARCH_PRODUCT,
  SEARCH_SELLERS,
} from '../actions/wb';

const initialState = {
  requests_remains: 0,
  requests_today: 0,
  brandsList: [],
  brandsProductsList: [],
  brandsCategoriesList: [],
  brandsHistory: [],
  brandsSellers: [],
  categoriesList: [],
  productsList: [],
  productHistory: [],
  product: {},
  productPosition: [],
  subcategoriesList: [],
  subcategoriesBrandsList: [],
  subcategoriesHistory: [],
  subcategoriesSellers: [],
  sellersList: [],
  sellersCategories: [],
  sellersBrands: [],
  sellersHistory: [],
  sellersProducts: [],
  stocksProductHistory: [],
  favoriteProducts: [],
  searchProducts: [],
  newProducts: [],
  counts: {
    productsCount: 0,
    brandsCountL: 0,
    categoriesCount: 0,
    sellersCount: 0,
    subcategoriesCount: 0,
    mainCategoriesCount: 0,
    favoriteCount: 0,
    searchCount: 0,
    newCount: 0,
  },
  categoryTitles: {
    id: '',
  },
  brandTitles: {
    id: '',
    name: '',
  },
  sellerTitles: {
    id: '',
    name: '',
  },
  stocks: {
    stocks: [],
    regions: [],
  },
  optionsSales: [],
  optionsSalesTotal: [],
  trends: [],
};

const wbReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTS_AVAILABLE:
      return { ...state, ...action.payload };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////CATEGORIES
    case GET_CATEGORIES:
      const categoriesList = action.payload.list.map((category) => {
        return newCategory(category);
      });
      return {
        ...state,
        categoriesList: [...categoriesList],
        counts: { ...state.counts, mainCategoriesCount: action.payload.count },
      };
    case GET_CATEGORY_INFO:
      const categoryTitles = action.payload.record;
      return {
        ...state,
        categoryTitles: { ...categoryTitles },
      };

    case GET_SUBCATEGORIES:
      const subcategoriesList = action.payload.list.map((cat) => {
        return newSubcategory(cat);
      });
      return {
        ...state,
        subcategoriesList: [...subcategoriesList],
        counts: {
          ...state.counts,
          subcategoriesCount: action.payload.count,
        },
      };

    case GET_SUBCATEGORIES_BRANDS:
      const subcategoriesBrandsList =
        action.payload.list !== undefined
          ? action.payload.list.map((brand, i) => {
              return newBrand(brand, i);
            })
          : [];
      return {
        ...state,
        subcategoriesBrandsList: [...subcategoriesBrandsList],
      };
    case GET_SUBCATEGORIES_HISTORY:
      const subcategoriesHistory = action.payload.history
        .map((cat) => {
          return newSubcategoryHistory(cat);
        })
        .reverse();
      return { ...state, subcategoriesHistory: [...subcategoriesHistory] };

    case GET_SUBCATEGORIES_SELLERS:
      const subcategoriesSellers = action.payload.list.map((seller, i) => {
        return newSeller(seller, i);
      });
      return {
        ...state,
        subcategoriesSellers: [...subcategoriesSellers],
      };
    case CLEAR_SUBCATEGORIES:
      return { ...state, subcategoriesList: [] };
    case GET_SUBCATEGORIES_TRENDS:
      const subTrends = action.payload.trends.map((trend) => {
        return newTrend(trend);
      });
      return {
        ...state,
        trends: [...subTrends],
      };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////BRANDS

    case GET_BRANDS:
      const offset = action.offset;
      const brandsList = action.payload.list.map((brand, i) => {
        return newBrand(brand, i + offset);
      });
      return {
        ...state,
        brandsList: [...brandsList],
        counts: {
          ...state.counts,
          brandsCount: action.payload.count,
        },
      };
    case SEARCH_BRANDS:
      const searchOffset = action.offset;
      const searchList = action.payload.list.map((brand, i) => {
        return newBrand(brand, i + searchOffset);
      });

      return {
        ...state,
        brandsList: [...searchList],
        counts: {
          ...state.counts,
          brandsCount: action.payload.count,
        },
      };

    case GET_BRAND_INFO:
      const brandTitles = action.payload.record;
      return {
        ...state,
        brandTitles: { ...brandTitles },
      };

    case GET_BRANDS_PRODUCTS:
      const brandsProductsList =
        action.payload.list !== undefined
          ? action.payload.list.map((product) => {
              return newProduct(product);
            })
          : [];
      return {
        ...state,
        brandsProductsList: [...brandsProductsList],
        counts: {
          ...state.counts,
          productsCount: action.payload.count,
        },
      };

    case GET_BRANDS_CATEGORIES:
      const brandsCategoriesList =
        action.payload.list !== undefined
          ? action.payload.list.map((cat, i) => {
              return newBrandCategorie(cat);
            })
          : [];
      return {
        ...state,
        brandsCategoriesList: [...brandsCategoriesList],
        counts: {
          ...state.counts,
          categoriesCount: action.payload.count,
        },
      };

    case GET_BRANDS_HISTORY:
      const brandsHistory = action.payload.history
        .map((brand) => {
          return newBrandHistory(brand);
        })
        .reverse();
      return {
        ...state,
        brandsHistory: [...brandsHistory],
      };
    case GET_BRANDS_SELLERS:
      const brandsSellers = action.payload.list.map((brand, i) => {
        return newSeller(brand, i);
      });
      return {
        ...state,
        brandsSellers: [...brandsSellers],
      };
    case GET_BRANDS_TRENDS:
      const brandsTrends = action.payload.trends.map((trend) => {
        return newTrend(trend);
      });

      return {
        ...state,
        trends: [...brandsTrends],
      };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////PRODUCT

    case GET_PRODUCTS:
      const productsList = action.payload.list
        ? action.payload.list.map((product) => {
            return newProduct(product);
          })
        : [];
      return {
        ...state,
        productsList: [...productsList],
        counts: {
          ...state.counts,
          productsCount: action.payload.count,
        },
      };

    case GET_PRODUCT:
      const productHistory =
        action.payload.history.list
          .map((prod) => {
            return newProductHistory(prod);
          })
          .reverse() || [];

      const productPosition =
        typeof action.payload.history_categories === 'object'
          ? action.payload.history_categories.list.map((prod) => {
              return newProductPosition(prod);
            })
          : [];

      const stocksProductHistory =
        action.payload.history_stocks.list.map((stock) => {
          return newStocksProductHistory(stock);
        }) || [];

      const optionsSales =
        action.payload.history_options.list.length > 0
          ? action.payload.history_options.list.map((option) => {
              return newOptionsProductHistory(option);
            })
          : [];

      const optionsSalesTotal =
        action.payload.history_options.list.length > 0
          ? action.payload.history_options.list.map((option) => {
              return newOptionProductTotal(option);
            })
          : [];

      return {
        ...state,
        product: { ...action.payload.record },
        productHistory: [...productHistory],
        productPosition: [...productPosition],
        stocksProductHistory: [...stocksProductHistory],
        optionsSales: [...optionsSales],
        optionsSalesTotal: [...optionsSalesTotal],
      };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////SELLERS

    case GET_SELLERS:
      const offs = action.offset;
      const sellersList = action.payload.list.map((seller, i) => {
        return newSeller(seller, i + offs);
      });
      return {
        ...state,
        sellersList: [...sellersList],
        counts: {
          ...state.counts,
          sellersCount: action.payload.count,
        },
      };
    case SEARCH_SELLERS:
      const searchOffs = action.offset;
      const searchSellersList = action.payload.list.map((seller, i) => {
        return newSeller(seller, i + searchOffs);
      });

      return {
        ...state,
        sellersList: [...searchSellersList],
        counts: {
          ...state.counts,
          sellersCount: action.payload.count,
        },
      };

    case GET_SELLER_INFO:
      const sellerTitles = action.payload.record;
      return {
        ...state,
        sellerTitles: { ...sellerTitles },
      };
    case GET_SELLERS_CATEGORIES:
      const sellersCategories = action.payload.list.map((seller) => {
        return newSellersCategory(seller);
      });
      return {
        ...state,
        sellersCategories: [...sellersCategories],
        counts: {
          ...state.counts,

          categoriesCount: action.payload.count,
        },
      };
    case GET_SELLERS_BRANDS:
      const sellersBrands = action.payload.list.map((seller, i) => {
        return newBrand(seller, i);
      });
      return {
        ...state,
        sellersBrands: [...sellersBrands],
        counts: {
          ...state.counts,
          brandsCount: action.payload.count,
        },
      };
    case GET_SELLERS_HISTORY:
      const sellersHistory = action.payload.history
        .map((seller) => {
          return newSellersHistory(seller);
        })
        .reverse();
      return {
        ...state,
        sellersHistory: [...sellersHistory],
      };

    case GET_SELLERS_PRODUCTS:
      const sellersProducts = action.payload.list.map((product) => {
        return newProduct(product);
      });
      return {
        ...state,
        sellersProducts: [...sellersProducts],
        counts: {
          ...state.counts,
          productsCount: action.payload.count,
        },
      };

    case GET_SELLERS_TRENDS:
      const sellersTrends = action.payload.trends.map((trend) => {
        return newTrend(trend);
      });
      return {
        ...state,
        trends: [...sellersTrends],
      };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////STOCKS

    case GET_STOCKS_REGIONS:
      const stocks = action.payload;
      return {
        ...state,
        stocks: {
          ...stocks,
        },
      };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////FAVORITES
    case ADD_FAVORITES:
      return state;
    case GET_FAVORITES:
      const favoriteProducts = action.payload.list.map((product) => {
        return newProduct(product);
      });
      return {
        ...state,
        favoriteProducts: [...favoriteProducts],
        counts: {
          ...state.counts,
          favoriteCount: action.payload.count ? action.payload.count : 0,
        },
      };
    case DELETE_FAVORITES:
      return state;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    case SEARCH_PRODUCT:
      const searchProducts =
        action.payload.list !== undefined
          ? action.payload.list.map((product) => {
              return newProduct(product);
            })
          : [];
      return {
        ...state,
        searchProducts: [...searchProducts],
        counts: {
          ...state.counts,
          searchCount: action.payload.count,
        },
      };

    case FETCH_NEW:
      const newProducts =
        action.payload.list !== undefined
          ? action.payload.list.map((product) => {
              return newProduct(product);
            })
          : [];
      return {
        ...state,
        newProducts: [...newProducts],
        counts: {
          ...state.counts,
          newCount: action.payload.count,
        },
      };

    default:
      return state;
  }
};

export default wbReducer;
