export const introSteps = (userName) => {
  return [
    {
      element: '.root',
      intro: `<h4>Добро пожаловать <br>${userName}</h4>
                  <h6>Все возможные инструменты будут доступны после покупки подписки</h6>
                  <br>
                 Приветствуем вас на сервисе аналитики Wildberries - MarketProfit. Пройдите небольшую экскурсию по нашему сервису`,
    },
    {
      element: '.navbar-user',
      intro:
        'В меню пользователя вы можете увидеть информацию о себе, посмотреть данные по вашему тарифному плану, а так же отслеживать понравившиеся вам товары',
    },
    {
      element: '.navbar-form',
      intro:
        'Для того чтобы найти интересующий вас товар, скопируйте ссылку с Wildberries и вставьте к нам в поисковую строку',
    },
    {
      element: '.tariffs',
      intro:
        'На странице <strong>"Тарифы"</strong> вы можете приобрести  подходящий вам тарифный план, а так же изучить полную информацию об акциях',
    },
    {
      element: '.brands',
      intro:
        'Если вы хотите найти и получить сводные данные по конкретному бренду, то воспользуйтесь этим инструментом. Он станет доступным после оплаты тарифа <strong>Расширенный</strong> или выше',
    },
    {
      element: '.categories',
      intro:
        'Тут вы получите доступ к данным по категориям. Для этого достаточно тарифа <strong>Базовый</strong> или выше',
    },
    {
      element: '.sellers',
      intro:
        'Ну а если вы хотите отслеживать информацию по конкретным продавцам, то вам сюда. Доступно только на тарифе <strong>Профессиональный</strong>',
    },
    {
      element: '.stocks',
      intro: 'Просматривайте доступные склады',
    },
    {
      element: '.favorites',
      intro:
        'Инструмент <strong>"Избранные товары"</strong> поможет вам сохранить в персонализированные подборки те товары, которые вы бы хотели постоянно отслеживать',
    },
    {
      element: '.main__video-container ',
      intro:
        'Для более подробного ознакомления с сервисом рекомендуем  посмотреть видео',
    },
    {
      element: '.footer_contacts ',
      intro:
        'Ссылка на наш Telegram, YouTube-канал, а так же способы связи со службой поддержки',
    },
  ];
};
