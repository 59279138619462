import numeral from 'numeral';

class ProductHistory {
  constructor(
    createdAt,
    sellQty,
    qty,
    price,
    basicPrice,
    promoPrice,
    clientPrice,
    finishPrice,
    revenue
  ) {
    this.createdAt = createdAt;
    this.sellQty = sellQty;
    this.qty = qty;
    this.price = price;
    this.basicPrice = basicPrice;
    this.promoPrice = promoPrice;
    this.clientPrice = clientPrice;
    this.finishPrice = finishPrice;
    this.revenue = revenue;
  }
}

export default ProductHistory;

export const newProductHistory = (prod) => {
  return new ProductHistory(
    prod.createdAt,
    prod.sellQty === null ? 0 : prod.sellQty,
    prod.qty === null ? 0 : prod.qty,
    `${numeral(prod.price).format(`0,000,000`)}`,
    prod.basicPrice === null
      ? `${numeral(prod.price).format(`0,000,000`)}`
      : `${numeral(prod.basicPrice).format(`0,000,000`)}`,
    prod.promoPrice === null
      ? `${numeral(prod.price).format(`0,000,000`)}`
      : `${numeral(prod.promoPrice).format(`0,000,000`)}`,
    prod.clientPrice === null
      ? `${numeral(prod.price).format(`0,000,000`)}`
      : `${numeral(prod.clientPrice).format(`0,000,000`)}`,
    prod.finishPrice,
    prod.revenue === null ? 0 : prod.revenue
  );
};
