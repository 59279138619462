import React from 'react';

const Intro = (props) => {
  return (
    <div className='intro'>
      <img
        src=' /assets/img/svg/img-2.svg'
        alt=''
        height='130px'
        className='d-none d-xl-block'
      />

      <p className='intro__description'>
        Добро пожаловать в MarketProfit! <br />
        Наш сервис представляет собой систему оперативного сбора, хранения и
        анализа огромного массива данных о всех товарах маркетплейса
        Wildberries. Алгоритмы MarketProfit работают круглосуточно, чтобы
        ежедневно наши пользователи могли получать необходимые ответы о
        продажах, остатках, выручке любого продавца и бренда. Теперь вся
        статистика находится в одном месте и уже готова к использованию в
        удобном интерфейсе.
      </p>
      <div className='main__video-container'>
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/pGf0JhH6OeA'
          title='MarketProfit'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Intro;
