import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';

import { brandsCategoriesOptions } from '../chart/chartOptions';
import {
  otherRevenue,
  otherSales,
  seriesRvenue,
  seriesSales,
} from '../chart/chartSort';

const DonutChart = ({ data }) => {
  const optionsSales = {
    ...brandsCategoriesOptions.options,
    labels:
      data.length > 5
        ? [...seriesSales(data, 'title'), 'Остальные']
        : [...seriesSales(data, 'title')],
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: '50%',
          labels: {
            show: true,

            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val.length >= 7 ? val.slice(0, 7) + '...' : val;
              },
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: 0,
              formatter: function (val) {
                return `${numeral(val).format(`0,000,000 `)} шт.`;
              },
            },
            total: {
              show: true,
              label: 'Общие',
              color: '#373d3f',
              formatter: function (w) {
                return `${numeral(
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0)
                ).format(`0,000,000 `)} шт.`;
              },
            },
          },
        },
      },
    },
    title: {
      text: 'Продажи',
    },
  };

  const optionsRevenue = {
    ...brandsCategoriesOptions.options,
    labels:
      data.length > 5
        ? [...seriesRvenue(data, 'title'), 'Остальные']
        : [...seriesRvenue(data, 'title')],
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val.length >= 7 ? val.slice(0, 7) + '...' : val;
              },
            },
            value: {
              show: true,
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              color: undefined,
              offsetY: 0,
              formatter: function (val) {
                return `${numeral(val).format(`0,000,000 `)}₽`;
              },
            },
            total: {
              show: true,
              label: 'Общая',
              color: '#373d3f',
              formatter: function (w) {
                return `${numeral(
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0)
                ).format(`0,000,000`)} ₽`;
              },
            },
          },
        },
      },
    },
    title: {
      text: 'Выручка',
    },
  };

  return (
    <div className="phill__chartable">
      <Chart
        type="donut"
        options={optionsSales}
        series={
          data.length > 5
            ? [...seriesSales(data, 'SumSellQty'), +otherSales(data)]
            : [...seriesSales(data, 'SumSellQty')]
        }
      />

      <Chart
        type="donut"
        options={optionsRevenue}
        series={
          data.length > 5
            ? [...seriesRvenue(data, 'SumRevenue'), +otherRevenue(data)]
            : [...seriesRvenue(data, 'SumRevenue')]
        }
      />
    </div>
  );
};

export default React.memo(DonutChart);
