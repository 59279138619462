import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';

import { historyOptions } from '../chart/chartOptions';
import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';

const ProductStockHistoryChart = ({ history, days, options, title }) => {
  const dates = history.map((cat) => {
    return cat.map((ct) => {
      return ct.createdAt;
    });
  });

  const stocks = history.map((stock) => {
    return {
      name: stock[0].name,
      history: stock
        .map((ct) => {
          return options ? +ct.sellQty : +ct.qty;
        })
        .reverse(),
    };
  });

  const mergeDates = (...date) => {
    let allDate = [];

    date.forEach((dat) => {
      allDate = [...allDate, ...dat];
    });
    const uniqueDate = allDate.filter(
      (item, index) => allDate.indexOf(item) === index
    );
    return uniqueDate.sort();
  };

  const getIndexOfShortArr = () => {
    const shortArr2 = stocks.map((st, i) => {
      return st.history.length < mergeDates(...dates).length ? i : -1;
    });
    const arrOfI = shortArr2.filter((arr) => {
      return arr !== -1;
    });

    return arrOfI;
  };

  const shortArrDate = getIndexOfShortArr().map((arr) => {
    return history[arr]
      .map((cat) => {
        return cat.createdAt;
      })
      .reverse();
  });

  const shortArrQty = getIndexOfShortArr().map((arr) => {
    return history[arr]
      .map((cat) => {
        return options ? +cat.sellQty : +cat.qty;
      })
      .reverse();
  });

  const getIndex = (arrSm, arrBig) => {
    let ret = [];

    for (let i = 0; i < arrSm.length; i += 1) {
      if (arrBig.indexOf(arrSm[i]) > -1) {
        ret.push(arrBig.indexOf(arrSm[i]));
      }
    }

    return ret;
  };

  const arrayModified = (arr, new_index, longArr) => {
    let newArr = [];
    for (let i = 0; i < new_index[0]; i++) {
      newArr.push(0);
    }

    let finalArr = [...newArr, ...arr];

    if (finalArr.length < longArr.length) {
      for (let i = finalArr.length; i < longArr.length; i++) {
        finalArr.push(0);
      }
    }

    return finalArr;
  };

  const series = [
    ...stocks.map((cat, i) => {
      return {
        name: cat.name,
        data:
          cat.history.length < mergeDates(...dates).length
            ? arrayModified(
                shortArrQty[
                  getIndexOfShortArr().findIndex((arr) => {
                    return arr === i;
                  })
                ],
                getIndex(
                  shortArrDate[
                    getIndexOfShortArr().findIndex((arr) => {
                      return arr === i;
                    })
                  ],
                  mergeDates(...dates)
                ),
                mergeDates(...dates)
              )
            : cat.history,
      };
    }),
  ];

  const areaChart = {
    options: {
      ...historyOptions,
      xaxis: {
        ...historyOptions.xaxis,
        categories: mergeDates(...dates),
      },
      yaxis: [
        {
          seriesName: options ? 'Продажи' : 'Остатки',
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0,000,000`)} шт.`;
            },
          },

          title: {
            text: options ? 'Продажи' : 'Остатки ',
          },
        },
      ],
      tooltip: {
        theme: 'dark',
        followCursor: true,
        style: {
          fontSize: series.length > 10 ? '10px' : '12px',
        },
        x: {
          format: 'dd/MM/yy',
        },

        // fixed: {
        //   enabled: series.length > 10 ? true : false,
        //   position: 'topLeft',
        //   offsetX: -30,
        //   offsetY: -150,
        // },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: { height: 300 },
            yaxis: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <div className="graph">
      <Panel>
        <PanelHeader>{`${
          options
            ? 'Продажи по размерам'
            : !title
            ? 'Остаток по складам'
            : 'Остаток по размерам'
        } за количество дней - ${days}`}</PanelHeader>
        <PanelBody className="p-0">
          <Chart type="line" options={areaChart.options} series={series} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(ProductStockHistoryChart);
