import React from 'react';
import numeral from 'numeral';

import { Panel, PanelHeader, PanelBody } from '../panel/panel';

const Billing = ({
  activeTariff,
  discounts,
  mounth,
  form,
  params,
  promoCode,
  basic14Active,
}) => {
  return (
    <div className="billing">
      <Panel>
        <PanelHeader noButton={true}>Оплата подписки</PanelHeader>
        <PanelBody>
          <h1>Сумма к оплате:</h1>
          <h1 style={{ fontWeight: '700', color: '#618633' }}>{`${numeral(
            activeTariff.price * ((100 - discounts[mounth]) / 100) * mounth
          ).format(`0,000,000`)} ₽ `}</h1>
          <p>
            Доступ к сервису MarketProfit по тарифу{' '}
            <span style={{ fontWeight: '700', fontSize: '1rem' }}>
              {activeTariff.title}
            </span>
            , сроком на{' '}
            <span style={{ fontWeight: '700', fontSize: '1rem' }}>
              {activeTariff.id === 'base' && promoCode.code === 'BASIC14'
                ? '14'
                : mounth}
            </span>{' '}
            {activeTariff.id === 'base' && promoCode.code === 'BASIC14'
              ? 'дней'
              : mounth === '1'
              ? 'месяц'
              : mounth === '3'
              ? 'месяца'
              : mounth === '6'
              ? 'месяцев'
              : ''}
            .
          </p>
          <div className="buttons">
            {activeTariff.id === 'base' && promoCode.code === 'BASIC14' ? (
              <button
                onClick={basic14Active}
                className="btn-new btn-primary m-r-10"
              >
                Активировать
              </button>
            ) : (
              <form
                action={params.api_url}
                method={params.api_method}
                target="_blank"
              >
                {form()}
                <button className="btn-new btn-primary m-r-10">
                  Оплата онлайн
                </button>
              </form>
            )}
            {/* <button className='btn-new btn-lime'>Безналичная оплата</button> */}
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default Billing;
