import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import SidebarNavList from './sidebar-nav-list.jsx';
import Menu from './menu.js';

const SidebarNav = () => {
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);
  const [menus] = useState(Menu);

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (clicked === -1 && match) {
      setActive(-1);
      setClicked(1);
    } else {
      setActive(active === i ? -1 : i);
      setClicked(1);
    }
  };

  return (
    <ul className='nav'>
      <li className='nav-header'>Навигация</li>
      {menus.map((menu, i) => (
        <Route
          path={menu.path}
          exact={menu.exact}
          key={i}
          children={({ match }) => (
            <SidebarNavList
              data={menu}
              key={i}
              expand={(e) => handleExpand(e, i, match)}
              active={i === active}
              clicked={clicked}
            />
          )}
        />
      ))}
    </ul>
  );
};

export default React.memo(SidebarNav);
