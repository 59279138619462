import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import DropdownNotification from './dropdown/notification.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import SearchForm from './search/form.jsx';

const Header = (props) => {
  const [collapseMegaMenu, setCollapseMegaMenu] = useState(false);

  const isAuth = useSelector((state) => !!state.auth.isLogin);

  const toggleMegaMenu = () => {
    setCollapseMegaMenu(!collapseMegaMenu);
    props.handlerSidebar(collapseMegaMenu);
  };

  return (
    <div id='header' className='header navbar-default'>
      <div className='navbar-header'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className='navbar-brand'>
            {/* <span className='navbar-logo'></span> */}
            <b>Market</b> Profit
          </div>
        </Link>

        <button type='button' className='navbar-toggle pt-0 pb-0 mr-0'></button>

        <button
          onClick={toggleMegaMenu}
          type='button'
          className='navbar-toggle'
        >
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
        </button>
      </div>

      <ul className='navbar-nav navbar-right'>
        <SearchForm />

        {isAuth ? (
          <>
            {/* <DropdownNotification /> */}
            <DropdownProfile />
          </>
        ) : (
          <div className='buttons__group'>
            <Link to='/login'>
              <button className='button button__log'>
                <span>Войти</span>
              </button>
            </Link>
            <Link to='/register'>
              <button className='button button__reg'>
                <span>Регистрация</span>
              </button>
            </Link>
          </div>
        )}
      </ul>
    </div>
  );
};

export default React.memo(Header);
