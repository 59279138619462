import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactNotification from 'react-notifications-component';
import SweetAlert from 'react-bootstrap-sweetalert';
import validator from 'validator';
import 'react-notifications-component/dist/theme.css';

import error, { success } from '../components/error/errorNotification';
import marketprofit from '../apis/marketprofit';
import Loader from '../components/loaders/Loader';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel.jsx';

const DemoFormPage = () => {
  const info = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(isAuthenticated ? info.title : '');
  const [email, setEmail] = useState(isAuthenticated ? info.email : '');
  const [phone, setPhone] = useState(isAuthenticated ? info.phone : '');
  const [comment, setComment] = useState('');
  const [sweetAlertError, setSweetAlertError] = useState(false);

  const sentFormHandler = async () => {
    setIsLoading(true);
    try {
      const formDate = new FormData();
      formDate.append('title', name);
      formDate.append('phone', phone);
      formDate.append('email', email);
      comment && formDate.append('comment', comment);

      const response = await marketprofit.post(`/demo_request`, formDate);
      if (!!response.data.success) {
        success('Ваша заявка отправлена. Мы скоро с вами свяжемся.');
      }
    } catch (e) {
      error('Не удалось отправить заявку...  Попробуйте еще раз позже.');
    }
    setIsLoading(false);
    setSweetAlertError(false);
  };

  return (
    <div className='main'>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='main__header'>
        <h1 className='page-header'>
          Заявка на демонстрацию
          <small>{/* header small text goes here... */}</small>
        </h1>
      </div>

      <Panel>
        <PanelHeader>Форма для демонстрации и теста системы</PanelHeader>
        <PanelBody>
          <p>
            Мы создавали наш сервис, опираясь на запросы реальных продавцов
            маркетплейсов, понимая их потребности. Работать с MarketProfit легко
            и Вам не потребуется обладать какими-либо специальными знаниями для
            этого. Чтобы Вы могли быстрее приступить к аналитике –
            воспользуйтесь бесплатной демонстрацией, во время которой наш
            менеджер проведет Вас по платформе и ответит на все вопросы. В
            результате короткой онлайн встречи Вам будет предоставлен бесплатный
            доступ к системе на сутки.
          </p>

          <form className='form-horizontal form-bordered'>
            <div className='form-group row'>
              <label className='col-lg-4 col-form-label'>Ваше имя:</label>
              <div className='col-lg-8'>
                <input
                  className='form-control'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-lg-4 col-form-label'>Ваш email</label>
              <div className='col-lg-8'>
                <input
                  className='form-control'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-lg-4 col-form-label'>
                Ваш контактный телефон:
              </label>
              <div className='col-lg-8'>
                <InputMask
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask='+999999999999'
                  maskChar={''}
                  placeholder=''
                  className='form-control'
                ></InputMask>
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-lg-4 col-form-label'>
                Комментарий, если имеется:
              </label>
              <div className='col-lg-8'>
                <textarea
                  className='form-control'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
          </form>

          <button
            className='btn-new btn-success m-t-20 m-b-10'
            onClick={() => {
              if (name.length === 0) {
                error('Поле "Ваше имя" не должно быть пустым');
                return;
              } else if (!validator.isEmail(email)) {
                error('Введите верный почтовый адрес');
                return;
              } else if (phone.length < 11) {
                error('Минимум 10 цифр в номере телефона');
                return;
              } else {
                setSweetAlertError(true);
              }
            }}
          >
            Заказать демонстрацию
          </button>
          {sweetAlertError && (
            <SweetAlert
              success
              showCancel
              confirmBtnText='Да, подтверждаю.'
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='default'
              cancelBtnText='Отмена'
              title='Проверьте ваши данные, чтобы мы могли с вами связаться'
              onConfirm={sentFormHandler}
              onCancel={() => setSweetAlertError(false)}
            >
              {`Имя: ${name ? name : 'Не указано'}, номер: ${
                phone ? phone : 'Не указан'
              }, email: ${email ? email : 'Не указан'}, комментарий: ${
                comment ? comment : 'Не указан'
              }`}
            </SweetAlert>
          )}
        </PanelBody>
      </Panel>
    </div>
  );
};

export default DemoFormPage;
