import React from 'react';
import { Link } from 'react-router-dom';

const NavigationPage = ({
  links,
  activePill,
  isLoading,
  subcategories,
  titles,
}) => {
  return (
    <div className='buttons__group '>
      <Link to={links[0]}>
        <button
          className={`button ${activePill === '1' ? 'active' : ''} ${
            isLoading ? 'disabled' : ''
          } `}
          disabled={isLoading}
          // onClick={() => togglePill('1')}
        >
          {titles[0]}
        </button>
      </Link>
      {subcategories ? (
        <Link to={links[5]}>
          <button
            className={`button ${activePill === '6' ? 'active' : ''} ${
              isLoading ? 'disabled' : ''
            }`}
            disabled={isLoading}
            // onClick={() => togglePill('6')}
          >
            {titles[5]}
          </button>
        </Link>
      ) : null}
      <Link to={links[1]}>
        <button
          className={`button ${activePill === '2' ? 'active' : ''} ${
            isLoading ? 'disabled' : ''
          }`}
          disabled={isLoading}
          // onClick={() => togglePill('2')}
        >
          {titles[1]}
        </button>
      </Link>

      <Link to={links[2]}>
        <button
          className={`button ${activePill === '3' ? 'active' : ''} ${
            isLoading ? 'disabled' : ''
          }`}
          disabled={isLoading}
          // onClick={() => togglePill('3')}
        >
          {titles[2]}
        </button>
      </Link>

      <Link to={links[4]}>
        <button
          className={`button ${activePill === '5' ? 'active' : ''} ${
            isLoading ? 'disabled' : ''
          }`}
          disabled={isLoading}
          // onClick={() => togglePill('5')}
        >
          {titles[4]}
        </button>
      </Link>

      <Link to={links[3]}>
        <button
          className={`button ${activePill === '4' ? 'active' : ''} ${
            isLoading ? 'disabled' : ''
          }`}
          disabled={isLoading}
          // onClick={() => togglePill('4')}
        >
          {titles[3]}
        </button>
      </Link>
    </div>
  );
};

export default NavigationPage;
