import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  Button,
} from 'reactstrap';
import moment from 'moment';
import ReactNotification from 'react-notifications-component';

import Loader from '../../components/loaders/Loader';
import { addFavorites, searchProduct } from '../../store/actions/wb';
import Table from '../../components/tables/Table';
import { productColumns } from '../../components/tables/productColumns';
import error, { success } from '../../components/error/errorNotification';
import { saveAll } from '../../components/tables/saveAll';

const SearchPage = (props) => {
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [words, setWords] = useState('');
  const [offSet, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [order_by, setOrder_by] = useState('SumRevenue');
  const [dir, setDir] = useState('desc');
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const count = useSelector((state) => state.wb.counts.searchCount);
  const searchProducts = useSelector((state) => state.wb.searchProducts);

  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;

  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);

      try {
        if (!words) {
          setIsLoading(false);

          return;
        }
        await dispatch(
          searchProduct(limit, offSet, order_by, dir, date_from, date_to, words)
        );
      } catch (e) {
        error('Что-то пошло не так... Попробуйте еще позже');
      }

      setIsLoading(false);
    };

    rendering();
  }, [dispatch, limit, offSet, date_from, dir, order_by, date_to, words]);

  const searchHander = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await dispatch(
        searchProduct(
          limit,
          offSet,
          order_by,
          dir,
          date_from,
          date_to,
          searchValue
        )
      );
    } catch (e) {
      error('Не удалось совершить поиск, попробуйте позже...');
    }
    setIsLoading(false);
  };

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const saveFavoritesHandler = async (ids) => {
    setIsLoading2(true);
    try {
      await dispatch(addFavorites(ids));
      success('Успешно сохранено');
    } catch (e) {
      error('Не удалось сохранить, попробуйте еще раз позже...');
    }
    setIsLoading2(false);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ReactNotification />

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Поиск</li>
      </ol>
      <h1 className="page-header">Поиск по продукту</h1>
      <form onSubmit={searchHander}>
        <InputGroup size="lg" className="m-b-20">
          <Input
            placeholder="Ввведите слова для поиска..."
            type="text"
            className="input-white"
            value={searchValue}
            onBlur={() => {
              setWords(searchValue);
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <InputGroupButtonDropdown addonType="append">
            <Button color="inverse">
              <i className="fa fa-search fa-fw"></i> Поиск
            </Button>
          </InputGroupButtonDropdown>
        </InputGroup>
      </form>

      <Table
        products
        isLoading={isLoading2}
        count={count}
        limitHandler={limitHandler}
        offsetHandler={offsetHandler}
        onOffset={onOffset}
        cleanOffset={cleanOffset}
        lastPage={lastPage}
        sortHandler={sortHandler}
        pageSize={20}
        saveFavoritesHandler={saveFavoritesHandler}
        dateHandler={dateHandler}
        title={
          isLoading
            ? ''
            : `Количество товаров - ${count}, за количество дней - ${days}`
        }
        data={isLoading ? [] : searchProducts}
        columns={productColumns}
        saveAll={() => {
          let filters = { name: { operator: 'like', value: searchValue } };

          return saveAll(
            `/wb/products`,

            order_by,
            dir,
            date_from,
            date_to,
            filters,
            `Отчет по поиску за ${days} дней`
          );
        }}
      />
    </div>
  );
};

export default SearchPage;
