import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import Table from '../../components/tables/Table';
import * as authActions from '../../store/actions/auth';
import * as displayActions from '../../store/actions/display';
import * as wbActions from '../../store/actions/wb';

const BillsPage = () => {
  const info = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [activeBill, setActiveBill] = useState('');
  const [limit, setLimit] = useState(20);
  const [offSet, setOffset] = useState(0);
  const [haveTariff, setHaveTariff] = useState(false);
  const [render, setRender] = useState(false);
  const [sweetAlertError, setSweetAlertError] = useState(false);
  const [filterBills, setFilterBills] = useState({
    status: '',
    tariff_id: '',
    user_id: '',
    date_from: '',
    date_to: '',
  });
  const data = useSelector((state) => state.auth.billsList);
  const reqInfo = useSelector((state) => state.wb);
  const count = useSelector((state) => state.auth.billsCount);
  const usersBillsList = data.filter((bill) => {
    return bill.user_id === info.id;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageContentFullWidth(true));
    dispatch(displayActions.setPageContentFullHeight(true));
    dispatch(displayActions.setPageFooter(false));

    return () => {
      dispatch(displayActions.setPageContentFullHeight(false));
      dispatch(displayActions.setPageContentFullWidth(false));
      dispatch(displayActions.setPageFooter(true));
    };
  }, [dispatch]);

  useEffect(() => {
    const render = async () => {
      setIsLoading(true);
      try {
        await dispatch(authActions.fetchBills(limit, offSet, filterBills));
        if (info.tariff_expired !== null) {
          await dispatch(wbActions.requestAvailable());

          setHaveTariff(true);
        }
      } catch (e) {
        error('Не удалось загрузить список счетов, попробуйте позже...');
      }
      setRender(true);

      setIsLoading(false);
    };
    render();
  }, [dispatch, limit, offSet, filterBills, info.tariff_expired]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };
  //   const sortHandler = (by, dr) => {
  //     setOrder_by(by);
  //     !!dr ? setDir('desc') : setDir('asc');
  //   };

  const deleteHandler = async () => {
    try {
      await dispatch(authActions.deleteBill(activeBill));
      await dispatch(authActions.fetchBills(limit, offSet, filterBills));
    } catch (e) {
      error('Не удалось удалить счет, попробуйте позже...');
    }
    setSweetAlertError(false);
  };

  const filterBillsHandler = (id, filter) => {
    id === 'user_id' &&
      setFilterBills((state) => ({ ...state, user_id: filter }));
    id === 'status' &&
      setFilterBills((state) => ({ ...state, status: filter }));
    id === 'tariff' &&
      setFilterBills((state) => ({ ...state, tariff_id: filter }));
    id === 'ctime' &&
      setFilterBills((state) => ({
        ...state,
        date_from: filter.date_from,
        date_to: filter.date_to,
      }));
  };

  const billsColumn = [
    {
      Header: 'Информация',

      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          tipText: 'ID счета',
          disableFilters: true,
          width: 70,
          disableSortBy: true,
        },
        {
          Header: 'Тариф',
          accessor: 'tariff',
          tipText: 'Название тарифа',
          disableSortBy: true,
        },
        {
          Header: 'Код',
          accessor: 'code',
          tipText: 'Скидочный код',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Срок',
          accessor: 'period',
          tipText: 'Срок подключения тарифа',
          disableSortBy: true,
          disableFilters: true,

          width: 70,
          Cell: ({ row }) => {
            return `${row.values.period} мес.`;
          },
        },

        {
          Header: 'Статус',
          accessor: 'status',
          tipText: 'Статус платежа',
          disableSortBy: true,
        },
        {
          Header: 'Стоимость',
          accessor: 'amount',
          tipText: 'Стоимость услуги',
          Cell: ({ row }) => {
            return `${numeral(row.values.amount).format(`0,000,000`)} ₽`;
          },
          disableSortBy: true,
          disableFilters: true,
        },

        {
          Header: 'Дата создания',
          accessor: 'ctime',
          tipText: 'Дата создания  платежа',
          disableSortBy: true,
        },

        {
          Header: 'Управление',
          accessor: 'options',
          tipText: 'Управление счетом',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => {
            return row.values.status === 'Оплачено' ? (
              <div></div>
            ) : (
              <>
                <Link target='_blank' to={`/bill/${row.values.id}`}>
                  <div className='btn btn-lime m-r-5 table__edit'>
                    <i className={`fas fa-shopping-cart fa-fw`}></i>
                  </div>
                </Link>

                <div
                  className='btn btn-danger m-r-5 table__edit'
                  onClick={() => {
                    setActiveBill(row.values.id);
                    setSweetAlertError(true);
                  }}
                >
                  <i className={`fas fa-trash fa-fw`}></i>
                </div>
              </>
            );
          },
        },
      ],
    },
  ];

  return (
    <div>
      {isLoading && <Loader />}
      <ReactNotification />

      {sweetAlertError && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText='Да, удалить'
          confirmBtnBsStyle='danger'
          cancelBtnBsStyle='default'
          cancelBtnText='Отмена'
          title='Вы уверены?'
          onConfirm={deleteHandler}
          onCancel={() => setSweetAlertError(false)}
        >
          Мы удалим этот счет
        </SweetAlert>
      )}

      <div className='profile'>
        <div className='profile-header'>
          <div className='profile-header-cover bills'></div>
          <div className='profile-header-content'>
            <div className='profile-header-img'>
              <img src='/assets/img/user/user-12.jpg' alt='' />
            </div>
            <div className='profile-header-info'>
              <h4 className='m-t-10 m-b-5'>{info.title}</h4>
              <p className='m-b-10'>{info.role.title}</p>
              <Link to='/user'>
                <button className='btn btn-xs btn-yellow'>
                  Изменить профиль
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='profile-content'>
        <div className='tab-content p-0'>
          {/* ///////////////////////////////////////////////////////////////////////////////////////////// BillsList */}

          <div className='row row-space-2'></div>
          {render && (
            <table className='table table-profile'>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h4>Ваш тариф</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='divider'>
                  <td colSpan='2'></td>
                </tr>
                {haveTariff ? (
                  <>
                    <tr>
                      <td className='field'>Название тарифа</td>
                      <td>
                        <i className='fa fa-clipboard fa-lg m-r-5'></i>
                        {info.tariff.title}
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>
                        {moment(info.tariff_expired) > moment.now()
                          ? 'Тариф истекает'
                          : 'Тариф истек'}
                      </td>
                      <td>
                        <i className='fa fa-calendar-alt fa-lg m-r-5'></i>
                        {moment(info.tariff_expired).format(
                          ' DD/MM/YYYY, HH:mm'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>Запросов на сегодня осталось</td>
                      <td>
                        <i className='fa fa-search fa-lg m-r-5'></i>
                        {reqInfo.requests_remains}
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>Запросов сделано сегодня </td>
                      <td>
                        <i className='fa fa-search fa-lg m-r-5'></i>
                        {reqInfo.requests_today}
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <th></th>
                      <th>
                        <h4>
                          У вас нету активного пакета. Но вы можете его купить
                        </h4>
                      </th>
                    </tr>
                  </>
                )}

                <tr className='divider'>
                  <td colSpan='2'></td>
                </tr>
                <tr className='highlight'>
                  <td className='field'>&nbsp;</td>
                  <td className='p-t-10 p-b-10'>
                    <Link
                      to='/tariffs'
                      type='submit'
                      className='btn-new btn-primary width-150'
                    >
                      {haveTariff ? 'Добавить дней' : 'Купить'}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <Table
            filterable
            count={billsColumn.length}
            title={`Cписок ваших счетов`}
            limitHandler={limitHandler}
            offsetHandler={offsetHandler}
            onOffset={onOffset}
            cleanOffset={cleanOffset}
            lastPage={lastPage}
            // sortHandler={sortHandler}
            filterSelect={filterBillsHandler}
            pageSize={20}
            filterHandler={filterBillsHandler}
            sortBy={[{ id: 'id', desc: true }]}
            data={isLoading ? [] : usersBillsList}
            columns={billsColumn}
          />
        </div>
      </div>
    </div>
  );
};

export default BillsPage;
