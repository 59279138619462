class ProductPosition {
  constructor(name, createdAt, categoryOrdnum) {
    this.name = name;
    this.createdAt = createdAt;
    this.categoryOrdnum = categoryOrdnum;
  }
}

export default ProductPosition;

export const newProductPosition = (prod) => {
  return prod.history.map((d) => {
    return new ProductPosition(
      `${
        prod.category.parent.parent
          ? prod.category.parent.parent.name + '/'
          : ''
      }${prod.category.parent ? prod.category.parent.name + '/' : ''}${
        prod.category.name
      }`,
      d.createdAt,
      d.categoryOrdnum === null ? '0' : d.categoryOrdnum
    );
  });
};
