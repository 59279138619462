import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../../app.jsx';
import {
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  Button,
  Alert,
} from 'reactstrap';
import Loader from '../../components/loaders/Loader';
import marketprofit from '../../apis/marketprofit';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel';

const NoSearchResultsPage = (props) => {
  const value = props.location.search.split('?search=').join('');
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [searchValue, setSearchValue] = useState(value);
  const tariff = useSelector((state) => state.auth.user.tariff.id);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const onDismiss = () => {
    setVisible(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const searching = async () => {
      setIsLoading(true);
      let id;
      const getLastItem = (searchValue) => {
        const value = searchValue.split('/');
        return value[4];
      };
      searchValue.includes('www.wildberries.ru')
        ? (id = getLastItem(searchValue))
        : (id = searchValue);

      try {
        const response = await marketprofit.get(`/wb/product/${id}`);
        if (!!response.data.success) {
          history.push(`/wildberries/item/${id}`);
        }
      } catch (e) {
        history.push({
          pathname: '/notfound',
          search: `?search=${searchValue}`,
        });
      }

      setIsLoading(false);
      setVisible(true);
    };

    !isAuthenticated
      ? history.push('/login')
      : !tariff
      ? history.push('/notavailable')
      : searching();
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/'>Главная</Link>
        </li>
        <li className='breadcrumb-item active'>Поиск</li>
      </ol>
      <h1 className='page-header'>Не найдено</h1>
      <form onSubmit={searchHandler}>
        <InputGroup size='lg' className='m-b-20'>
          <Input
            placeholder='Enter keywords here...'
            type='text'
            className='input-white'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <InputGroupButtonDropdown addonType='append'>
            <Button color='inverse'>
              <i className='fa fa-search fa-fw'></i> Search
            </Button>
          </InputGroupButtonDropdown>
        </InputGroup>
      </form>
      <Panel>
        <PanelHeader>MarketProfit</PanelHeader>
        <PanelBody>
          {value && (
            <Alert color='danger' isOpen={visible} toggle={onDismiss}>
              По запросу <strong>{value}</strong> ничего не найдено
            </Alert>
          )}
          В поле поиска следует указать URL страницы товара на
          www.wildberries.ru или Артикул товара(он же ID). Поиск по названиям и
          брендам тут не осуществляется.
        </PanelBody>
      </Panel>
    </div>
  );
};

export default NoSearchResultsPage;
