import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';

export const sellersListColumns = [
  {
    Header: 'Information',

    columns: [
      {
        Header: '№',
        accessor: 'num',
        tipText: '№',
        disableSortBy: true,
        disableFilters: true,
        width: 50,
      },

      {
        Header: 'Название',
        accessor: 'name',
        tipText: 'Название продавца',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <Link
              to={{
                pathname: `/wildberries/sellers/products/${row.original.id}`,
                state: row.values.name,
              }}
            >
              {row.values.name}
            </Link>
          );
        },
      },

      {
        Header: 'Рейтинг',
        accessor: 'AvgRating',
        tipText: 'Средний рейтинг продавца',
        sortable: true,
        width: 75,
      },
      {
        Header: 'Комментарии',
        accessor: 'SumFeedbackCount',
        tipText: 'Количество комментариев продавца',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumFeedbackCount).format(
            `0,000,000`
          )} шт.`;
        },
      },
      {
        Header: 'Впервые появился',
        accessor: 'firstseen',
        tipText: 'Дата первого появления',
        sortable: true,
        width: 100,
        Cell: ({ row }) => {
          return moment(row.values.firstseen).format('DD/MM/yyyy');
        },
      },
      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Количество видов товаров продавца',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,000,000`)} ед.`;
        },
      },
      {
        Header: 'Продажи',
        accessor: 'SumSellQty',
        tipText: 'Общее количество продаж продавца',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Выручка за период',
        accessor: 'SumRevenue',
        tipText: 'Общая сумма выручки продавца за период',
        sortable: true,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },
    ],
  },
];
