import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { history } from '../../../app';
import marketprofit from '../../../apis/marketprofit';
import { logout } from '../../../store/actions/auth';

const DropdownProfile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isSu = useSelector((state) => state.auth.is_su);
  const name = useSelector((state) => state.auth.user.title);
  const dispatch = useDispatch();

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const becomeBack = async () => {
    try {
      const response = await marketprofit.post('/su_exit');
      if (!!response.data.success) {
        history.go(0);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className='dropdown navbar-user'
      tag='li'
      style={{ cursor: 'pointer' }}
    >
      <DropdownToggle tag='a'>
        <img src='/assets/img/user/user-12.jpg' alt='avatar' />
        <span className='d-none d-md-inline'>{name}</span>
        <b className='caret'></b>
      </DropdownToggle>
      <DropdownMenu
        className='dropdown-menu dropdown-menu-right'
        style={{ right: '0' }}
        tag='ul'
      >
        {/* <DropdownItem>
          <span className='badge badge-danger pull-right'>2</span> Inbox
        </DropdownItem> */}
        <Link to='/user'>
          <DropdownItem>Настройки</DropdownItem>
        </Link>
        <Link to='/user/bills'>
          <DropdownItem>Тариф и Счета</DropdownItem>
        </Link>
        <Link to='/wildberries/favorites'>
          <DropdownItem>Избранные товары</DropdownItem>
        </Link>
        {isSu ? (
          <DropdownItem
            onClick={becomeBack}
            style={{ color: '#fff', background: '#2d353c' }}
          >
            Вернуться назад <i className='fa fa-user-secret fa-lg m-l-5'></i>
          </DropdownItem>
        ) : null}
        <div className='dropdown-divider'></div>
        <DropdownItem>
          <Link to='/login'>
            <div onClick={onLogout}>Выход</div>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownProfile;
