import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactNotification from 'react-notifications-component';
import validator from 'validator';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import { history } from '../../app';
import Loader from '../../components/loaders/Loader';
import { createUser } from '../../store/actions/auth';
import * as displayActions from '../../store/actions/display';

const UserCreatePage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const info = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageContentFullWidth(true));
    dispatch(displayActions.setPageContentFullHeight(true));
    return () => {
      dispatch(displayActions.setPageContentFullHeight(false));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  const onCreate = async (e) => {
    e.preventDefault();

    if (title.length === 0) {
      error('Введите имя');
      return;
    } else if (password !== password2) {
      error('Пароли должны совпадать');
      setPassword('');
      setPassword2('');
      return;
    } else if (phone.length < 11) {
      error('В номере телефона должно быть минимум 10 цифр');
      return;
    } else if (password.length < 8) {
      error('Пароль слишком короткий');
      return;
    } else if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      error('Простой пароль (Минимум 1 заглавная и  число) ');
      return;
    } else {
      setIsLoading(true);
      try {
        await dispatch(createUser(email, phone, title, password, password2));

        setPassword('');
        setPassword2('');
        setTitle('');
        setEmail('');
        setPhone('');
        history.push('/user');
      } catch (e) {
        error('Ошибка сервара. Попробуйте создать позже');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className='profile'>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='profile-header'>
        <div className='profile-header-cover admin'></div>
        <div className='profile-header-content'>
          <div className='profile-header-img'>
            <img src='/assets/img/user/user-12.jpg' alt='' />
          </div>
          <div className='profile-header-info'>
            <h4 className='m-t-10 m-b-5'>{info.title}</h4>
            <p className='m-b-10'>{info.role.title}</p>
            <label>Создание нового пользователя</label>
          </div>
        </div>
      </div>

      <div className='profile-content'>
        <div className='tab-content p-0'>
          <div className='tab-pane fade show active '>
            <div className='profile'>
              <div className='table-responsiv form-inline'>
                <h2>Создание нового пользователя</h2>
                <table className='table table-profile'>
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='divider'>
                      <td colSpan='2'></td>
                    </tr>
                    <tr>
                      <td className='field'>Email</td>
                      <td>
                        <i className='fa fa-user fa-lg m-r-5'></i>
                        <input
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr className='divider'>
                      <td colSpan='2'></td>
                    </tr>
                    <tr>
                      <td className='field'>ФИО</td>
                      <td>
                        <i className='fa fa-user fa-lg m-r-5'></i>
                        <input
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>Мобильный номер</td>
                      <td>
                        <i className='fa fa-mobile fa-lg m-r-3'></i>
                        <InputMask
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          mask='+999999999999'
                          maskChar={''}
                          placeholder=''
                        ></InputMask>
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>Новый пароль</td>
                      <td>
                        <i className='fa fa-keyboard fa-lg m-r-5'></i>
                        <input
                          type='password'
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='field'>Повторите Новый пароль</td>
                      <td>
                        <i className='fa fa-keyboard fa-lg m-r-5'></i>
                        <input
                          type='password'
                          value={password2}
                          onChange={(e) => {
                            setPassword2(e.target.value);
                          }}
                        />
                      </td>
                    </tr>

                    <tr className='divider'>
                      <td colSpan='2'></td>
                    </tr>
                    <tr className='highlight'>
                      <td className='field'>&nbsp;</td>
                      <td className='p-t-10 p-b-10'>
                        <button
                          onClick={(e) => onCreate(e)}
                          className='btn-new btn-primary width-150'
                        >
                          <i className='fa fa-user-plus fa-lg m-r-5'></i>
                          Создать
                        </button>
                        <button
                          onClick={() => {
                            setPassword('');
                            setPassword2('');
                            setTitle('');
                            setEmail('');

                            setPhone('');
                            history.push('/admin/users_list');
                          }}
                          className='btn-new btn-danger btn-white-without-border width-150 m-l-5'
                        >
                          <i className='fa fa-reply fa-lg m-r-5'></i>
                          Назад
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreatePage;
