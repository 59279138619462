import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { login } from '../../store/actions/auth';
import { history } from '../../app';

const TestShowPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const log = async () => {
      try {
        await dispatch(login('ip.dragod@gmail.com', 'qwe123qwe', false));
        history.push('/');
        history.go(0);
      } catch (e) {
        console.log(e.message);
      }
    };

    log();
  }, [dispatch]);

  return (
    <div className=''>
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/'>Главная</Link>
        </li>
        <li className='breadcrumb-item active'>Вход для теста</li>
      </ol>
      <h1 className='page-header'>
        Страница для теста <small>{/*choose your plan*/}</small>
      </h1>
    </div>
  );
};

export default TestShowPage;
