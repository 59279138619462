import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Calendar from '../../components/calendar/calendar';
import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import Table from '../../components/tables/Table';
import ProductDescription from '../../components/product/productDescription';
import ProductHistoryChart from '../../components/product/productHistoryChart';
import ProductPosition from '../../components/product/productPosition';
import ProductStocks from '../../components/product/productStocks';
import ProductOptions from '../../components/product/productOptions';
import { productInfoColumns } from '../../components/tables/productInfoColumns';
import * as wbActions from '../../store/actions/wb';

const ProductPage = (props) => {
  const id = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState();
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;
  const product = useSelector((state) => state.wb.product);
  const options = useSelector((state) => state.wb.optionsSalesTotal);
  const history = useSelector((state) => state.wb.productHistory);
  const stockHistory = useSelector((state) => state.wb.stocksProductHistory);
  const optionHistory = useSelector((state) => state.wb.optionsSales);
  const productPosition = useSelector((state) => state.wb.productPosition);
  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);

      try {
        await dispatch(wbActions.getProduct(id, date_from, date_to));
      } catch (e) {
        error(
          'Не удалось загрузить информацию о товаре... Попробуйте еще позже'
        );
      }
      setIsLoading(false);
      setIsRender(true);
    };

    rendering();
  }, [dispatch, date_from, date_to, id]);

  const modelHandler = (img) => {
    setImage(img);
    setIsOpen(true);
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active"> Товар</li>
      </ol>
      <h1 className="page-header">
        {product.name !== undefined ? `${product.name}` : 'Страница продукта'}
        <small>
          {product.name !== undefined ? ` ${props.match.params.id}` : ''}
        </small>
      </h1>

      {isRender && (
        <>
          <div className="product__calendar">
            <Calendar dateHandler={dateHandler} />
          </div>
          <div className="product">
            <div className="product__info">
              <ProductDescription
                product={isLoading ? [] : product}
                modelHandler={modelHandler}
              />

              {stockHistory.length > 0 && !isLoading && (
                <ProductStocks
                  product={product}
                  days={days}
                  stockHistory={isLoading ? [] : stockHistory}
                  history={history.reverse()}
                  optionHistory={isLoading ? [] : optionHistory}
                />
              )}

              {options.length > 0 && !isLoading && (
                <ProductOptions
                  days={days}
                  options={options}
                  optionHistory={isLoading ? [] : optionHistory}
                />
              )}
            </div>
            <div className="product__stat">
              <ProductHistoryChart
                history={isLoading ? [] : history}
                days={days}
              />
              <Table
                title={isLoading ? '' : `История за количество дней - ${days}`}
                sortBy={[{ id: 'createdAt', desc: true }]}
                data={isLoading ? [] : history}
                columns={productInfoColumns}
                footer={true}
              />

              {productPosition.length > 0 && !isLoading && (
                <ProductPosition
                  historyPos={productPosition}
                  history={isLoading ? [] : history}
                  days={days}
                />
              )}
            </div>
          </div>
        </>
      )}

      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <img
          className="product__body-gallery--imgBig"
          src={image}
          alt="Product"
        />
      </Modal>
    </div>
  );
};

export default ProductPage;
