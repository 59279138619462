import React, { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import StockDonutChart from '../chart/stockDonutChart.js';
import ProductStockHistoryChart from './productStockHistoryChart.js';

const ProductStocks = ({
  product,
  stockHistory,
  days,
  optionHistory,
  history,
}) => {
  const [activeTab, setActiveTab] = useState('1');

  const stocks = product.stocks;
  const sizes = product.options;

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Panel>
        <PanelHeader>{`Остатки по складам на ${moment()
          .subtract(1, 'days')
          .format('DD/MM/yyyy')}`}</PanelHeader>
        <PanelBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                <span className='d-sm-none'>Сводка</span>
                <span className='d-sm-block d-none'>Сводка</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                <span className='d-sm-none'>История складов</span>
                <span className='d-sm-block d-none'>История складов</span>
              </NavLink>
            </NavItem>

            <NavItem>
              {sizes.length > 0 ? (
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggleTab('3');
                  }}
                >
                  <span className='d-sm-none'>История размеров</span>
                  <span className='d-sm-block d-none'>История размеров</span>
                </NavLink>
              ) : null}
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              {activeTab === '1' && (
                <div className='stock__container'>
                  <StockDonutChart data={stocks ? stocks : []} />
                  {sizes.length > 0 ? (
                    <StockDonutChart
                      title={'Размеры'}
                      data={sizes ? sizes : []}
                    />
                  ) : null}
                </div>
              )}
            </TabPane>
            <TabPane tabId='2'>
              {activeTab === '2' && (
                <ProductStockHistoryChart days={days} history={stockHistory} />
              )}
            </TabPane>

            <TabPane tabId='3'>
              {activeTab === '3' && (
                <ProductStockHistoryChart
                  days={days}
                  history={optionHistory}
                  title={true}
                />
              )}
            </TabPane>
          </TabContent>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ProductStocks;
