import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { historyOptions } from './chartOptions.js';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';

const HistoryChart = ({ history, days }) => {
  const sales = history.map((day) => {
    return +day.SumSellQty;
  });

  const date = history.map((day) => {
    return day.createdAt;
  });

  const revenue = history.map((day) => {
    return +day.SumRevenue;
  });

  const areaChart = {
    options: {
      ...historyOptions,
      xaxis: {
        ...historyOptions.xaxis,
        categories: date,
      },
      yaxis: [
        {
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0,000,000`)} шт.`;
            },
          },
          title: {
            text: 'Продажи',
          },
        },
        {
          opposite: true,
          labels: {
            formatter: function (val, index) {
              return `${numeral(val).format(`0,000,000`)} ₽`;
            },
          },
          title: {
            text: 'Выручка',
          },
        },
      ],
    },
  };

  const series = [
    {
      name: 'Продажи',
      data: sales,
    },

    {
      name: 'Выручка',
      data: revenue,
    },
  ];

  return (
    <div className="graph">
      <Panel>
        <PanelHeader>{`Продажи и выручка за количество дней - ${days}`}</PanelHeader>
        <PanelBody className="p-0">
          <Chart
            type="area"
            options={areaChart.options}
            series={series}
            height={350}
            width={'98%'}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(HistoryChart);
