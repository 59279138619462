import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

export const sellersHistoryColumn = [
  {
    Header: 'Информация',
    Footer: 'Информация',
    columns: [
      {
        Header: 'Дата',
        accessor: 'createdAt',
        tipText: 'Дата',
        sortable: true,
        Cell: ({ row }) => {
          return moment(row.values.createdAt).format('DD/MM/yyyy');
        },
      },

      {
        Header: 'Комментарии',
        accessor: 'SumFeedbackCount',
        tipText: 'Общее количество комментариев',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumFeedbackCount).format(
            `0,000,000`
          )} шт.`;
        },
      },
      {
        Header: 'Рейтинг',
        accessor: 'AvgRating',
        tipText: 'Средний рейтинг продавца',
        sortable: true,
        width: 80,
      },
      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Количество видов товаров у продавца',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,000,000`)} ед.`;
        },
      },
      {
        Header: 'Остаток',
        accessor: 'SumQty',
        tipText: 'Количество остатка товара на складах',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumQty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Цена остатка',
        accessor: 'SumQtyPrice',
        tipText: 'Общая стоимость остатка товара на складах',
        sortable: true,
        width: 80,
      },
      {
        Header: 'Средняя цена',
        accessor: 'AvgPrice',
        tipText: 'Средняя цена товара',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.AvgPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Ср. цена продаж',
        accessor: 'AvgSellPrice',
        tipText: 'Средняя цена продаж',
        sortable: true,
        width: 80,
        Cell: ({ row }) => {
          return `${numeral(row.values.AvgSellPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Продаж',
        accessor: 'SumSellQty',
        tipText: 'Общее количество продаж',
        sortable: true,
        width: 140,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => +row.values.SumSellQty + sum, 0),
            [info.rows]
          );

          return <>{`Итого ${total} шт.(за ${info.rows.length} дней)`}</>;
        },
      },
      {
        Header: 'Выручка',
        accessor: 'SumRevenue',
        tipText: 'Общая сумма выручки',
        sortable: true,
        width: 140,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => +row.values.SumRevenue + sum, 0),
            [info.rows]
          );

          return (
            <>{`Итого ${numeral(total).format(`0,000,000`)} ₽(за ${
              info.rows.length
            } дней)`}</>
          );
        },
      },
    ],
  },
];
