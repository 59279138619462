import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

// import Calendar from '../../components/calendar/calendar';
import error from '../../components/error/errorNotification';
import {
  filterSellers,
  filtersState,
} from '../../components/filters/filterHandlers';
import Loader from '../../components/loaders/Loader';
import Table from '../../components/tables/Table';
import { sellersListColumns } from '../../components/tables/sellersListColumns';
import * as wbActions from '../../store/actions/wb';
import { saveAll } from '../../components/tables/saveAll';

const SellersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offSet, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [order_by, setOrder_by] = useState('SumRevenue');
  const [dir, setDir] = useState('desc');
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const [filter, setFilter] = useState(filtersState);
  const sellersList = useSelector((state) => state.wb.sellersList);
  const count = useSelector((state) => state.wb.counts.sellersCount);
  const dispatch = useDispatch();
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);

      try {
        await dispatch(
          wbActions.getSellers(
            limit,
            offSet,
            order_by,
            dir,
            date_from,
            date_to,
            filter
          )
        );
      } catch (e) {
        error('Что-то пошло не так... Попробуйте еще позже');
      }

      setIsLoading(false);
    };

    rendering();
  }, [dispatch, limit, offSet, date_from, dir, order_by, date_to, filter]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const filterSellersHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterSellers(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Продавцы</li>
      </ol>
      <h1 className="page-header">
        Список продавцов
        {/* <small>{!isLoading && ` Найдено продавцов - ${count} шт.`}</small> */}
      </h1>

      {/* <Search title={'Поиск продавцов'} searchHandler={searchHandler} /> */}
      {/* <Calendar dateHandler={dateHandler} /> */}

      <Table
        filterable
        count={count}
        limitHandler={limitHandler}
        offsetHandler={offsetHandler}
        onOffset={onOffset}
        cleanOffset={cleanOffset}
        lastPage={lastPage}
        sortHandler={sortHandler}
        pageSize={20}
        searchTitle={'Поиск Продавца: '}
        dateHandler={dateHandler}
        filterHandler={filterSellersHandler}
        title={isLoading ? '' : `Список продавцов - ${count} за  ${days} дней`}
        data={isLoading ? [] : sellersList}
        columns={sellersListColumns}
        saveAll={() =>
          saveAll(
            '/wb/sellers',

            order_by,
            dir,
            date_from,
            date_to,
            filter,
            `Отчет по продавцам  за ${days} дней`
          )
        }
      />
    </div>
  );
};
export default SellersPage;
