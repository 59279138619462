import marketprofit from '../../apis/marketprofit';

export const SIGNUP = 'SIGNUP';
export const CREATE_USER = 'CREATE_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_ME = 'FETCH_ME';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_USER = 'CHANGE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_TARIFF = 'SET_TARIFF';
export const FETCH_BILLS = 'FETCH_BILLS';
export const DELETE_BILL = 'DELETE_BILL';

export const login = (email, password, remember) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    remember === true && formData.append('remember', 1);

    const response = await marketprofit.post('/login', formData);

    if (!!response.data.success) {
      await dispatch(fetchMe());
      dispatch({ type: LOGIN, payload: response.data });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.post('/logout');
      if (!!response.data.success) {
        dispatch({ type: LOGOUT });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const fetchMe = () => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/me');

      if (!!response.data.success) {
        dispatch({ type: FETCH_ME, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const fetchUsers = (limit, offset, order_by, dir, filter) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get('/users', {
        params: {
          limit,
          offset,
          order_by,
          dir,
          email: filter && filter.email ? filter.email : null,
          phone: filter && filter.phone ? filter.phone : null,
          role_id: filter && filter.role_id ? filter.role_id : null,
          tariff_id: filter && filter.tariff_id ? filter.tariff_id : null,
        },
      });

      if (!!response.data.success) {
        dispatch({ type: FETCH_USERS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const signUp = (email, phone, title, password, password2) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('title', title);
    formData.append('password', password);
    formData.append('password2', password2);

    const response = await marketprofit.put('/me', formData);

    if (!!response.data.success) {
      dispatch({ type: SIGNUP, payload: response.data });
    }
  };
};

export const createUser = (email, phone, title, password, password2) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('title', title);
    formData.append('password', password);
    formData.append('password2', password2);

    try {
      const response = await marketprofit.put('/user', formData);

      if (!!response.data.success) {
        await dispatch(fetchUsers());

        dispatch({ type: CREATE_USER });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const updateUser = (phone, title, password, password2) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('phone', phone);
    formData.append('title', title);
    if (password) {
      formData.append('password', password);
      formData.append('password2', password2);
    }

    const response = await marketprofit.post('/me', formData);

    if (!!response.data.success) {
      await dispatch(fetchMe());

      dispatch({ type: UPDATE_USER });
    }
  };
};

export const changeUser = (id, role_id, phone, title, password, password2) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('role_id', role_id);
    formData.append('title', title);
    formData.append('phone', phone);

    if (password.length > 0) {
      formData.append('password', password);
      formData.append('password2', password2);
    }

    try {
      const response = await marketprofit.post(`/user/${id}`, formData);

      if (!!response.data.success) {
        await dispatch(fetchUsers());
        await dispatch({ type: CHANGE_USER });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('id', id);

    try {
      const response = await marketprofit.delete(`/user/${id}`, formData);

      if (!!response.data.success) {
        await dispatch(fetchUsers());
        await dispatch({ type: DELETE_USER });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const setTariff = (id, tariff, period) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('tariff_id', tariff);
    formData.append('period', period);

    try {
      const response = await marketprofit.post(`/user/${id}/tariff`, formData);

      if (!!response.data.success) {
        await dispatch(fetchUsers());
        await dispatch({ type: SET_TARIFF });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const fetchBills = (limit, offset, filter) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.get(`/invoices/`, {
        params: {
          limit: limit ? limit : 20,
          offset,
          status: filter && filter.status ? filter.status : null,
          tariff_id: filter && filter.tariff_id ? filter.tariff_id : null,
          user_id: filter && filter.user_id ? filter.user_id : null,
          date_from: filter && filter.date_from ? filter.date_from : null,
          date_to: filter && filter.date_to ? filter.date_to : null,
        },
      });
      if (!!response.data.success) {
        dispatch({ type: FETCH_BILLS, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const deleteBill = (id) => {
  return async (dispatch) => {
    try {
      const response = await marketprofit.delete(`/invoice/${id}`);

      if (!!response.data.success) {
        dispatch({ type: DELETE_BILL, payload: response.data });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
};
