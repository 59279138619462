import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import moment from 'moment';

import { productListOptions } from '../chart/chartOptions';

export const productColumns = [
  {
    Header: 'Information',
    columns: [
      {
        Header: 'Фото',
        accessor: 'img',
        tipText: 'Фото продукта',
        disableSortBy: true,
        disableFilters: true,
        width: 65,

        Cell: ({ row }) => {
          return (
            <div className='table product-images'>
              <img
                className='table__image'
                src={row.original.img}
                width={'auto'}
                alt='logo'
              />
              <div className='table__container'>
                <img
                  className='table__bigImage'
                  src={row.original.imgBig}
                  alt='logo-Big'
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
        tipText: 'ID (Артикул) товара',
        disableSortBy: true,
        // disableFilters: true,

        width: 75,
      },
      {
        Header: 'Название',
        accessor: 'name',
        tipText: 'Название товара',
        sortable: true,

        Cell: ({ row }) => {
          return (
            <Link to={`/wildberries/item/${row.values.id}`}>
              {row.values.name}
            </Link>
          );
        },
      },
      // {
      //   Header: 'Категория',
      //   tipText: 'Категория товара',
      //   accessor: 'category',
      //   sortable: true,
      // },
      {
        Header: 'Бренд',
        accessor: 'brand',
        tipText: 'Бренд товара',
        sortable: true,

        Cell: ({ row }) => {
          return (
            <Link
              to={{
                pathname: `/wildberries/brands/products/${row.original.siteBrandId}`,
                state: row.values.brand,
              }}
            >
              {row.values.brand}
            </Link>
          );
        },
      },
      {
        Header: 'Цвет',
        accessor: 'colors',
        tipText: 'Цвет(а) товара',
        sortable: true,
        disableFilters: true,

        width: 85,
      },

      {
        Header: 'Продавец',
        accessor: 'seller',
        tipText: 'Продавец товара',
        sortable: true,

        maxWidth: 400,
        Cell: ({ row }) => {
          return (
            <Link
              to={{
                pathname: `/wildberries/sellers/products/${row.original.sellerId}`,
                state: row.values.seller,
              }}
            >
              {row.values.seller}
            </Link>
          );
        },
      },
      {
        Header: 'Остаток',
        accessor: 'qty',
        tipText: 'Остаток товара на складах',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.qty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Комментарии',
        accessor: 'feedbackCount',
        tipText: 'Комментарии',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.feedbackCount).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Рейтинг',
        accessor: 'rating',
        tipText: 'Рейтинг товара',
        sortable: true,
        width: 75,
      },
      {
        Header: 'Впервые появился',
        accessor: 'firstseen',
        tipText: 'Дата первого появления',
        sortable: true,
        width: 100,
        Cell: ({ row }) => {
          return moment(row.values.firstseen).format('DD/MM/yyyy');
        },
      },

      {
        Header: 'Цена',
        accessor: 'finishPrice',
        tipText: 'Цена товара',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.finishPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'ЦенаПП',
        accessor: 'clientPrice',
        tipText: 'Цена Постоянного Покупателя',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.clientPrice).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Был в наличии',
        accessor: 'NumbDaysWithQty',
        tipText: 'Количество дней, когда товар был в наличии',
        sortable: true,
        width: 75,
      },

      {
        Header: 'Продаж',
        accessor: 'SumSellQty',
        tipText: 'Общая сумма продаж',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
      },

      {
        Header: 'Дней с продажами',
        accessor: 'NumbDaysWithSell',
        tipText: 'Дней, когда товар продавался',
        sortable: true,
        width: 75,
      },

      {
        Header: 'Выручка',
        accessor: 'SumRevenue',
        tipText: 'Общая выручка за товар',
        sortable: true,
        width: 120,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },

      {
        Header: 'График продаж',
        accessor: 'history',
        tipText: 'График продаж по дням',
        disableSortBy: true,
        disableFilters: true,

        width: 230,
        Cell: ({ row }) => {
          const series = row.values.history.map((day) => {
            return day.sellQty === null ? 0 : +day.sellQty;
          });

          return (
            <Chart
              height={'25px'}
              width={'200px'}
              type='bar'
              options={productListOptions.options}
              series={[{ data: series }]}
            />
          );
        },
      },
    ],
  },
];
