import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const BaseRoute = (props) => {
  const tariff = useSelector((state) => state.auth.user.tariff.id);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const tariffCheker = () => {
    if (!tariff) {
      return false;
    }
    return true;
  };

  return tariffCheker() ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : isAuthenticated ? (
    <Redirect to='/notavailable' />
  ) : (
    <Redirect to='/login' />
  );
};

export default BaseRoute;
