import moment from 'moment';

class User {
  constructor(
    id,
    title,
    role,
    email,
    phone,
    tariff,
    tariff_expired,
    ctime,
    last_access,
    is_disabled
  ) {
    this.id = id;
    this.title = title;
    this.role = role;
    this.email = email;
    this.phone = phone;
    this.tariff = tariff;
    this.tariff_expired = tariff_expired;
    this.ctime = ctime;
    this.last_access = last_access;
    this.is_disabled = is_disabled;
  }
}

export default User;

export const newUser = (user) => {
  return new User(
    user.id,
    user.title,
    user.role.title,
    user.email,
    user.phone,
    user.tariff ? user.tariff.title : '-',
    moment(user.tariff_expired).format(' DD/MM/YYYY ') === 'Invalid date'
      ? '-'
      : moment(user.tariff_expired).format(' DD/MM/YYYY '),
    moment(user.ctime).format('DD/MM/YYYY'),
    moment(user.last_access).format(' DD/MM/YYYY ') === 'Invalid date'
      ? '-'
      : moment(user.last_access).format(' DD/MM/YYYY '),
    user.is_disabled
  );
};
