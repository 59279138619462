export const otherSales = (data) => {
  return data.length > 5
    ? data
        .sort(function (a, b) {
          let keyA = +a.SumSellQty;
          let keyB = +b.SumSellQty;

          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .map((s) => +s.SumSellQty)
        .slice(5)
        .reduce((acc, curr) => acc + curr)
    : [];
};

export const otherRevenue = (data) => {
  return data.length > 5
    ? data
        .sort(function (a, b) {
          let keyA = +a.SumRevenue;
          let keyB = +b.SumRevenue;

          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .map((s) => +s.SumRevenue)
        .slice(5)
        .reduce((acc, curr) => acc + curr)
    : [];
};

export const seriesSales = (data, argument) => {
  return data.length !== []
    ? data
        .sort(function (a, b) {
          let keyA = +a.SumSellQty;
          let keyB = +b.SumSellQty;

          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .slice(0, 5)
        .map((s) =>
          argument === 'SumSellQty' ? +s.SumSellQty : s.name ? s.name : s.title
        )
    : [];
};

export const seriesRvenue = (data, argument) => {
  return data.length > 0
    ? data
        .sort((a, b) => {
          let keyA = +a.SumRevenue;
          let keyB = +b.SumRevenue;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .slice(0, 5)
        .map((s) =>
          argument === 'SumRevenue' ? +s.SumRevenue : s.name ? s.name : s.title
        )
    : [];
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const otherQty = (data) => {
  return data.length > 5
    ? data
        .sort(function (a, b) {
          let keyA = +a.qty;
          let keyB = +b.qty;

          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .map((s) => +s.qty)
        .slice(5)
        .reduce((acc, curr) => acc + curr)
    : [];
};

export const seriesQty = (data, argument) => {
  return data.length > 0
    ? data
        .sort((a, b) => {
          let keyA = +a.qty;
          let keyB = +b.qty;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
        .slice(0, 5)
        .map((s) => (argument === 'qty' ? +s.qty : s.name ? s.name : s.title))
    : [];
};
