import React from 'react';
import { Link } from 'react-router-dom';

import {
  Panel,
  PanelHeader,
  PanelBody,
} from '../../components/panel/panel.jsx';

const TermsPage = (props) => (
  <div className='main'>
    <ol className='breadcrumb float-xl-right'>
      <li className='breadcrumb-item'>
        <Link to='/'>Главная</Link>
      </li>
      <li className='breadcrumb-item active'>Условия использования</li>
    </ol>
    <h1 className='page-header'>Условия использования</h1>

    <Panel>
      <PanelHeader> Договор оказания услуг (публичная оферта)</PanelHeader>

      <PanelBody>
        <div className='terms'>
          <p>
            ИП «Хасанов Александр Игоревич», именуемое в дальнейшем
            «Исполнитель», предлагает Услугу, размещенную на Сайте{' '}
            <b>marketprofit.ru</b>, любому дееспособному
            физическому/юридическому лицу (индивидуальному предпринимателю),
            именуемому в дальнейшем «Заказчик», в случае принятия последним
            условий настоящего Договора (Безусловный акцепт). Настоящий Договор
            (далее – Договор) не требует двустороннего подписания и действителен
            в электронном виде.
          </p>
          <h3>ТЕРМИНЫ</h3>
          <ul>
            Стороны используют следующие значения терминов:
            <li>
              <i>a.</i> <b>Сервис (Сайт)</b> – сайт, расположенный в сети
              Интернет по адресу <b>marketprofit.ru</b>, посредством которого
              Исполнитель предоставляет Услугу. Все права на Сайт принадлежат
              Исполнителю. Никакие положения Договора не могут трактоваться, как
              передача исключительных прав на Сайт Заказчику.
            </li>
            <li>
              <i>b.</i> <b>Услуга</b> – предоставление информации в виде анализа
              и статистики продаж товаров, категорий на сайте
              www.wildberries.ru, статистика по по бренду и продавцу Данные о
              продажах вычисляются на основе публично доступной информации из
              сайта wildberries.ru.
            </li>
            <li>
              <i>c.</i> <b>Заказчик</b> – любое дееспособное физическое или
              юридическое лицо, индивидуальный предприниматель, акцептировавшее
              Договор.
            </li>
            <li>
              <i>d.</i> <b>Акцепт Договора (Безусловный акцепт)</b>– в
              соответствии со статьёй 438 ГК РФ, полное и безоговорочное
              принятие Договора, осуществляемое путем проставления отметки
              (галочки) в окошке «Ознакомлен и согласен с условиями Договора
              оказания услуг (публичной оферты)» при регистрации на Сайте и для
              уже зарегистрированных в личном кабинете.
            </li>
            <li>
              <i>e.</i> <b>Персональные данные</b>– информация, относящаяся к
              Заказчику, в том числе указанная им при заполнении Личного
              кабинета.
            </li>
            <li>
              <i>f.</i> <b>Обработка персональных данных</b>– действия
              (операции), производимые Исполнителем в ручном или автоматическом
              режиме с персональными данными, включая сбор, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              использование, распространение (в том числе передача),
              обезличивание, блокирование и уничтожение персональных данных.
            </li>
            <li>
              <i>g.</i> <b>Личный кабинет</b> – совокупность защищённых страниц
              на Сайте, создаваемых для получения Услуги, посредством которых
              осуществляется использование Заказчиком Сайта и взаимодействие
              Сторон. Доступ к Личному кабинету осуществляется Заказчиком
              посредством ввода Логина и пароля.
            </li>
            <li>
              <i>h.</i> <b>Аналог собственноручной подписи</b> – проставление
              отметки (галочки) в окошке «Ознакомлен и согласен с условиями
              Договора оказания услуг (публичной оферты)» при регистрации на
              Сайте.
            </li>
            <li>
              <i>i.</i> <b>Тариф</b> – совокупность функционала,
              предоставляемого Заказчику в течение оплаченного периода
              использования.
            </li>
            <li>
              <i>j.</i>Договор включает Политику конфиденциальности.
            </li>
          </ul>
          <h3>1. Предмет Договора</h3>
          <ul>
            <li>
              <i>1.1.</i> Исполнитель обязуется предоставить Заказчику Услугу в
              соответствии с Договором, а Заказчик обязуется принимать и
              оплачивать её по расценкам Исполнителя, указанным на Сайте.
            </li>
            <li>
              <i>1.2.</i> В случае несогласия Заказчика с какими-либо из
              положений Договора, Заказчик не вправе использовать Сервис. В
              случае, если Исполнителем были внесены какие-либо изменения в
              Договор, с которыми Заказчик не согласен, он обязан немедленно
              прекратить использование Сервиса.
            </li>
            <li>
              <i>1.3.</i> Исполнитель имеет право в любой момент внести
              изменения в Договор. Изменения вступают в силу на следующий
              календарный день после размещения новой редакции Договора на
              Сайте.
            </li>
            <li>
              <i>1.4.</i> Заказчик обязуется самостоятельно ознакомиться с
              актуальной редакцией Договора.
            </li>
            <li>
              <i>1.5.</i> Регистрируясь на Сайте, Заказчик гарантирует, что
              полностью ознакомился с текстом Договора и принимает его условия.
            </li>
            <li>
              <i>1.6.</i> Регистрируясь на Сайте, Заказчик подтверждает свою
              дееспособность, а также правомочия на оплату Услуги.
            </li>
            <li>
              <i>1.7.</i> Актуальная версия Договора в любое время доступна по
              адресу: (
              <a href='https://my.marketprofit.ru/terms'>
                https://my.marketprofit.ru/terms
              </a>
              ).
            </li>
          </ul>
          <h3>2. Начало пользования Услугой</h3>
          <ul>
            <li>
              <i>2.1.</i> Текст Договора является публичной офертой в
              соответствии со статьёй 435 и частью 2 статьи 437 Гражданского
              кодекса РФ. Договор заключается путем проставления отметки
              (галочки) в окошке «Ознакомлен и согласен с условиями Договора
              оказания услуг (публичной оферты)» при регистрации на Сайте путем
              создания Личного кабинета.
            </li>
            <li>
              <i>2.2.</i> Личный кабинет Заказчика на Сервисе активируется и
              может быть использован для получения Услуг только после акцепта
              Договора.
            </li>
            <li>
              <i>2.3.</i> Для приобретения Услуги и получения бесплатной версии
              доступа к Услуге Заказчику необходимо создать Личный кабинет, где
              нужно указать следующие параметры:
              <ul>
                <li>а) имя Заказчика;</li>
                <li> б) контактный телефон Заказчика;</li>
                <li>
                  в) адрес электронной почты Заказчика. Исполнитель не проверяет
                  достоверность и актуальность данных сведений о Заказчике.
                </li>
              </ul>
            </li>
          </ul>
          <h3>3. Порядок оплаты Услуги и отчетность</h3>
          <ul>
            <li>
              <i>3.1.</i> Стоимость Услуги определяется в соответствии с
              расценками (Тарифами) Исполнителя, доступными Заказчику на
              Сервисе. Услуга оказывается в рамках пакета услуг («Тарифа»).
              Заключая Договор и заказывая Услугу, Заказчик подтверждает факт
              ознакомления и согласия с расценками Исполнителя и принимает на
              себя обязанности по оплате.
            </li>
            <li>
              <i>3.2.</i> Оплата осуществляется с использованием платежных
              систем или путём безналичного перевода на расчетный счёт
              Исполнителя в том числе на основании счёта (полный перечень
              способов оплаты доступен в Личном кабинете).
            </li>
            <li>
              <i>3.3.</i> Заказчик за свой счёт оплачивает комиссии (сборы),
              взимаемые кредитными организациями (платежными
              системами/агрегаторами) при осуществлении оплаты.
            </li>
            <li>
              <i>3.4.</i> Оплата Услуги считается произведенной с момента
              поступления денежных средств на расчетный счет Исполнителя. При
              зачислении денежных средств Исполнитель направляет на электронную
              почту Заказчика либо в его Личный кабинет письмо об активации
              Тарифа.
            </li>
            <li>
              <i>3.5.</i> Исполнитель вправе в одностороннем порядке изменять
              расценки без персонального уведомления Заказчика. Новые расценки
              вступают в силу со дня опубликования и применяются в отношении
              Услуги, оказание которой осуществляется после такого
              опубликования.
            </li>
            <li>
              <i>3.6.</i> Стороны соглашаются с тем, что плательщиком по
              Договору может быть третье лицо. В этом случае Заказчик обязан
              направить Исполнителю соответствующее информационное письмо, а
              плательщик при осуществлении оплаты в назначении платежа должен
              указать сведения, позволяющие идентифицировать Заказчика.
            </li>
            <li>
              <i>3.7.</i> В случае если в течение 5 (пяти) рабочих дней с
              момента окончания срока действия соответствующего Тарифа
              Исполнитель не получил претензий Заказчика, связанных с Услугой,
              считается, что она оказана Заказчику в полном объеме и надлежащим
              образом. Последующие претензии не принимаются.
            </li>
            <li>
              <i>3.8.</i> Стороны пришли к соглашению, что Акт предоставления
              услуги Исполнителем не составляется (за исключением случая, если
              такой Акт требуется Заказчиком).
            </li>
            <li>
              <i>3.9.</i> Датой начала оказания Услуги Исполнителем является
              дата осуществления подключения (продления) соответствующего
              Тарифа.
            </li>
          </ul>
          <h3>4. Срок действия и прекращение Договора</h3>
          <ul>
            <li>
              <i>4.1.</i> Договор вступает в силу с момента его заключения
              Сторонами и действует до исполнения ими своих обязательств.
              Договор является публичной офертой и считается заключённым с
              момента Акцепта Заказчиком в порядке, установленном Договором.
            </li>
            <li>
              <i>4.2.</i> Договор прекращается в следующих случаях: а)
              расторжение Договора по соглашению Сторон; б) отказ Стороны от
              Договора в порядке и по основаниям, предусмотренным
              законодательством РФ и/или Договором.
            </li>
            <li>
              <i>4.3.</i> Любая Сторона вправе отказаться от Договора в
              одностороннем внесудебном порядке путем направления другой Стороне
              уведомления об отказе от Договора не менее чем за 15 (пятнадцати)
              рабочих дней до расторжения. Уведомление направляется на адрес
              электронной почты другой Стороны. При одностороннем отказе от
              Договора за Услугу, оказанную до даты отказа от Договора, денежные
              средства не подлежат возврату.
            </li>
            <li>
              <i>4.4.</i> Отсутствие со стороны Заказчика действий по
              использованию Сервиса в течение 6 месяцев со дня активации Личного
              кабинета считается односторонним отказом Исполнителя от Договора.
            </li>
            <li>
              <i>4.5.</i> Исполнитель вправе в любое время (без соблюдения
              срока, указанного в п.4.3. Договора) в одностороннем внесудебном
              порядке отказаться от Договора в любом из следующих случаев:
              <ul>
                <li> а) нарушения Заказчиком условий Договора;</li>
                <li>
                  б) нарушения Заказчиком исключительного права на результаты
                  интеллектуальной деятельности, доступные на Сервисе, или иных
                  пределов использования Сервиса;
                </li>
                <li>
                  в) неоднократного предоставления недостоверной информации
                  Заказчиком и/или разглашения им конфиденциальной информации;
                </li>
                <li>
                  г) отзыв Заказчиком согласия на обработку персональных данных;
                </li>
                <li>
                  д) использование Заказчиком Сервиса с целью перепродажи
                  полученных с его помощью сведений. В случае отказа Исполнителя
                  от Договора в соответствии с настоящим пунктом суммы,
                  выплаченные Исполнителю Заказчиком до даты отказа, не
                  возвращаются Заказчику.
                </li>
              </ul>
            </li>
            <li>
              <i>4.6.</i> Исполнитель вправе в одностороннем внесудебном порядке
              изменять условия Договора, уведомив Заказчика посредством Сервиса
              или электронной почты не менее чем за 10 (десяти) календарных дней
              до вступления таких изменений в силу. Заказчик самостоятельно
              регулярно отслеживает Сервис на предмет изменений в Договоре.
            </li>
            <li>
              <i>4.7.</i> При несогласии с изменениями в Договоре Заказчик
              обязан в течение 7 (семи) календарных дней с даты уведомления об
              изменениях или их опубликования на Сервисе направить Исполнителю
              сообщение об отказе от Договора. В противном случае такие
              изменения с даты их вступления в силу считаются согласованными
              обеими Сторонами, подлежат применению к отношениям между ними и
              становятся неотъемлемой частью Договора, а Заказчик не имеет права
              предъявлять претензии в связи с изменением Договора.
            </li>
            <li>
              <i>4.8.</i> Исполнитель вправе прекратить и (или) заблокировать
              доступ к Сайту, если Заказчик нарушил Договор или содержащиеся в
              иных документах условия пользования Сайтом, а также в случае
              прекращения действия Сайта по причине технической неполадки или
              проблемы.
            </li>
            <li>
              <i>4.9.</i> Прекращение Договора не влияет на обязанность
              исполнения обязательств, возникших в период его действия и
              сохраняющих силу после прекращения Договора.
            </li>
          </ul>
          <h3>5. Ответственность сторон и разрешение споров</h3>
          <ul>
            <li>
              <i>5.1. </i>Стороны несут ответственность за неисполнение или
              ненадлежащее исполнение Договора в порядке, предусмотренном
              Договором и действующим законодательством РФ.
            </li>
            <li>
              <i>5.2. </i>Размер ответственности Заказчика в любом случае не
              может быть меньше размера убытков, причиненных Исполнителю
              нарушением обязательств и/или гарантий Заказчика.
            </li>
            <li>
              <i>5.3. </i>Исполнитель отвечает перед Заказчиком за виновное
              неисполнение или ненадлежащее исполнение своих обязательств по
              Договору в размере не более чем сумма денежных средств, полученных
              Исполнителем от Заказчика.
            </li>
            <li>
              <i>5.4. </i>Исполнитель не несет ответственности за недополученные
              доходы Заказчика, несоответствие Сервиса его ожиданиям и
              аналогичные последствия.
            </li>
            <li>
              <i>5.5. </i>Исполнитель не несет ответственности за невозможность
              оказания Услуг и/или их ненадлежащее качество по причинам,
              зависящим от Заказчика или иных лиц.
            </li>
            <li>
              <i>5.6. </i>Исполнитель не несет ответственности за временные сбои
              и перерывы в работе Сайта и вызванные ими потерю информации, а
              также за любой ущерб компьютеру Заказчика или иного лица,
              мобильным устройствам, любому другому оборудованию или
              программному обеспечению, вызванный или связанный со скачиванием
              материалов с Сайта или по ссылкам, размещенным на Сайте. При этом
              в случае, если Заказчик вообще не получил Услугу по Договору по
              указанным причинам (то есть ни разу не воспользовался Сервисом)
              произведённая им оплата подлежит возврату Исполнителем.
            </li>
            <li>
              <i>5.7. </i>Исполнитель не несет ответственность перед Заказчиком
              или перед любыми третьими лицами за любой косвенный, случайный,
              неумышленный ущерб, включая упущенную выгоду или потерянные
              данные, вред чести, достоинству или деловой репутации, вызванный в
              связи с использованием Сайта, содержимого Сайта или иных
              материалов, к которым Заказчик или иные лица получили доступ с
              помощью Сайта, возникшие не по вине Исполнителя.
            </li>
            <li>
              <i>5.8. </i>Уплата санкций Стороной производится в течение 5
              (пяти) рабочих дней со дня получения требования другой Стороны об
              их уплате. Требование Исполнителя к Заказчику об уплате санкций
              может быть направлено через Личный кабинет на Сервисе и/или
              посредством электронной почты Заказчика.
            </li>
            <li>
              <i>5.9. </i>Все споры и разногласия, возникающие при исполнении
              Сторонами обязательств по Договору, решаются путем переговоров. В
              случае невозможности их устранения Стороны имеют право обратиться
              за судебной защитой своих интересов по месту нахождения Заказчика.
            </li>
            <li>
              <i>5.10.</i> Стороны установили необходимость соблюдения
              обязательного досудебного претензионного порядка до обращения в
              суд. Срок ответа на претензию устанавливается в 15 (пятнадцать)
              календарных дней. Претензия и ответ на неё пересылаются с
              использованием адресов электронной почты, указанных в Договоре.
            </li>
          </ul>
          <h3>6. Форс-мажорные обстоятельства</h3>
          <ul>
            <li>
              <i>6.1.</i> Стороны освобождаются от ответственности за
              неисполнение или ненадлежащее исполнение обязательств по Договору
              на время действия непреодолимой силы. Под непреодолимой силой
              понимаются чрезвычайные и непреодолимые при данных условиях
              обстоятельства, препятствующие исполнению своих обязательств
              Сторонами по Договору. К ним относятся: стихийные явления
              (землетрясения, наводнения и т. п.), обстоятельства общественной
              жизни (военные действия, чрезвычайные положения, крупнейшие
              забастовки, эпидемии, пандемии и т. п.), запретительные меры
              государственных органов (запрещение перевозок, валютные
              ограничения, международные санкции запрета на торговлю и т. п.). В
              течение этого времени Стороны не имеют взаимных претензий, и
              каждая из Сторон принимает на себя свой риск последствий
              форс-мажорных обстоятельств.
            </li>
            <li>
              <i>6.2.</i> Форс-мажорным обстоятельством, в том числе признаются
              технические сбои в работе Сервиса, которые приводят к искажению
              информации.
            </li>
          </ul>
          <h3>7. Иные положения</h3>
          <ul>
            <li>
              <i>7.1. </i>Исполнитель, его сотрудники и партнеры не гарантируют,
              что качество Услуги будет отвечать ожиданиям Заказчика.
            </li>
            <li>
              <i>7.2. </i>Содержащаяся на Сайте информация не содержит
              каких-либо советов и рекомендаций совершить какую-либо сделку.
            </li>
            <li>
              <i>7.3. </i>Исполнитель обеспечивает функционирование и
              работоспособность Сайта и обязуется оперативно восстанавливать его
              работоспособность в случае технических сбоев и перерывов.
            </li>
            <li>
              <i>7.4. </i>Заказчик несёт ответственность за лиц, действующих по
              его поручению (в том числе сотрудников, агентов), и признает
              действия таких лиц своими собственными. Исполнитель не несет
              ответственности за последствия нарушения Заказчиком настоящего
              пункта.
            </li>
            <li>
              <i>7.5. </i>Заказчик гарантирует, что каждое лицо, участвующее в
              заключении и исполнении Договора в интересах Заказчика (в том
              числе выражающее акцепт от имени Заказчика), является должным
              образом уполномоченным им на осуществление таких действий.
              Заказчик самостоятельно осуществляет контроль за указанными лицами
              и подтверждает, что действия совершаются ими по указанию
              Заказчика, от его имени, а участие таких лиц не влияет на
              обязанности Заказчика по Договору.
            </li>
            <li>
              <i>7.6. </i>Исполнитель вправе привлекать для оказания Услуги
              третьих лиц. При этом согласия и гарантии, предоставляемые
              Исполнителем Заказчику по Договору, распространяются на таких
              третьих лиц.
            </li>
            <li>
              <i>7.7. </i>Исполнитель не несет ответственности за ограничения
              деятельности Заказчика, введённые сайтом www.wildberries.ru, либо
              за невозможность пользования Услугой из-за действий (бездействий)
              владельца сайта www.wildberries.ru.
            </li>
            <li>
              <i>7.8. </i>Заказчик понимает и соглашается с тем, что Исполнителю
              для оказания Услуги может потребоваться информация и данные
              Заказчика, без которых Исполнитель не сможет осуществить оказание
              Услуги. Заказчик предоставляет такую информацию в кратчайшие
              сроки, после получения запроса Исполнителя.
            </li>
            <li>
              <i>7.9. </i>Исполнитель вправе, уведомив Заказчика,
              приостанавливать работу Сервиса и/или оказание Услуги по
              техническим или иным причинам. В течение срока выявления и
              устранения таких причин плата за Услугу не взимается. При этом
              Исполнитель уведомляет Заказчика о приостановлении оказания Услуги
              предварительно, либо в течение 1 рабочего дня с момента
              приостановления через Сервис или через электронную почту.
              Указанное приостановление не является основанием для привлечения
              Исполнителя к ответственности.
            </li>
            <li>
              <i>7.10.</i> Каждая Сторона обязуется не раскрывать в любой форме
              третьим лицам конфиденциальную информацию, связанную с другой
              Стороной, или любую другую информацию, которая стала известна
              Стороне в связи с исполнением настоящего Договора. Не является
              нарушением настоящего пункта предоставление конфиденциальной
              информации в случаях, предусмотренных законодательством.
            </li>
            <li>
              <i>7.11.</i> Заказчик не вправе разглашать информацию о Сервисе,
              программном обеспечении Исполнителя, материалах и технологиях,
              применяемых Исполнителем для оказания Услуг, независимо от
              отдельного уведомления о конфиденциальности такой информации.
              Положения настоящего пункта и пункта 7.13. Договора остаются в
              силе и после прекращения действия Договора в течение 3 (трех) лет.
            </li>
            <li>
              <i>7.12.</i> Заказчик не вправе вести незаконную деятельность,
              распространять вредоносную и массовую («спам») информацию, а также
              получать несанкционированный доступ к информации третьих лиц
              посредством Сервиса.
            </li>
            <li>
              <i>7.13.</i> Уступка прав и обязанностей Заказчика по Договору
              возможна только при условии предварительного согласия Исполнителя.
            </li>
            <li>
              <i>7.14.</i> Никакое из положений Договора не может толковаться
              как предоставление Заказчику прав в отношении результатов
              интеллектуальной деятельности Исполнителя.
            </li>
            <li>
              <i>7.15.</i> Алгоритмы работы Сервиса и его исходные коды (в том
              числе их части) являются коммерческой тайной Исполнителя. Любое их
              использование иначе чем в рамках Договора, является нарушением
              прав Исполнителя, основанием для отказа от Договора.
            </li>
            <li>
              <i>7.16.</i> В случае если Заказчику становится известно о
              недобросовестных действиях третьих лиц в отношении Сервиса
              Исполнителя, в том числе их копировании, Заказчик обязан
              незамедлительно информировать об этом Исполнителя.
            </li>
            <li>
              <i>7.17.</i> Заказчик дает Исполнителю разрешение (согласие) на
              направление Заказчику информации, в том числе рекламного
              характера.
            </li>
          </ul>
          <h3>8. Реквизиты Исполнителя</h3>
          <ul>
            <li>
              ИП «Хасанов Александр Игоревич» (ИНН 773165159730, ОГРНИП
              318774600654543) Электронная почта Исполнителя:
              support@marketprofit.ru Почтовый адрес для направления
              корреспонденции: support@marketprofit.ru
            </li>
          </ul>
        </div>
      </PanelBody>
    </Panel>
  </div>
);
export default TermsPage;
