import {
  SETPAGECONTENTFULLHEIGHT,
  SETPAGECONTENTFULLWIDTH,
  SETPAGEFOOTER,
  SETPAGEHEADER,
  SETPAGESIDEBAR,
} from '../actions/display';

const initialState = {
  pageSidebar: true,
  pageHeader: true,
  pageFooter: true,
  pageContentFullWidth: false,
  pageContentFullHeight: false,
};

const displayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETPAGESIDEBAR:
      return {
        ...state,
        pageSidebar: action.payload,
      };
    case SETPAGEHEADER:
      return {
        ...state,
        pageHeader: action.payload,
      };
    case SETPAGEFOOTER:
      return {
        ...state,
        pageFooter: action.payload,
      };
    case SETPAGECONTENTFULLWIDTH:
      return {
        ...state,
        pageContentFullWidth: action.payload,
      };

    case SETPAGECONTENTFULLHEIGHT:
      return {
        ...state,
        pageContentFullHeight: action.payload,
      };
    default:
      return state;
  }
};

export default displayReducer;
