import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';

const SidebarNavList = (props) => {
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);

  const handleExpand = (e, i, match) => {
    e.preventDefault();
    setActive(active === i ? -1 : i);
    setClicked(1);
  };

  let icon = props.data.icon && <i className={props.data.icon}></i>;
  let img = props.data.img && (
    <div className='icon-img'>
      <img src={props.data.img} alt='' />
    </div>
  );
  let caret = props.data.children && !props.data.badge && (
    <b className='caret'></b>
  );
  let label = props.data.label && (
    <span className='label label-theme m-l-5'>{props.data.label}</span>
  );
  let badge = props.data.badge && (
    <span className='badge pull-right'>{props.data.badge}</span>
  );
  let title = props.data.title && (
    <span>
      {props.data.title} {label}
    </span>
  );
  let highlight = props.data.highlight && (
    <i className='fa fa-paper-plane text-theme'></i>
  );



  return (

   
    <Route
      path={props.data.path}
      exact={props.data.exact}
      children={({ match }) => (
        <li
          className={
            (match ? 'active ' : '') +
            (props.active ||
            (props.clicked === -1 && match) ||
            props.data.search
              ? 'expand '
              : 'closed ') +
            (props.data.children ? 'has-sub ' : '') +
            props.data.path.split('/')[props.data.path.split('/').length - 1]
          }
        >
          
          {props.data.children ? (

           
            <Link to={props.data.path} onClick={props.expand}>
              {caret} {badge} {img} {icon} {title} {highlight}
            </Link>
          ) : ( 
            props.data.help? <a target='_blank' rel='noreferrer' href={props.data.path}>{caret} {badge} {img} {icon} {title} {highlight}</a> :
            <Link to={props.data.path}>
              {caret} {img} {icon} {badge} {title} {highlight}
            </Link>
          )}
          {props.data.children && (
            <ul
              className={
                'sub-menu ' +
                (props.active ||
                (props.clicked === -1 && match) ||
                props.data.search
                  ? 'd-block '
                  : 'd-none')
              }
            >
              {props.data.children &&
                props.data.children.map((submenu, i) => (
                  <SidebarNavList
                    data={submenu}
                    key={i}
                    expand={(e) => handleExpand(e, i, match)}
                    active={i === active}
                    clicked={clicked}
                  />
                ))}
            </ul>
          )}
        </li>
      )}
    />
  );
};

export default React.memo(SidebarNavList);
