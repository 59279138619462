export const filterProducts = (id, value, operator, setFilter) => {
  id === 'feedbackCount' &&
    setFilter((state) => ({
      ...state,
      feedbackCount: { value: value, operator: operator },
    }));
  id === 'qty' &&
    setFilter((state) => ({
      ...state,
      qty: { value: value, operator: operator },
    }));
  id === 'rating' &&
    setFilter((state) => ({
      ...state,
      rating: { value: value, operator: operator },
    }));
  id === 'firstseen' &&
    setFilter((state) => ({
      ...state,
      firstseen: { value: value, operator: operator },
    }));

  id === 'finishPrice' &&
    setFilter((state) => ({
      ...state,
      finishPrice: { value: value, operator: operator },
    }));
  id === 'clientPrice' &&
    setFilter((state) => ({
      ...state,
      clientPrice: { value: value, operator: operator },
    }));
  id === 'NumbDaysWithQty' &&
    setFilter((state) => ({
      ...state,
      NumbDaysWithQty: { value: value, operator: operator },
    }));
  id === 'SumSellQty' &&
    setFilter((state) => ({
      ...state,
      SumSellQty: { value: value, operator: operator },
    }));
  id === 'NumbDaysWithSell' &&
    setFilter((state) => ({
      ...state,
      NumbDaysWithSell: { value: value, operator: operator },
    }));
  id === 'SumRevenue' &&
    setFilter((state) => ({
      ...state,
      SumRevenue: { value: value, operator: operator },
    }));
  id === 'id' &&
    setFilter((state) => ({
      ...state,
      id: { value: value, operator: operator },
    }));
  id === 'name' &&
    setFilter((state) => ({
      ...state,
      name: { value: value, operator: operator },
    }));
  id === 'brand' &&
    setFilter((state) => ({
      ...state,
      brand: { value: value, operator: operator },
    }));
  id === 'seller' &&
    setFilter((state) => ({
      ...state,
      seller: { value: value, operator: operator },
    }));
};

export const filterCategories = (id, value, operator, setFilter) => {
  id === 'title' &&
    setFilter((state) => ({
      ...state,
      title: { value: value, operator: operator },
    }));
  id === 'AvgRating' &&
    setFilter((state) => ({
      ...state,
      AvgRating: { value: value, operator: operator },
    }));
  id === 'SumFeedbackCount' &&
    setFilter((state) => ({
      ...state,
      SumFeedbackCount: { value: value, operator: operator },
    }));
  id === 'Products' &&
    setFilter((state) => ({
      ...state,
      Products: { value: value, operator: operator },
    }));
  id === 'SumSellQty' &&
    setFilter((state) => ({
      ...state,
      SumSellQty: { value: value, operator: operator },
    }));
  id === 'SumRevenue' &&
    setFilter((state) => ({
      ...state,
      SumRevenue: { value: value, operator: operator },
    }));
};

export const filterBrands = (id, value, operator, setFilter) => {
  id === 'name' &&
    setFilter((state) => ({
      ...state,
      name: { value: value, operator: operator },
    }));
  id === 'AvgRating' &&
    setFilter((state) => ({
      ...state,
      AvgRating: { value: value, operator: operator },
    }));
  id === 'firstseen' &&
    setFilter((state) => ({
      ...state,
      firstseen: { value: value, operator: operator },
    }));
  id === 'SumFeedbackCount' &&
    setFilter((state) => ({
      ...state,
      SumFeedbackCount: { value: value, operator: operator },
    }));
  id === 'Products' &&
    setFilter((state) => ({
      ...state,
      Products: { value: value, operator: operator },
    }));
  id === 'SumSellQty' &&
    setFilter((state) => ({
      ...state,
      SumSellQty: { value: value, operator: operator },
    }));
  id === 'SumRevenue' &&
    setFilter((state) => ({
      ...state,
      SumRevenue: { value: value, operator: operator },
    }));
};

export const filterSellers = (id, value, operator, setFilter) => {
  id === 'name' &&
    setFilter((state) => ({
      ...state,
      name: { value: value, operator: operator },
    }));
  id === 'AvgRating' &&
    setFilter((state) => ({
      ...state,
      AvgRating: { value: value, operator: operator },
    }));
  id === 'firstseen' &&
    setFilter((state) => ({
      ...state,
      firstseen: { value: value, operator: operator },
    }));
  id === 'SumFeedbackCount' &&
    setFilter((state) => ({
      ...state,
      SumFeedbackCount: { value: value, operator: operator },
    }));
  id === 'Products' &&
    setFilter((state) => ({
      ...state,
      Products: { value: value, operator: operator },
    }));
  id === 'SumSellQty' &&
    setFilter((state) => ({
      ...state,
      SumSellQty: { value: value, operator: operator },
    }));
  id === 'SumRevenue' &&
    setFilter((state) => ({
      ...state,
      SumRevenue: { value: value, operator: operator },
    }));
};

export const filtersState = {
  feedbackCount: { value: '', operator: '' },
  qty: { value: '', operator: '' },
  rating: { value: '', operator: '' },
  firstseen: { value: '', operator: '' },
  finishPrice: { value: '', operator: '' },
  clientPrice: { value: '', operator: '' },
  NumbDaysWithQty: { value: '', operator: '' },
  SumSellQty: { value: '', operator: '' },
  NumbDaysWithSell: { value: '', operator: '' },
  SumRevenue: { value: '', operator: '' },
  id: { value: '', operator: '' },
  brand: { value: '', operator: '' },
  name: { value: '', operator: '' },
  seller: { value: '', operator: '' },
  title: { value: '', operator: '' },
  AvgRating: { value: '', operator: '' },
  SumFeedbackCount: { value: '', operator: '' },
  Products: { value: '', operator: '' },
};
