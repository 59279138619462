import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const date = new Date();

  return (
    <div id='footer' className='footer'>
      <div
        className=''
        style={{
          fontSize: '1rem',
          fontWeight: 'bold',
          margin: '0.5rem auto',
          textAlign: 'center',
        }}
      >
        Copyright &copy; {date.getFullYear()} by MarketProfit
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        className='footer__desc'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '42%',
          }}
        >
          <Link to='/terms'>Условия использования</Link>
          <Link to='/privacy'>Политика конфеденциальности</Link>
          <Link to='/tariffs'>Информация об оплате</Link>
        </div>

        <div className='footer_contacts'>
          <a className='mailto' href='mailto:support@marketprofit.ru'>
            <i className='fa fa-envelope fa-lg m-r-5'></i>
            support@marketprofit.ru
          </a>
          <div className='' style={{ display: 'flex' }}>
            <a
              href='https://www.youtube.com/channel/UC2VhfJbvIfzxV2Iuxcx6qFA'
              rel='noopener noreferrer'
              target='_blank'
              className='youtube'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i className='fab fa-youtube fa-2x m-r-10'></i>
            </a>
            <a
              href='https://t.me/marketprofit_news'
              rel='noopener noreferrer'
              target='_blank'
              className='telegram'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i className='fab fa-telegram fa-2x m-r-10'></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
