const convertNumberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const storeSessionChart = {
  options: {
    chart: {
      type: 'area',
      width: 160,
      height: 28,
      defaultLocale: 'ru',
      locales: [
        {
          name: 'ru',
          options: {
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            shortMonths: [
              'Янв',
              'Фев',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          },
        },
      ],

      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1500,
        animateGradually: {
          enabled: true,
          delay: 1000,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 1500,
        },
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      stacked: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },

    labels: [
      'Jun 23',
      'Jun 24',
      'Jun 25',
      'Jun 26',
      'Jun 27',
      'Jun 28',
      'Jun 29',
    ],

    tooltip: {
      theme: 'dark',
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return '';
          },
        },
        formatter: (value) => {
          return convertNumberWithCommas(value);
        },
      },
      marker: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 1430,
        options: {
          chart: {
            width: 80,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 160,
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 180,
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 120,
          },
        },
      },
    ],
  },
  title: {
    text: 'Fundamental Analysis of Stocks',
    align: 'left',
  },
};

export const productListOptions = {
  options: {
    colors: [' #cb11ab'],

    chart: {
      width: 200,
      type: 'bar',
      defaultLocale: 'ru',
      locales: [
        {
          name: 'ru',
          options: {
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь',
            ],
            shortMonths: [
              'Янв',
              'Фев',
              'Мар',
              'Апр',
              'Май',
              'Июн',
              'Июл',
              'Авг',
              'Сен',
              'Окт',
              'Ноб',
              'Дек',
            ],
            days: [
              'Воскресенье',
              'Понедельник',
              'Вторник',
              'Среда',
              'Четверг',
              'Пятница',
              'Суббота',
            ],
            shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чв', 'Пт', 'Сб'],
          },
        },
      ],

      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1500,
        animateGradually: {
          enabled: true,
          delay: 1000,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 1500,
        },
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      stacked: false,
    },
    stroke: {
      curve: 'stepline',
      width: 3,
    },

    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return '';
          },
        },
        formatter: (value) => {
          return `${convertNumberWithCommas(value)} шт`;
        },
      },
      marker: {
        show: false,
      },
    },
  },
};

export const brandsCategoriesOptions = {
  options: {
    legend: {
      show: true,
      // width: 350,
      position: 'bottom',
      horizontalAlign: 'left',
      // formatter: function (seriesName, opts) {
      //   return seriesName.length >= 17
      //     ? seriesName.slice(0, 14) + '...'
      //     : seriesName;
      // },
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        opacity: 0.45,
      },
    },
    colors: [
      '#d80e5f',
      '#f59c1a',
      '#348fe2',
      '#00acac',
      '#8753de',
      '#cb11ab',
      '#177245',
      '#FAD6A5',
      '#00FF40',
      '#88540B',
      '#FFF600',
      '#0000FF',
      '#3B2F2F',
      '#915C83',
      '#C0E8D5',
      '#536878',
    ],
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            width: 700,
            height: 700,
          },
          legend: {
            width: 700,
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 650,
            height: 650,
          },
          legend: {
            width: 650,
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 500,
            height: 500,
          },
          legend: {
            width: 500,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            width: 400,
            height: 400,
          },
          legend: {
            width: 400,
          },
        },
      },

      {
        breakpoint: 1060,
        options: {
          chart: {
            width: 450,
            height: 450,
          },
          legend: {
            width: 450,
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          chart: {
            width: 450,
            height: 450,
          },
          legend: {
            width: 450,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 500,
            height: 500,
          },
          legend: {
            width: 500,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 450,
            height: 450,
          },
          legend: {
            width: 450,
          },
        },
      },
      {
        breakpoint: 440,
        options: {
          chart: {
            width: 380,
            height: 380,
          },
          legend: {
            width: 380,
          },
        },
      },
    ],
  },
};

export const historyOptions = {
  chart: {
    height: 350,
    type: 'area',
    background: '#1d2226',
    defaultLocale: 'ru',
    locales: [
      {
        name: 'ru',
        options: {
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
          shortMonths: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Ноб',
            'Дек',
          ],
          days: [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
          ],
          shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чв', 'Пт', 'Сб'],
        },
      },
    ],
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  colors: [
    '#fb5597',
    '#4a95d6',
    '#27e027',
    '#c72dc7',
    '#c74d27',
    '#35cee2',
    '#fd1b1b',
    '#e5d991',
    '#f59c1a',
    '#177245',
    '#88540B',
    '#FFF600',
    '#0000FF',
    '#3B2F2F',
    '#915C83',
    '#C0E8D5',
    '#536878',
  ],

  xaxis: {
    type: 'datetime',
    axisBorder: {
      show: true,
      color: 'rgba(182, 194, 201, 0.5)',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: -1,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#b6c2c9',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
  },

  tooltip: {
    theme: 'dark',
    x: {
      format: 'dd/MM/yy',
    },
  },
  theme: {
    mode: 'dark',
    palette: 'palette8',
    monochrome: {
      enabled: false,
      color: '#255aee',
      shadeTo: 'light',
      shadeIntensity: 0.65,
    },
  },
  responsive: [
    {
      breakpoint: 1000,
      options: {
        chart: {},
        yaxis: {
          show: false,
        },
      },
    },
  ],
};
