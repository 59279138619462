import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import InputMask from 'react-input-mask';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import marketprofit from '../../apis/marketprofit';
import { history } from '../../app';
import Loader from '../../components/loaders/Loader';
import * as authActions from '../../store/actions/auth';
import * as displayActions from '../../store/actions/display';

const UserChangePage = (props) => {
  const info = useSelector((state) => state.auth.user);
  const [isRender, setIsRender] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [addTariff, setAddTariff] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [tariff, setTariff] = useState('noTariff');
  const [period, setPeriod] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [user, setUser] = useState('');
  const [edit, setEdit] = useState(false);
  const [sweetAlertError, setSweetAlertError] = useState(false);
  const [sweetAlertConfirm, setSweetAlertConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      const id = +props.match.params.id;
      const formDate = new FormData();
      if (id) {
        formDate.append('id', id);
      }
      try {
        const response = await marketprofit.get(`/user/${id}`, formDate);
        setUser(response.data.user);
        setName(response.data.user.title);
        setPhone(response.data.user.phone);
        setRole(response.data.user.role.id);

        setTariff(
          response.data.user.tariff ? response.data.user.tariff.id : 'noTariff'
        );

        setIsRender(true);
      } catch (e) {
        error('Не удалось загрузить данные, попробуйте позже...');
      }
    };
    rendering();
  }, [props.match.params.id, edit, addTariff, sweetAlertError]);

  useEffect(() => {
    dispatch(displayActions.setPageContentFullWidth(true));
    dispatch(displayActions.setPageContentFullHeight(true));
    return () => {
      dispatch(displayActions.setPageContentFullHeight(false));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  const onUpdate = async () => {
    try {
      await dispatch(
        authActions.changeUser(
          +props.match.params.id,
          role,
          phone,
          name,
          password,
          password2
        )
      );

      await dispatch(authActions.fetchMe());
      setSweetAlertConfirm(false);

      setChangePassword(false);
      setPassword2('');
      setPassword('');
      setEdit(false);
      setIsLoading(false);
    } catch (e) {
      error('Не удалось обновить данные, попробуйте позже...');
    }
  };

  const addTariffHandler = async () => {
    try {
      await dispatch(
        authActions.setTariff(+props.match.params.id, tariff, period)
      );
      setSweetAlertConfirm(false);
    } catch (e) {
      error('Не удалось добавить тариф. Попробуйте позже...');
    }

    setPeriod('');
    setAddTariff(false);
  };

  const becomeUser = async (id) => {
    setIsLoading(true);
    try {
      const response = await marketprofit.post(`/su/${id}`);
      if (!!response.data.success) {
        history.push('/main');
        history.go(0);
      }
    } catch (e) {
      error('Не удалось стать пользователем, попробуйте позже...');
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    try {
      await dispatch(authActions.deleteUser(props.match.params.id));
      setSweetAlertError(false);
    } catch (e) {
      error('Не удалось удалить пользователя, попробуйте позже...');
    }
  };

  return (
    <>
      <ReactNotification />

      {!!isRender && (
        <div className='profile'>
          {isLoading && <Loader />}
          <div className='profile-header'>
            <div className='profile-header-cover'></div>
            <div className='profile-header-content'>
              <div className='profile-header-img'>
                <img src='/assets/img/user/user-12.jpg' alt='' />
              </div>
              <div className='profile-header-info'>
                <h4 className='m-t-10 m-b-5'>{info.title}</h4>
                <p className='m-b-10'>{info.role.title}</p>
                <label>{`Пользователь ${user.title}`}</label>
              </div>
            </div>
          </div>

          {sweetAlertError && (
            <SweetAlert
              danger
              showCancel
              confirmBtnText='Да, удалить'
              confirmBtnBsStyle='danger'
              cancelBtnBsStyle='default'
              cancelBtnText='Отмена'
              title='Вы уверены?'
              onConfirm={onDelete}
              onCancel={() => setSweetAlertError(false)}
            >
              Вы не сможете восстановить этого пользователя
            </SweetAlert>
          )}

          {sweetAlertConfirm && (
            <SweetAlert
              info
              showCancel
              confirmBtnText='Да, обновить'
              confirmBtnBsStyle='info'
              cancelBtnBsStyle='default'
              cancelBtnText='Отмена'
              title='Вы уверены?'
              onConfirm={edit ? onUpdate : addTariffHandler}
              onCancel={() => setSweetAlertConfirm(false)}
            >
              {edit
                ? `Мы изменим данные пользователя`
                : `Мы добавим дней - ${period}, по тарифу -${tariff} `}
            </SweetAlert>
          )}

          {!edit ? (
            <div className='profile-content'>
              <div className='tab-content p-0'>
                <div className='tab-pane fade show active '>
                  {!addTariff ? (
                    ///////////////////////////////////////////////////////////////////////////////////// USER
                    <div className='profile'>
                      <div className='table-responsiv form-inline'>
                        <h2>Пользователь</h2>
                        <table className='table table-profile'>
                          <thead>
                            <tr>
                              <th></th>
                              <th>
                                <h4>
                                  {name}
                                  <small>{user.role.title}</small>
                                </h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='divider'>
                              <td colSpan='2'></td>
                            </tr>

                            <tr>
                              <td className='field'>Email</td>
                              <td>
                                <i className='fa fa-envelope fa-lg m-r-5'></i>
                                {user.email}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>Мобильный номер</td>
                              <td>
                                <i className='fa fa-mobile fa-lg m-r-3'></i>
                                {user.phone}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>Последний вход</td>
                              <td>
                                <i className='fa fa-mobile fa-lg m-r-3'></i>
                                {user.last_access
                                  ? moment(user.last_access).format(
                                      'DD/MM/yyyy HH:MM'
                                    )
                                  : 'Не заходил'}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>Тариф</td>
                              <td>
                                <i className='fa fa-key fa-lg m-r-3'></i>
                                {user.tariff
                                  ? user.tariff.title
                                  : 'Не активен на данный момент'}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>
                                {user.tariff ? 'Истекает' : 'Истек'}
                              </td>
                              <td>
                                <i className='fa fa-clock fa-lg m-r-3'></i>
                                {user.tariff_expired
                                  ? moment(user.tariff_expired).format(
                                      'DD/MM/yyyy'
                                    )
                                  : 'Еще не  был активирован'}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'></td>
                              <td>
                                {user.is_disabled ? (
                                  <strong className='text-danger'>
                                    Удален
                                  </strong>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>

                            <tr className='divider'>
                              <td colSpan='2'></td>
                            </tr>
                            <tr className='highlight'>
                              <td className='field'>&nbsp;</td>
                              <td className='p-t-10 p-b-10'>
                                {!user.is_disabled && (
                                  <>
                                    {' '}
                                    <button
                                      className='btn-new btn-lime width-150'
                                      onClick={() => setEdit(true)}
                                    >
                                      <i className='fa fa-cloud-upload-alt fa-lg m-r-5'></i>
                                      Редактировать
                                    </button>
                                    <button
                                      className='btn-new btn-primary width-150 m-l-5'
                                      onClick={() => setAddTariff(true)}
                                    >
                                      <i className='fa fa-cart-plus fa-lg m-r-5'></i>
                                      Добавить тариф
                                    </button>
                                    {!user.role.is_admin && (
                                      <button
                                        className='btn-new btn-inverse width-150 m-l-5'
                                        onClick={() => {
                                          becomeUser(props.match.params.id);
                                        }}
                                      >
                                        <i className='fa fa-user-secret fa-lg m-r-5'></i>
                                        Притвориться
                                      </button>
                                    )}
                                    <button
                                      className='btn-new btn-danger width-150 m-l-5'
                                      onClick={() => {
                                        setSweetAlertError(true);
                                      }}
                                    >
                                      <i className='fa fa-trash fa-lg m-r-5'></i>
                                      Удалить
                                    </button>
                                  </>
                                )}
                                <button
                                  onClick={() => {
                                    history.push('/admin/users_list');
                                  }}
                                  className='btn-new btn-success  width-150 m-l-5'
                                >
                                  <i className='fa fa-reply fa-lg m-r-5'></i>
                                  Назад
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ///////////////////////////////////////////////////////////////////////////////////// ADD TARIFF

                    <div className='profile'>
                      <div className='table-responsive form-inline'>
                        <h2>Добавить Тариф</h2>
                        <table className='table table-profile'>
                          <thead>
                            <tr>
                              <th></th>
                              <th>
                                <h4>
                                  {name}
                                  <small>{user.role.title}</small>
                                </h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='divider'>
                              <td colSpan='2'></td>
                            </tr>

                            <tr>
                              <td className='field'>Тариф</td>
                              <td>
                                <i className='fa fa-key fa-lg m-r-3'></i>
                                {user.tariff
                                  ? user.tariff.title
                                  : 'Не активен на данный момент'}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>
                                {user.tariff ? 'Истекает' : 'Истек'}
                              </td>
                              <td>
                                <i className='fa fa-clock fa-lg m-r-3'></i>
                                {user.tariff_expired
                                  ? moment(user.tariff_expired).format(
                                      'DD/MM/yyyy'
                                    )
                                  : 'Еще не  был активирован'}
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>
                                Добавить количество дней
                              </td>
                              <td>
                                <i className='fa fa-calendar-alt fa-lg m-r-5'></i>
                                <input
                                  type='number'
                                  value={period}
                                  onChange={(e) => {
                                    setPeriod(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className='field'>Изменить Тариф</td>
                              <td>
                                <i className='fa fa-indent fa-lg m-r-5'></i>
                                <select
                                  name='tariff'
                                  onChange={(e) => {
                                    setTariff(e.target.value);
                                  }}
                                  value={tariff}
                                  required
                                >
                                  <option value='noTariff'>Нет Тарифа</option>
                                  <option value='base'>Базовый</option>
                                  <option value='advanced'>Расширенный</option>
                                  <option value='professional'>
                                    Профессиональный
                                  </option>
                                </select>
                              </td>
                            </tr>

                            <tr className='divider'>
                              <td colSpan='2'></td>
                            </tr>
                            <tr className='highlight'>
                              <td className='field'>&nbsp;</td>
                              <td className='p-t-10 p-b-10'>
                                <button
                                  className='btn-new btn-primary width-150'
                                  onClick={() => {
                                    // addTariffHandler(e);
                                    if (addTariff && period <= 0) {
                                      error(
                                        'Количество дней должно быть больше нуля'
                                      );
                                    } else if (
                                      addTariff &&
                                      tariff === 'noTariff'
                                    ) {
                                      error('Нужно выбрать тариф');
                                    } else {
                                      setSweetAlertConfirm(true);
                                    }
                                  }}
                                >
                                  <i className='fa fa-cart-plus fa-lg m-r-5'></i>
                                  Добавить
                                </button>

                                <button
                                  onClick={() => {
                                    setAddTariff(false);
                                  }}
                                  className='btn-new btn-success  width-150 m-l-5'
                                >
                                  <i className='fa fa-reply fa-lg m-r-5'></i>
                                  Назад
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ///////////////////////////////////////////////////////////////////////////////////// EDIT USER

            <div className='profile-content'>
              <div className='tab-content p-0'>
                <div className='tab-pane fade show active '>
                  <div className='profile'>
                    <div className='table-responsive form-inline'>
                      <h2>Редактирование</h2>
                      <table className='table table-profile'>
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <h4>
                                {name}
                                <small>{user.role.title}</small>
                              </h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='divider'>
                            <td colSpan='2'></td>
                          </tr>
                          <tr>
                            <td className='field'>ФИО</td>
                            <td>
                              <i className='fa fa-user fa-lg m-r-5'></i>
                              <input
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className='field'>Мобильный номер</td>
                            <td>
                              <i className='fa fa-mobile fa-lg m-r-3'></i>
                              <InputMask
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                mask='+999999999999'
                                maskChar={''}
                                placeholder=''
                              ></InputMask>
                            </td>
                          </tr>

                          <tr>
                            <td className='field'>Роль</td>
                            <td>
                              <i className='fa fa-graduation-cap fa-lg m-r-5'></i>
                              <select
                                name='role'
                                onChange={(e) => {
                                  setRole(e.target.value);
                                }}
                                value={role}
                                required
                              >
                                <option value='client'>Клиент</option>

                                <option value='admin'>Админ</option>
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td className='field'>Поменять пароль</td>
                            <td>
                              <i
                                style={{ cursor: 'pointer' }}
                                className=' fa fa-edit fa-lg m-r-5'
                                onClick={() => {
                                  setChangePassword(!changePassword);
                                  setPassword('');
                                  setPassword2('');
                                }}
                              ></i>
                            </td>
                          </tr>

                          {changePassword && (
                            <>
                              <tr>
                                <td className='field'>Новый пароль</td>
                                <td>
                                  <i className='fa fa-keyboard fa-lg m-r-5'></i>{' '}
                                  <input
                                    type='password'
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className='field'>
                                  Повторите новый пароль
                                </td>
                                <td>
                                  <i className='fa fa-keyboard fa-lg m-r-5'></i>{' '}
                                  <input
                                    type='password'
                                    value={password2}
                                    onChange={(e) => {
                                      setPassword2(e.target.value);
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className='divider'>
                            <td colSpan='2'></td>
                          </tr>
                          <tr className='highlight'>
                            <td className='field'>&nbsp;</td>
                            <td className='p-t-10 p-b-10'>
                              <button
                                onClick={() => {
                                  if (name.length === 0) {
                                    error('Введите имя');
                                  } else if (password !== password2) {
                                    error('Пароли должны совпадать');
                                  } else if (phone.length < 11) {
                                    error(
                                      'Мобильный номер должен иметь не менее 10 цифр'
                                    );
                                  } else if (
                                    changePassword &&
                                    password.length < 7
                                  ) {
                                    error('Слишком короткий пароль');
                                  }

                                  //  else if (!validator.isStrongPassword(password) ) {
                                  //   setError('Passwords lenghth must be minimum 8 letters, 1 uppercase letter, 1 symbol, 1 number ');
                                  // }
                                  else {
                                    setSweetAlertConfirm(true);
                                  }
                                }}
                                className='btn-new btn-lime width-150'
                              >
                                <i className='fa fa-cloud-upload-alt fa-lg m-r-5'></i>
                                Обновить
                              </button>

                              <button
                                onClick={() => {
                                  setPassword('');
                                  setPassword2('');
                                  setEdit(false);
                                }}
                                className='btn-new btn-success  width-150 m-l-5'
                              >
                                <i className='fa fa-reply fa-lg m-r-5'></i>
                                Назад
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserChangePage;
