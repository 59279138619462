import React, { useState } from 'react';
import classnames from 'classnames';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import StockDonutChart from '../chart/stockDonutChart.js';
import ProductStockHistoryChart from './productStockHistoryChart.js';

const ProductOptions = ({ options, optionHistory, days }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Panel>
        <PanelHeader>{`Продажи по размерам за количество дней - ${days}`}</PanelHeader>
        <PanelBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                <span className="d-sm-none">Сводка</span>
                <span className="d-sm-block d-none">Сводка</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                <span className="d-sm-none">История продаж</span>
                <span className="d-sm-block d-none">История продаж</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === '1' && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <StockDonutChart
                    title={'Общее количество продаж по размерам'}
                    data={options ? options : []}
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === '2' && (
                <ProductStockHistoryChart
                  days={days}
                  history={optionHistory}
                  options={true}
                />
              )}
            </TabPane>
          </TabContent>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(ProductOptions);
