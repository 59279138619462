import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import { userColumns } from '../../components/tables/userColumns';
import Table from '../../components/tables/Table';
import * as authActions from '../../store/actions/auth';
import * as displayActions from '../../store/actions/display';

const AdminPage = (props) => {
  const [tabUsersList, setTabUsersList] = useState(false);
  const [tabBillsList, setTabBillsList] = useState(false);
  const [activeBill, setActiveBill] = useState('');
  const info = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offSet, setOffset] = useState(0);
  const [order_by, setOrder_by] = useState('id');
  const [dir, setDir] = useState('desc');
  const [sweetAlertError, setSweetAlertError] = useState(false);
  const [filterUsers, setFilterUsers] = useState({
    email: '',
    phone: '',
    role_id: '',
    tariff_id: '',
  });
  const [filterBills, setFilterBills] = useState({
    status: 'payd',
    tariff_id: '',
    user_id: '',
    date_from: '',
    date_to: '',
  });
  const data = useSelector((state) => state.auth.userList);
  const count = useSelector((state) => state.auth.userCounts);
  const billsList = useSelector((state) => state.auth.billsList);
  const billsCount = useSelector((state) => state.auth.billsCount);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageContentFullWidth(true));
    dispatch(displayActions.setPageContentFullHeight(true));
    dispatch(displayActions.setPageFooter(false));

    return () => {
      dispatch(displayActions.setPageContentFullHeight(false));
      dispatch(displayActions.setPageContentFullWidth(false));
      dispatch(displayActions.setPageFooter(true));
    };
  }, [dispatch]);

  useEffect(() => {
    props.location.pathname.split('/')[2] === 'users_list'
      ? showTab('usersList')
      : showTab('billsList');
  }, [props.location.pathname]);

  useEffect(() => {
    const render = async () => {
      setIsLoading(true);

      if (tabUsersList) {
        try {
          dispatch(
            authActions.fetchUsers(limit, offSet, order_by, dir, filterUsers)
          );
        } catch (e) {
          error('Не удалось загрузить пользователей, попробуйте позже...');
        }
      } else if (tabBillsList) {
        try {
          dispatch(authActions.fetchBills(limit, offSet, filterBills));
        } catch (e) {
          error('Не удалось загрузить список счетов, попробуйте позже...');
        }
      }

      setIsLoading(false);
    };
    render();
  }, [
    dispatch,
    limit,
    offSet,
    order_by,
    dir,
    filterUsers,
    filterBills,
    tabUsersList,
    tabBillsList,
  ]);

  const showTab = async (tab) => {
    setTabUsersList(tab === 'usersList' ? true : false);
    setTabBillsList(tab === 'billsList' ? true : false);
  };

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };
  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const deleteHandler = async () => {
    try {
      await dispatch(authActions.deleteBill(activeBill));
      await dispatch(authActions.fetchBills(limit, offSet, filterBills));
    } catch (e) {
      error('Не удалось удалить счет, попробуйте позже...');
    }
    setSweetAlertError(false);
  };

  const filterUser = (id, filter) => {
    id === 'email' && setFilterUsers((state) => ({ ...state, email: filter }));
    id === 'phone' && setFilterUsers((state) => ({ ...state, phone: filter }));
    id === 'role' && setFilterUsers((state) => ({ ...state, role_id: filter }));
    id === 'tariff' &&
      setFilterUsers((state) => ({ ...state, tariff_id: filter }));
  };

  const filterUsersHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterUser(fil.id, fil.value);
    });
  };

  const filterBillsHandler = (allFilter) => {
    allFilter.map((fil) => {
      return filterBill(fil.id, fil.value);
    });
  };

  const filterBill = (id, filter) => {
    id === 'user_id' &&
      setFilterBills((state) => ({ ...state, user_id: filter }));
    id === 'status' &&
      setFilterBills((state) => ({ ...state, status: filter }));
    id === 'tariff' &&
      setFilterBills((state) => ({ ...state, tariff_id: filter }));
    id === 'ctime' &&
      setFilterBills((state) => ({
        ...state,
        date_from: filter.date_from,
        date_to: filter.date_to,
      }));
  };

  const billsColumn = [
    {
      Header: 'Информация',

      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          tipText: 'ID счета',
          disableFilters: true,
          width: 70,
          disableSortBy: true,
        },
        {
          Header: 'Клиент',
          accessor: 'user_id',
          tipText: 'Клиент',

          width: 70,
          Cell: ({ row }) => {
            return (
              <Link to={`/user/${row.values.user_id}`}>
                {row.values.user_id}
              </Link>
            );
          },
          disableSortBy: true,
        },
        {
          Header: 'Тариф',
          accessor: 'tariff',
          tipText: 'Название тарифа',

          disableSortBy: true,
        },
        {
          Header: 'Код',
          accessor: 'code',
          tipText: 'Скидочный код',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Срок',
          accessor: 'period',
          tipText: 'Срок подключения тарифа',

          width: 70,
          Cell: ({ row }) => {
            return `${row.values.period} мес.`;
          },
          disableSortBy: true,
          disableFilters: true,
        },

        {
          Header: 'Статус',
          accessor: 'status',
          tipText: 'Статус платежа',

          disableSortBy: true,
        },
        {
          Header: 'Стоимость',
          accessor: 'amount',
          tipText: 'Стоимость услуги',

          Cell: ({ row }) => {
            return `${numeral(row.values.amount).format(`0,000,000`)} ₽`;
          },
          disableSortBy: true,
          disableFilters: true,
        },

        {
          Header: 'Дата создания',
          accessor: 'ctime',
          tipText: 'Дата создания  платежа',
          // sortable: true,
          disableSortBy: true,
        },

        {
          Header: 'Управление',
          accessor: 'options',
          tipText: 'Управление счетом',
          width: 50,
          disableSortBy: true,
          disableFilters: true,

          Cell: ({ row }) => {
            return row.values.status === 'Оплачено' ? (
              <div></div>
            ) : (
              <div
                className="btn btn-danger m-r-5 table__edit"
                onClick={() => {
                  setActiveBill(row.values.id);
                  setSweetAlertError(true);
                }}
              >
                <i
                  className={`fas  fa-trash fa-fw
                  `}
                ></i>
              </div>
            );
          },
        },
      ],
    },
  ];

  return (
    <div>
      {isLoading && <Loader />}
      <ReactNotification />

      {sweetAlertError && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Да, удалить"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          cancelBtnText="Отмена"
          title="Вы уверены?"
          onConfirm={deleteHandler}
          onCancel={() => setSweetAlertError(false)}
        >
          Мы удалим этот счет
        </SweetAlert>
      )}
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover admin"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img src="/assets/img/user/user-12.jpg" alt="" />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{info.title}</h4>
              <p className="m-b-10">{info.role.title}</p>
              <Link to="/user">
                <button className="btn btn-xs btn-yellow">
                  Изменить профиль
                </button>
              </Link>
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/admin/users_list"
                // onClick={(e) => showTab(e, 'usersList')}
                className={'nav-link ' + (tabUsersList ? 'active ' : '')}
              >
                ПОЛЬЗОВАТЕЛИ
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/bills_list"
                // onClick={(e) => showTab(e, 'billsList')}
                className={'nav-link ' + (tabBillsList ? 'active ' : '')}
              >
                СЧЕТА
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          {/* ///////////////////////////////////////////////////////////////////////////////////////////// UserList */}

          <div
            className={'tab-pane fade ' + (tabUsersList ? 'show active ' : '')}
          >
            <Link to="/user/create" className="btn btn-green m-b-5">
              <i className="fa fa-user-plus fa-lg m-r-5"> </i>
              Добавить пользователя
            </Link>
            <div className="row row-space-2"></div>

            {tabUsersList && (
              <Table
                filterable
                count={count}
                title={`Список пользователей (${count})`}
                limitHandler={limitHandler}
                offsetHandler={offsetHandler}
                onOffset={onOffset}
                cleanOffset={cleanOffset}
                lastPage={lastPage}
                sortHandler={sortHandler}
                pageSize={20}
                filterHandler={filterUsersHandler}
                filterSelect={filterUser}
                sortBy={[{ id: 'id', desc: true }]}
                data={isLoading ? [] : data}
                columns={userColumns}
                download={true}
              />
            )}
          </div>

          <div
            className={'tab-pane fade ' + (tabBillsList ? 'show active ' : '')}
          >
            {tabBillsList && (
              <Table
                filterable
                count={billsCount}
                title={`Cписок счетов пользователей (${billsCount})`}
                limitHandler={limitHandler}
                offsetHandler={offsetHandler}
                onOffset={onOffset}
                cleanOffset={cleanOffset}
                lastPage={lastPage}
                // sortHandler={sortHandler}
                pageSize={20}
                filterHandler={filterBillsHandler}
                filterSelect={filterBill}
                sortBy={[{ id: 'id', desc: true }]}
                data={isLoading ? [] : billsList}
                columns={billsColumn}
                download={true}
                billsPaid
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
