import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { history } from '../../app';
import { logout } from '../../store/actions/auth';
import marketprofit from '../../apis/marketprofit';

const SidebarProfile = () => {
  const [profileActive, setProfileActive] = useState(false);
  const name = useSelector((state) => state.auth.user.title);
  const role = useSelector((state) => state.auth.user.role.title);
  const isAdmin = useSelector((state) => state.auth.user.role.is_admin);
  const tariff = useSelector((state) => state.auth.user.tariff.title);
  const tariffExpired = useSelector((state) => state.auth.user.tariff_expired);
  const isSu = useSelector((state) => state.auth.is_su);

  const dispatch = useDispatch();

  const handleProfileExpand = (e) => {
    e.preventDefault();
    setProfileActive(!profileActive);
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const becomeBack = async () => {
    try {
      const response = await marketprofit.post('/su_exit');
      if (!!response.data.success) {
        history.go(0);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  return (
    <ul className='nav'>
      <li className={'nav-profile ' + (profileActive ? 'expand ' : '')}>
        <Link to='/' onClick={handleProfileExpand}>
          <div className='cover with-shadow'></div>
          <div className='image'>
            <img src='/assets/img/user/user-12.jpg' alt='' />
          </div>
          <div className='info'>
            <b className='caret pull-right'></b>
            {name}
            <small>{role}</small>
            <small>{tariff}</small>
            {tariff !== 'Доступ не оплачен' && (
              <small>
                {`Истекает ${moment(tariffExpired).format('DD/MM/YYYY')}`}
              </small>
            )}
          </div>
        </Link>
      </li>
      <li>
        <ul className={'nav nav-profile ' + (profileActive ? 'd-block ' : '')}>
          {isAdmin ? (
            <li>
              <Link to='/admin/users_list'>
                <i className='fa fa-laptop'></i> Админ
              </Link>
            </li>
          ) : null}

          <li>
            <Link to='/user/bills'>
              <i className='fa fa-archive'></i> Тариф и Счета
            </Link>
          </li>
          <li>
            <Link to='/user'>
              <i className='fa fa-cog'></i> Настройки
            </Link>
          </li>

          {isSu ? (
            <li
              onClick={becomeBack}
              style={{
                padding: '7px 20px',
                lineHeight: '20px',
                color: '#fff',
                background: '#242b30',
                display: 'block',
                cursor: 'pointer',
              }}
            >
              <i className='fa fa-user-secret fa-lg m-r-5'></i>Вернуться назад
            </li>
          ) : null}

          <li>
            <Link to='/' onClick={onLogout}>
              <i className='fa fa-sign-out-alt'></i> Выход
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default React.memo(SidebarProfile);
