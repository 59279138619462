import numeral from 'numeral';
import moment from 'moment';

export const trendsColumnsCategories = [
  {
    Header: 'Информация',

    columns: [
      {
        Header: 'Неделя',
        accessor: 'startWeek',
        tipText: 'Неделя',
        sortable: true,
        width: 75,

        Cell: ({ row }) => {
          return moment(row.values.startWeek).format(`DD/MM/yyyy`);
        },
      },

      {
        Header: 'Продаж',
        accessor: 'SumSellQty',
        tipText: 'Общее количество продаж',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Выручка',
        accessor: 'SumRevenue',
        tipText: 'Общая сумма выручки',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Общее количество видов товаров',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,000,000`)} ед.`;
        },
      },
      {
        Header: 'Брендов',
        accessor: 'Brands',
        tipText: 'Общее количество брендов',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Brands).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Выручка на товар',
        accessor: 'AvgRevenue',
        tipText: 'Средняя выручка за товар',
        sortable: true,
        width: 75,

        Cell: ({ row }) => {
          return `${numeral(row.values.AvgRevenue).format(`0,000,000`)} ₽/шт.`;
        },
      },
    ],
  },
];

export const trendsColumnsBrands = [
  {
    Header: 'Информация',

    columns: [
      {
        Header: 'Неделя',
        accessor: 'startWeek',
        tipText: 'Неделя',
        sortable: true,
        width: 75,

        Cell: ({ row }) => {
          return moment(row.values.startWeek).format(`DD/MM/yyyy`);
        },
      },

      {
        Header: 'Продаж',
        accessor: 'SumSellQty',
        tipText: 'Общее количество продаж',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumSellQty).format(`0,000,000`)} шт.`;
        },
      },
      {
        Header: 'Выручка',
        accessor: 'SumRevenue',
        tipText: 'Общая сумма выручки',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.SumRevenue).format(`0,000,000`)} ₽`;
        },
      },
      {
        Header: 'Товаров',
        accessor: 'Products',
        tipText: 'Общее количество товаров',
        sortable: true,
        width: 75,
        Cell: ({ row }) => {
          return `${numeral(row.values.Products).format(`0,000,000`)} шт.`;
        },
      },

      {
        Header: 'Выручка на товар',
        accessor: 'AvgRevenue',
        tipText: 'Средняя выручка за товар',
        sortable: true,
        width: 75,

        Cell: ({ row }) => {
          return `${numeral(row.values.AvgRevenue).format(`0,000,000`)} ₽/шт.`;
        },
      },
    ],
  },
];
