class StocksProductHistory {
  constructor(name, createdAt, qty) {
    this.name = name;
    this.createdAt = createdAt;
    this.qty = qty;
  }
}

export default StocksProductHistory;

export const newStocksProductHistory = (stock) => {
  return stock.history.map((st) => {
    return new StocksProductHistory(
      stock.name,
      st.createdAt,
      st.qty === null ? 0 : st.qty
    );
  });
};
