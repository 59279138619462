class OptionsProductHistory {
  constructor(name, createdAt, qty, sellQty) {
    this.name = name;
    this.createdAt = createdAt;
    this.qty = qty;
    this.sellQty = sellQty;
  }
}

export default OptionsProductHistory;

export const newOptionsProductHistory = (option) => {
  return option.history.map((op) => {
    return new OptionsProductHistory(
      option.name,
      op.createdAt,
      op.qty === null ? 0 : op.qty,
      op.sellQty === null ? 0 : op.sellQty
    );
  });
};
