import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// import CategoriesList from '../../components/wbCatergories/categoriesList';
// import CategoriesListTwo from '../../components/wbCatergories/categoriesList2';
// import Calendar from '../../components/calendar/calendar';
import error from '../../components/error/errorNotification';
import {
  filterCategories,
  filtersState,
} from '../../components/filters/filterHandlers';
import moment from 'moment';
import Table from '../../components/tables/Table';
import Loader from '../../components/loaders/Loader';
import * as wbActions from '../../store/actions/wb';
import { categoriesColumn } from '../../components/tables/categoriesColumn';
import { saveAll } from '../../components/tables/saveAll';

const CatergoriesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const [filter, setFilter] = useState(filtersState);
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;

  const mainCategoriesCount = useSelector(
    (state) => state.wb.counts.mainCategoriesCount
  );
  const categoriesList = useSelector((state) => state.wb.categoriesList);
  const dispatch = useDispatch();

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        await dispatch(wbActions.getCategories(date_from, date_to, filter));
      } catch (e) {
        error('Что-то пошло не так... Попробуйте еще позже');
      }

      setIsLoading(false);
    };
    rendering();
  }, [dispatch, date_from, date_to, filter]);

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const filterCategoriesHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterCategories(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active"> Категории</li>
      </ol>
      <h1 className="page-header">
        Категории <small>{/*header small text goes here...*/}</small>
      </h1>
      {/* <Calendar dateHandler={dateHandler} /> */}

      {/* {isRender && <CategoriesList categories={categoriesList} />} */}
      {/* {isRender && <CategoriesListTwo categories={categoriesList} />} */}
      <Table
        filterable
        pageSize={20}
        dateHandler={dateHandler}
        title={
          isLoading
            ? ''
            : `Категорий - ${
                mainCategoriesCount ? mainCategoriesCount : 0
              }, за количество дней - ${days}`
        }
        data={isLoading ? [] : categoriesList}
        columns={categoriesColumn}
        filterHandler={filterCategoriesHandler}
        saveAll={() =>
          saveAll(
            '/wb/categories',
            'SumRevenue',
            'desc',
            date_from,
            date_to,
            filter,
            `Отчет по категориям за ${days} дней`
          )
        }
      />
    </div>
  );
};
export default CatergoriesPage;
