import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import * as displayActions from '../../store/actions/display';

const NotFoundPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageSidebar(false));
    dispatch(displayActions.setPageHeader(false));
    dispatch(displayActions.setPageFooter(false));
    dispatch(displayActions.setPageContentFullWidth(true));
    return () => {
      dispatch(displayActions.setPageSidebar(true));
      dispatch(displayActions.setPageHeader(true));
      dispatch(displayActions.setPageFooter(true));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  return (
    <div className='error'>
      <div className='error-code m-b-10'>404</div>
      <div className='error-content'>
        <div className='error-message'>Мы не можем это найти...</div>
        <div className='error-desc m-b-30'>
          Страница, которую вы ищете, не существует . <br />
        </div>
        <div>
          <Link to='/' className='btn btn-success p-l-20 p-r-20'>
            На Главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
