import numeral from 'numeral';

class SubcategoryHistory {
  constructor(
    createdAt,
    SumFeedbackCount,
    AvgRating,
    Products,
    AvgPrice,
    AvgSellPrice,
    SumSellQty,
    SumRevenue
  ) {
    this.createdAt = createdAt;
    this.SumFeedbackCount = SumFeedbackCount;
    this.AvgRating = AvgRating;
    this.Products = Products;
    this.AvgPrice = AvgPrice;
    this.AvgSellPrice = AvgSellPrice;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
  }
}

export default SubcategoryHistory;

export const newSubcategoryHistory = (prod) => {
  return new SubcategoryHistory(
    prod.createdAt,
    prod.SumFeedbackCount === null ? 0 : prod.SumFeedbackCount,
    numeral(prod.AvgRating).format(`0.00`),
    `${numeral(prod.Products).format(`0,000,000`)}`,
    `${numeral(prod.AvgPrice).format(`0,000,000`)}`,
    `${numeral(prod.AvgSellPrice).format(`0,000,000`)}`,
    prod.SumSellQty === null ? 0 : prod.SumSellQty,
    prod.SumRevenue === null ? 0 : prod.SumRevenue
  );
};
