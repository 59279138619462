const Menu = [
  {
    path: '/main',
    icon: 'fas fa-home',
    title: 'Главная',
  },
  {
    path: '/tariffs',
    icon: 'fas fa-credit-card',
    title: 'Тарифы',
  },
  {
    path: '/wildberries/search',
    icon: 'fa fa-search',
    title: 'Поиск',
    label: 'Новое',
  },
  {
    path: '/wildberries/new',
    icon: 'fab fa-neos',
    title: 'Новинки',
    label: 'Новое',
  },
  { path: '/wildberries/brands', icon: 'fab fa-black-tie', title: 'Бренды' },
  {
    path: '/wildberries/categories',
    icon: 'fas fa-list-alt',
    title: 'Категории',
  },
  { path: '/wildberries/sellers', icon: 'fas fa-users', title: 'Продавцы' },
  { path: '/wildberries/stocks', icon: 'fas fa-cubes', title: 'Склады' },
  {
    path: '/wildberries/favorites',
    icon: 'fas fa-heart',
    title: 'Избранные Товары',
  },
  {
    path: '/help',
    icon: 'fa fa-question-circle',
    title: 'Поддержка',
    children: [
      { path: 'https://wa.me/79208745602', title: 'WhatsApp', help: true },
      { path: 'https://t.me/Marketprofit_WB', title: 'Telegram', help: true },
      // { path: '/wildberries/sellers', title: 'Продавцы' },
      // { path: '/wildberries/stocks', title: 'Склады' },
      // { path: '/wildberries/favorites', title: 'Избранные Товары' },
    ],
  },
];

export default Menu;
