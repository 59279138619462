class OptionProductTotal {
  constructor(name, qty) {
    this.name = name;
    this.qty = qty;
  }
}

export default OptionProductTotal;

export const newOptionProductTotal = (option) => {
  return new OptionProductTotal(
    option.name,

    option.summary.SumSellQty === null ? 0 : +option.summary.SumSellQty
  );
};
