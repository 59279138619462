import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// import Calendar from '../../components/calendar/calendar';
import { brandsColumns } from '../../components/tables/brandsColumns';
import { categoriesColumn } from '../../components/tables/categoriesColumn';
import DonutChart from '../../components/chart/donutChart';
import error, { success } from '../../components/error/errorNotification';
import {
  filterBrands,
  filterCategories,
  filterProducts,
  filterSellers,
  filtersState,
} from '../../components/filters/filterHandlers';
import Loader from '../../components/loaders/Loader';
import NavigationPage from '../../components/navigation/navigationPage';
import HistoryChart from '../../components/chart/historyChart';
import { history } from '../../app';
import { productColumns } from '../../components/tables/productColumns';
import Table from '../../components/tables/Table';
import TrandsChart from '../../components/chart/trandsChart';
import { trendsColumnsCategories } from '../../components/tables/trendsColumns';
import { subcategoriesHistoryColumns } from '../../components/tables/subcategoriesHistoryColumns';
import { sellersListColumns } from '../../components/tables/sellersListColumns';
import WarningLabel from '../../components/error/warningLabel';
import * as wbActions from '../../store/actions/wb';
import { saveAll } from '../../components/tables/saveAll';

const ProductsPage = (props) => {
  const id = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offSet, setOffset] = useState(0);
  const [order_by, setOrder_by] = useState('SumRevenue');
  const [dir, setDir] = useState('desc');
  const [activePill, setActivePill] = useState('');
  const [filter, setFilter] = useState(filtersState);
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const permissions = useSelector(
    (state) => state.auth.user.tariff.permissions
  );
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const productsList = useSelector((state) => state.wb.productsList);
  const subcategoryHistory = useSelector(
    (state) => state.wb.subcategoriesHistory
  );
  const subcategoriesBrandsList = useSelector(
    (state) => state.wb.subcategoriesBrandsList
  );
  const count = useSelector((state) => state.wb.counts.productsCount);
  const subcategoriesCount = useSelector(
    (state) => state.wb.counts.subcategoriesCount
  );
  const subcategoriesSellers = useSelector(
    (state) => state.wb.subcategoriesSellers
  );
  const categoryTitles = useSelector((state) => state.wb.categoryTitles);
  const subCategories = useSelector((state) => state.wb.subcategoriesList);
  const trends = useSelector((state) => state.wb.trends);
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;
  const dispatch = useDispatch();
  const titles = [
    'Товары',
    'Бренды',
    'Продавцы',
    'По дням',
    'Тренд',
    'Подкатегории',
  ];
  const links = [
    `/wildberries/categories/products/${id}`,
    `/wildberries/categories/brands/${id}`,
    `/wildberries/categories/sellers/${id}`,
    `/wildberries/categories/history/${id}`,
    `/wildberries/categories/trends/${id}`,
    `/wildberries/categories/subcategories/${id}`,
  ];

  useEffect(() => {
    const rendering = async () => {
      if (categoryTitles.id === id) {
        setShowTitle(true);

        return;
      }
      try {
        await dispatch(wbActions.getCategoryInfo(id));
      } catch (e) {
        error('Не удалось загрузить названия');
      }
      setShowTitle(true);
    };

    rendering();
  }, [dispatch, categoryTitles, id]);

  useEffect(() => {
    props.location.pathname.split('/')[3] === 'products' && setActivePill('1');
    props.location.pathname.split('/')[3] === 'subcategories' &&
      setActivePill('6');
    props.location.pathname.split('/')[3] === 'brands' && setActivePill('2');
    props.location.pathname.split('/')[3] === 'sellers' && setActivePill('3');
    props.location.pathname.split('/')[3] === 'trends' && setActivePill('5');
    props.location.pathname.split('/')[3] === 'history' && setActivePill('4');
  }, [props.location.pathname]);

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);

      try {
        switch (activePill) {
          case '1':
            await dispatch(
              wbActions.getProducts(
                id,
                limit,
                offSet,
                order_by,
                dir,
                date_from,
                date_to,
                filter
              )
            );

            setIsLoading(false);

            return;

          case '2':
            permissions.includes('CategoryBrands') &&
              (await dispatch(
                wbActions.getSubcategoriesBrands(id, date_from, date_to, filter)
              ));
            setIsLoading(false);

            return;

          case '3':
            permissions.includes('CategorySellers') &&
              (await dispatch(
                wbActions.getSubcategoriesSellers(
                  id,
                  date_from,
                  date_to,
                  filter
                )
              ));
            setIsLoading(false);

            return;

          case '4':
            await dispatch(
              wbActions.getSubcategoriesHistory(id, date_from, date_to)
            );
            setIsLoading(false);

            return;

          case '5':
            permissions.includes('CategoryTrends') &&
              (await dispatch(wbActions.getSubcategoriesTrends(id)));
            setIsLoading(false);

            return;

          case '6':
            await dispatch(
              wbActions.getSubcategories(id, date_from, date_to, filter)
            );
            setIsLoading(false);

            return;

          default:
            return;
        }
      } catch (e) {
        error('Не удалось загрузить данные... Попробуйте позже');
      }
    };
    rendering();
  }, [
    dispatch,
    activePill,
    id,
    date_from,
    date_to,
    limit,
    offSet,
    order_by,
    dir,
    permissions,
    filter,
  ]);

  useEffect(() => {
    if (
      props.location.pathname.split('/')[3] === 'subcategories' &&
      showTitle
    ) {
      return !categoryTitles.childs
        ? history.push(`/wildberries/categories/products/${id}`)
        : null;
    }
  }, [categoryTitles, showTitle, id, props.location.pathname]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const togglePill = (pill) => {
    if (activePill !== pill) {
      setActivePill(pill);
    }
  };

  const saveFavoritesHandler = async (ids) => {
    setIsLoading2(true);
    try {
      await dispatch(wbActions.addFavorites(ids));
      success('Успешно сохранено');
    } catch (e) {
      error('Не удалось сохранить, попробуйте еще раз позже...');
    }
    setIsLoading2(false);
  };

  const filterProductsHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterProducts(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  const filterCategoriesHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterCategories(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  const filterBrandsHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterBrands(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  const filterSellersHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterSellers(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  return (
    <div className="">
      {isLoading && <Loader />}

      <ReactNotification />
      {showTitle && (
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">Главная</Link>
          </li>
          {categoryTitles.parentId && categoryTitles.parent.parentId && (
            <li className="breadcrumb-item active">
              <Link
                to={{
                  pathname: `/wildberries/categories/products/${categoryTitles.parent.parentId}`,
                }}
              >{`${categoryTitles.parent.parent.title}`}</Link>
            </li>
          )}
          {categoryTitles.parentId && (
            <li className="breadcrumb-item active">
              <Link
                to={{
                  pathname: `/wildberries/categories/products/${categoryTitles.parentId}`,
                }}
              >{`${categoryTitles.parent.title}`}</Link>
            </li>
          )}
          <li className="breadcrumb-item active">
            {`${categoryTitles.title}`}
          </li>
        </ol>
      )}
      <h1 className="page-header">
        {showTitle && `${categoryTitles.title}`}
        {/* <small>
          {!isLoading &&
          
            ` Найдено ${count} товаров`}
        </small> */}
      </h1>

      {showTitle && (
        <div id="navigation">
          <NavigationPage
            togglePill={togglePill}
            activePill={activePill}
            isLoading={isLoading}
            titles={titles}
            subcategories={categoryTitles.childs ? true : false}
            links={links}
          />
          {/* {activePill === '5' ? null : <Calendar dateHandler={dateHandler} />} */}
        </div>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// PRODUCTS */}

      {activePill === '1' && (
        <Table
          filterable
          products
          saveFavoritesHandler={saveFavoritesHandler}
          isLoading={isLoading2}
          count={count}
          limitHandler={limitHandler}
          offsetHandler={offsetHandler}
          onOffset={onOffset}
          cleanOffset={cleanOffset}
          lastPage={lastPage}
          sortHandler={sortHandler}
          pageSize={20}
          dateHandler={dateHandler}
          title={
            isLoading
              ? ''
              : `Количество товаров - ${count}, за количество дней - ${days}`
          }
          data={productsList}
          columns={productColumns}
          filterHandler={filterProductsHandler}
          saveAll={() =>
            saveAll(
              `/wb/category/${id}/products`,

              order_by,
              dir,
              date_from,
              date_to,
              filter,
              `Отчет по товарам категории ${categoryTitles.title} за ${days} дней`
            )
          }
        />
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// BRANDS */}

      {activePill === '2' &&
        (permissions.includes('CategoryBrands') ? (
          <>
            {activePill === '2' && (
              <DonutChart data={isLoading ? [] : subcategoriesBrandsList} />
            )}
            <Table
              filterable
              pageSize={20}
              dateHandler={dateHandler}
              filterHandler={filterBrandsHandler}
              title={
                isLoading
                  ? ''
                  : `Топ-100 брендов данной категории  за количество дней - ${days}`
              }
              data={isLoading ? [] : subcategoriesBrandsList}
              columns={brandsColumns}
              saveAll={() =>
                saveAll(
                  `/wb/category/${id}/brands/`,

                  order_by,
                  dir,
                  date_from,
                  date_to,
                  filter,
                  `Отчет по брендам категории ${categoryTitles.title} за ${days} дней`
                )
              }
            />
          </>
        ) : (
          <WarningLabel
            header={'Ваш тариф не имеет возможности смотреть бренды категорий.'}
            title={` Данную функцию открывают тарифы
          "Расширенный" и выше.`}
          />
        ))}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// SELLERS */}

      {activePill === '3' &&
        (permissions.includes('CategorySellers') ? (
          <div className="pill__brands">
            {activePill === '3' && (
              <DonutChart data={isLoading ? [] : subcategoriesSellers} />
            )}

            <Table
              filterable
              pageSize={20}
              dateHandler={dateHandler}
              filterHandler={filterSellersHandler}
              title={
                isLoading
                  ? ''
                  : `Топ-100 продавцов бренда  за количество дней - ${days}`
              }
              data={isLoading ? [] : subcategoriesSellers}
              columns={sellersListColumns}
              saveAll={() =>
                saveAll(
                  `/wb/category/${id}/sellers`,

                  order_by,
                  dir,
                  date_from,
                  date_to,
                  filter,
                  `Отчет по продавцам категории ${categoryTitles.title} за ${days} дней`
                )
              }
            />
          </div>
        ) : (
          <WarningLabel
            header={
              'Ваш тариф не имеет возможности смотреть продавцов категорий.'
            }
            title={` Данную функцию открывает тариф
        "Профессиональный" `}
          />
        ))}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// HISTORY */}

      {activePill === '4' && (
        <div className="pill__history">
          {activePill === '4' && (
            <HistoryChart
              history={isLoading ? [] : subcategoryHistory}
              days={days}
            />
          )}

          <Table
            pageSize={40}
            dateHandler={dateHandler}
            title={
              isLoading ? '' : `История категории за количество дней - ${days}`
            }
            sortBy={[{ id: 'createdAt', desc: true }]}
            data={isLoading ? [] : subcategoryHistory}
            columns={subcategoriesHistoryColumns}
            footer={true}
            saveAll={() =>
              saveAll(
                `/wb/category/${id}`,

                order_by,
                dir,
                date_from,
                date_to,
                filter,
                `Отчет по истории категории ${categoryTitles.title}  за ${days} дней`
              )
            }
          />
        </div>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// TRENDS */}

      {activePill === '5' &&
        (permissions.includes('CategoryTrends') ? (
          <div className="pill__trends">
            {activePill === '5' && (
              <TrandsChart days={days} history={isLoading ? [] : trends} />
            )}
            <Table
              pageSize={40}
              title={isLoading ? '' : `Тренд по неделям`}
              sortBy={[{ id: 'startWeek', desc: true }]}
              data={isLoading ? [] : trends}
              columns={trendsColumnsCategories}
              saveAll={() =>
                saveAll(
                  `/wb/category/${id}/trends`,

                  order_by,
                  dir,
                  date_from,
                  date_to,
                  filter,
                  `Отчет по тренду категории ${categoryTitles.title} `
                )
              }
            />
          </div>
        ) : (
          <WarningLabel
            header={'Ваш тариф не имеет возможности смотреть тренды категорий.'}
            title={` Данную функцию открывает тариф
        "Профессиональный" `}
          />
        ))}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// SUBCATEGORIES */}

      {activePill === '6' && (
        <div className="pill__subcategories">
          {activePill === '6' && (
            <DonutChart data={isLoading ? [] : subCategories} />
          )}
          <Table
            filterable
            pageSize={20}
            dateHandler={dateHandler}
            title={
              isLoading
                ? ''
                : `Подкатегорий - ${
                    subcategoriesCount ? subcategoriesCount : '0'
                  }, за количество дней - ${days}`
            }
            data={isLoading ? [] : subCategories}
            columns={categoriesColumn}
            filterHandler={filterCategoriesHandler}
            saveAll={() =>
              saveAll(
                `/wb/category/${id}/categories`,

                order_by,
                dir,
                date_from,
                date_to,
                filter,
                `Отчет по подкатегориям  ${categoryTitles.title}  за ${days} дней`
              )
            }
          />
        </div>
      )}
    </div>
  );
};
export default ProductsPage;
