import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel';

const NotAvailablePage = (props) => {
  const tariff = useSelector((state) => state.auth.user.tariff.id);
  return (
    <div>
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/'>Главная</Link>
        </li>
        <li className='breadcrumb-item active'>Нет доступа</li>
      </ol>
      <h1 className='page-header'>
        Нет доступа <small>У вас нету доступа к данной странице</small>
      </h1>

      <Panel>
        <PanelHeader>MarketProfit</PanelHeader>
        <PanelBody>
          {!tariff
            ? `Эта функция недоступна без определенного пакета. Для получения доступа
          к ней необходима его активация. Выберите, какой тариф необходим Вам, и
          следуйте инструкциям по оплате.`
            : props.location.state
            ? props.location.state.message
            : `Данная функция недоступна для вашего тарифа. Но вы можете его расширить, подробнее изучив возможности каждого из них.`}
        </PanelBody>
      </Panel>

      <Link to='/tariffs'>
        <button className='btn btn__tariffs'>Страница Тарифов</button>
      </Link>
    </div>
  );
};

export default NotAvailablePage;
