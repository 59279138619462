import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from '../../components/loaders/Loader';
import { productColumns } from '../../components/tables/productColumns';
import Table from '../../components/tables/Table';
import error, { success } from '../../components/error/errorNotification';
import ReactNotification from 'react-notifications-component';
import { addFavorites, fetchNew } from '../../store/actions/wb';
import { saveAll } from '../../components/tables/saveAll';

const NewPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [offSet, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [order_by, setOrder_by] = useState('SumSellQty');
  const [dir, setDir] = useState('desc');
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const count = useSelector((state) => state.wb.counts.newCount);
  const newProducts = useSelector((state) => state.wb.newProducts);
  const [date, setDate] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const [date_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to] = useState(moment().subtract(1, 'd').format('yyyy-MM-DD'));
  // const days = moment(date_to).diff(moment(date_from), 'days') + 1;

  const dispatch = useDispatch();

  useEffect(() => {
    const loadNew = async () => {
      setIsLoading(true);

      try {
        await dispatch(
          fetchNew(
            limit,
            offSet,
            order_by,
            dir,
            date_from,
            date_to,
            date,
            date === moment().subtract(1, 'd').format('yyyy-MM-DD')
              ? '=='
              : '>='
          )
        );
      } catch (e) {
        error('Не удалось загрузить новинки, попробуйте позже');
      }
      setIsLoading(false);
    };

    loadNew();
  }, [dispatch, limit, offSet, order_by, dir, date_from, date_to, date]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  //   const dateHandler = (startDate, endDate) => {
  //     setDate_from(moment(startDate).format('yyyy-MM-DD'));
  //     setDate_to(moment(endDate).format('yyyy-MM-DD'));
  //   };

  const newDateHander = (date) => {
    setDate(moment(date).format('yyyy-MM-DD'));
  };

  const saveFavoritesHandler = async (ids) => {
    setIsLoading2(true);
    try {
      await dispatch(addFavorites(ids));
      success('Успешно сохранено');
    } catch (e) {
      error('Не удалось сохранить, попробуйте еще раз позже...');
    }
    setIsLoading2(false);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ReactNotification />

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Новинки</li>
      </ol>
      <h1 className="page-header">Список новинок</h1>

      <Table
        products
        isLoading={isLoading2}
        count={count}
        limitHandler={limitHandler}
        offsetHandler={offsetHandler}
        onOffset={onOffset}
        cleanOffset={cleanOffset}
        lastPage={lastPage}
        sortHandler={sortHandler}
        pageSize={20}
        sortColumn={order_by}
        newDateHander={newDateHander}
        saveFavoritesHandler={saveFavoritesHandler}
        // dateHandler={dateHandler}
        title={isLoading ? '' : `Количество товаров - ${count || 0}`}
        data={isLoading ? [] : newProducts}
        columns={productColumns}
        saveAll={() => {
          let filters = {
            firstseen: {
              value: date,
              operator:
                date === moment().subtract(1, 'd').format('yyyy-MM-DD')
                  ? '=='
                  : '>=',
            },
          };

          return saveAll(
            `/wb/products`,
            order_by,
            dir,
            date_from,
            date_to,
            filters,
            `Отчет по новым товарам c ${date}`
          );
        }}
      />
    </div>
  );
};

export default NewPage;
