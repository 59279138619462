import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../../components/error/errorNotification';
import Loader from '../../components/loaders/Loader';
import { history } from '../../app';
import marketprofit from '../../apis/marketprofit';
import * as authActions from '../../store/actions/auth';
import * as displayActions from '../../store/actions/display';
// import * as wbActions from '../../store/actions/wb';

const UserPage = () => {
  // const [tabAbout, setTabAbout] = useState(true);
  // const [tabTariff, setTabTariff] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  // const [haveTariff, setHaveTariff] = useState(false);
  const info = useSelector((state) => state.auth.user);
  const [phone, setPhone] = useState(info.phone);
  const [name, setName] = useState(info.title);
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [render, setRender] = useState(false);
  const [sweetAlertConfirm, setSweetAlertConfirm] = useState(false);

  const isSu = useSelector((state) => state.auth.is_su);
  // const reqInfo = useSelector((state) => state.wb);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageContentFullWidth(true));
    dispatch(displayActions.setPageContentFullHeight(true));
    dispatch(displayActions.setPageFooter(false));

    return () => {
      dispatch(displayActions.setPageContentFullHeight(false));
      dispatch(displayActions.setPageContentFullWidth(false));
      dispatch(displayActions.setPageFooter(true));
    };
  }, [dispatch]);

  // const showTab = useCallback(
  //   async (e, tab) => {
  //     e.preventDefault();
  // setRender(false);
  // setHaveTariff(false);

  // setTabAbout(tab === 'about' ? true : false);
  // setTabTariff(tab === 'tariff' ? true : false);

  // if (tab === 'tariff' && info.tariff_expired !== null) {
  //   setIsLoading(true);
  //   try {
  //     await dispatch(wbActions.requestAvailable());
  //     setIsLoading(false);
  //     setHaveTariff(true);
  //     setRender(true);
  //   } catch (e) {
  //     error('Не удалось загрузить данные, попробуйте еще раз позже...');
  //     setIsLoading(false);
  //   }
  // }
  // setRender(true);
  //   },
  //   [setTabAbout]
  // );

  const onUpdate = async () => {
    setSweetAlertConfirm(false);

    if (name.length === 0) {
      error('Введите Имя');
    } else if (password !== password2) {
      error('Пароли должны совпадать');
    } else if (phone.length < 11) {
      error('В номере телефона минимум 11 цифр');
    } else if (changePassword && password.length < 7) {
      error('Пароль слишком короткий');
    }
    //  else if (!validator.isStrongPassword(password) ) {
    //   setError('Passwords lenghth must be minimum 8 letters, 1 uppercase letter, 1 symbol, 1 number ');
    // }
    else {
      setIsLoading(true);
      try {
        await dispatch(
          authActions.updateUser(phone, name, password, password2)
        );
        await dispatch(authActions.fetchMe());
      } catch (e) {
        error('Ошибка сервера, попробуйте обновить позже...');
        setIsLoading(false);
      }

      setChangePassword(false);
      setPassword2();
      setPassword();
      setIsUpdate(false);
      setIsLoading(false);
    }
  };

  const becomeBack = async () => {
    setIsLoading(true);
    try {
      const response = await marketprofit.post('/su_exit');
      if (!!response.data.success) {
        setIsLoading(false);

        history.go(0);
      }
    } catch (e) {
      error('Не удалось стать другим пользователем, попробуйте позже...');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='profile'>
        <div className='profile-header'>
          <div className='profile-header-cover'></div>
          <div className='profile-header-content'>
            <div className='profile-header-img'>
              <img src='/assets/img/user/user-12.jpg' alt='' />
            </div>
            <div className='profile-header-info'>
              <h4 className='m-t-10 m-b-5'>{info.title}</h4>
              <p className='m-b-10'>{info.role.title}</p>
              <button
                onClick={() => {
                  setIsUpdate(true);
                }}
                className='btn btn-xs btn-yellow'
              >
                Изменить профиль
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='profile-content'>
        <div className='tab-content p-0'>
          <div className='table-responsiv form-inline'>
            <table className='table table-profile'>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h4>
                      {info.title} <small>{info.role.title}</small>
                    </h4>
                  </th>
                </tr>
              </thead>
              {!isUpdate ? (
                <tbody>
                  <tr className='divider'>
                    <td colSpan='2'></td>
                  </tr>
                  <tr>
                    <td className='field'>Мобильный номер</td>
                    <td>
                      <i className='fa fa-mobile fa-lg m-r-5'></i>
                      {info.phone}
                    </td>
                  </tr>
                  <tr>
                    <td className='field'>Email</td>
                    <td>
                      <i className='fa fa-envelope-open fa-lg m-r-5'></i>
                      {info.email}
                    </td>
                  </tr>
                  <tr className='divider'>
                    <td colSpan='2'></td>
                  </tr>
                  <tr className='highlight'>
                    <td className='field'>&nbsp;</td>
                    <td className='p-t-10 p-b-10'>
                      <button
                        className='btn-new btn-primary width-150'
                        onClick={() => {
                          setIsUpdate(true);
                        }}
                      >
                        Изменить
                      </button>

                      {isSu && (
                        <button
                          className='btn-new btn-inverse m-l-5 width-150'
                          onClick={becomeBack}
                        >
                          <i className='fa fa-user-secret fa-lg m-r-5'></i>
                          Назад
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr className='divider'>
                    <td colSpan='2'></td>
                  </tr>
                  <tr>
                    <td className='field'>Мобильный номер</td>
                    <td>
                      <i className='fa fa-mobile fa-lg m-r-5'></i>
                      <input
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className='field'>ФИО</td>
                    <td>
                      <i className='fa fa-user fa-lg m-r-5'></i>
                      <input
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className='field'>Поменять пароль</td>
                    <td>
                      <i
                        className=' fa fa-edit fa-lg m-r-5'
                        onClick={() => {
                          setChangePassword(!changePassword);
                          setPassword();
                          setPassword2();
                        }}
                      ></i>
                    </td>
                  </tr>
                  {changePassword && (
                    <>
                      <tr>
                        <td className='field'>Новый пароль</td>
                        <td>
                          <i className='fa fa-keyboard fa-lg m-r-5'></i>
                          <input
                            type='password'
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='field'>Повторить новый пароль</td>
                        <td>
                          <i className='fa fa-keyboard fa-lg m-r-5'></i>
                          <input
                            type='password'
                            value={password2}
                            onChange={(e) => {
                              setPassword2(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  )}

                  <tr className='divider'>
                    <td colSpan='2'></td>
                  </tr>
                  <tr className='highlight'>
                    <td className='field'>&nbsp;</td>
                    <td className='p-t-10 p-b-10'>
                      <button
                        onClick={() => setSweetAlertConfirm(true)}
                        className='btn-new btn-primary width-150'
                      >
                        <i className='fa fa-cloud-upload-alt fa-lg m-r-5'></i>
                        Обновить
                      </button>

                      <button
                        onClick={() => {
                          setIsUpdate(false);
                          setPassword();
                          setPassword2();
                        }}
                        className='btn-new btn-danger btn-white-without-border width-150 m-l-5'
                      >
                        <i className='fa fa-window-close fa-lg m-r-5'> </i>
                        Назад
                      </button>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {sweetAlertConfirm && (
              <SweetAlert
                info
                showCancel
                confirmBtnText='Да, обновить'
                confirmBtnBsStyle='info'
                cancelBtnBsStyle='default'
                cancelBtnText='Отмена'
                title='Вы уверены?'
                onConfirm={onUpdate}
                onCancel={() => setSweetAlertConfirm(false)}
              >
                Мы изменим ваши данные
              </SweetAlert>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
