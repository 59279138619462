import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Billing from '../../components/payment/billing';
import error from '../../components/error/errorNotification';
import { history } from '../../app';
import marketprofit from '../../apis/marketprofit';
import Loader from '../../components/loaders/Loader';

const TariffPage = () => {
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useState();
  const [mounth, setMounth] = useState('1');
  const [baseTariff, setBaseTariff] = useState({});
  const [advancedTariff, setadvAncedTariff] = useState({});
  const [professionalTariff, setProfessionalTariff] = useState({});
  const [activeTariff, setActiveTariff] = useState(null);
  const [params, setParams] = useState({});
  const [code, setCode] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [oldBaseTariff, setOldBaseTariff] = useState('');
  const [oldAdvancedTariff, setOldAdvancedTariff] = useState('');
  const [oldProfessionalTariff, setOldProfessionalTariff] = useState('');
  const user_id = useSelector((state) => state.auth.user.id);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);
      try {
        const response = await marketprofit.get(`/tariffs`);

        if (!!response.data.success) {
          setDiscounts(response.data.discounts);
          setBaseTariff(response.data.list[0]);
          setadvAncedTariff(response.data.list[1]);
          setProfessionalTariff(response.data.list[2]);
          setRender(true);
        }
      } catch (e) {
        error('Что-то пошло не так... Попробуйте позже');
      }
      setIsLoading(false);
    };
    rendering();
  }, []);

  const chooseHandler = async (tariff) => {
    if (isAuthenticated === false) {
      history.push('/login');
    } else {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('period', mounth);
        formData.append('tariff_id', tariff.id);
        promoCode && formData.append('code', promoCode.code);
        const response = await marketprofit.put('/invoice', formData);

        if (!!response.data.success) {
          const response2 = await marketprofit.post(
            `/pay/${response.data.id}/start/robokassa`
          );

          if (!!response2.data.success) {
            setParams(response2.data);
          }
        }
      } catch (e) {
        error('Не получилось создать счет, попробуйте еще раз позже');
      }
      setIsLoading(false);
    }
  };

  const promoAccepHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await marketprofit.get(`/tariffs`, {
        params: {
          code: code,
        },
      });

      if (!!response.data.success) {
        response.data.promocode === null && error('Такой промокод не найден');
        setPromoCode(response.data.promocode );
        setDiscounts(response.data.discounts);
        setBaseTariff(response.data.list[0]);
        setadvAncedTariff(response.data.list[1]);
        setProfessionalTariff(response.data.list[2]);
        setOldBaseTariff(response.data.list[0].old_price);
        setOldAdvancedTariff(response.data.list[1].old_price);
        setOldProfessionalTariff(response.data.list[2].old_price);
      }
    } catch (e) {
      error('Не удалось подключить промокод...');
    }
    setIsLoading(false);
  };

  const basic14Active = async () => {
    setIsLoading(true);
    try {
      const response = await marketprofit.post(
        `https://api.marketprofit.ru/invoice/${params.params.InvId}/apply`
      );

      if (response.data.success) {
        history.push('/main');
        history.go(0);
      }
    } catch (e) {
      error(
        'Не удалось активировать данные тариф. Попробуйте еще раз позже...'
      );
    }
    setIsLoading(false);
  };

  const form = () => {
    if (params.params) {
      const paramsOptions = Object.keys(params.params).map((key, i) => (
        <div key={i} className={key}>
          <input type="hidden" id={key} name={key} value={params.params[key]} />
        </div>
      ));
      return paramsOptions;
    }
  };

  return (
    <div className="">
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Главная</Link>
        </li>
        <li className="breadcrumb-item active">Тарифы</li>
      </ol>
      <h1 className="page-header">
        Страница тарифов <small>{/*choose your plan*/}</small>
      </h1>

      {isLoading && <Loader />}
      <ReactNotification />

      {render && (
        <>
          {!activeTariff && (
            <div>
              <div className="discounts">
                <h3>Срок подписки</h3>
                <div className="form__group">
                  <div className="form__radio-group">
                    <input
                      type="radio"
                      className="form__radio-input"
                      id="1"
                      name="size"
                      defaultChecked
                      onChange={(e) => {
                        setMounth(e.target.id);
                      }}
                    />
                    <label htmlFor="1" className="form__radio-label">
                      <span className="form__radio-button"></span>1 месяц
                    </label>
                  </div>

                  <div className="form__radio-group">
                    <input
                      type="radio"
                      className="form__radio-input"
                      id="3"
                      name="size"
                      onChange={(e) => {
                        setMounth(e.target.id);
                      }}
                    />
                    <label htmlFor="3" className="form__radio-label">
                      <span className="form__radio-button"></span>3 месяца
                    </label>
                  </div>

                  <div className="form__radio-group">
                    <input
                      type="radio"
                      className="form__radio-input"
                      id="6"
                      name="size"
                      onChange={(e) => {
                        setMounth(e.target.id);
                      }}
                    />
                    <label htmlFor="6" className="form__radio-label">
                      <span className="form__radio-button"></span>6 месяцев
                    </label>
                  </div>
                </div>
              </div>

              <div
                className="promo"
                style={{ backgroundColor: promoCode ? '#91bd91' : '#ced4da' }}
              >
                <form onSubmit={promoAccepHandler}>
                  <input
                    disabled={promoCode ? true : false}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="ввести промокод"
                    className="form-control"
                  />
                  <button
                    className={`btn btn-${promoCode ? 'success' : 'primary'}`}
                    disabled={promoCode ? true : false}
                  >
                    {promoCode ? (
                      <i className="fas fa-check-circle "></i>
                    ) : (
                      'Применить'
                    )}
                  </button>
                </form>
                {promoCode && <p>{promoCode.title}</p>}
              </div>

              <div className="tariffs__container">
                <div className="tariff tariff-basic">
                  <div className="tariff__heading">
                    <h2>{baseTariff.title}</h2>
                  </div>
                  <div className="tariff__description">
                    <p className="tariff__description--name">
                      Отлично подойдет для знакомства с сервисом
                    </p>
                    <h3 className="price">
                      <CountUp
                        duration={1}
                        formattingFn={(value) =>
                          `${numeral(value).format('0,000,000')} ₽/мес`
                        }
                        end={
                          baseTariff.price * ((100 - discounts[mounth]) / 100)
                        }
                      />

                      {promoCode && (
                        <small className="old-price">
                          {`${numeral(
                            oldBaseTariff * ((100 - discounts[mounth]) / 100)
                          ).format(`0,000,000`)}`}
                        </small>
                      )}
                    </h3>

                    <p>Запросов в день: {baseTariff.requests_limit}</p>
                    <p>
                      Анализ по категориям
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Анализ по брендам <i className="fas fa-times fa-lg"></i>
                    </p>
                    <p>
                      Остатки на складах
                      <i className="fas fa-times fa-lg"></i>
                    </p>
                    <p>
                      Анализ по продавцам
                      <i className="fas fa-times fa-lg"></i>
                    </p>
                    <p>Данные за последние {baseTariff.days_limit} суток</p>
                    <div className="tariff__description-total">
                      <h2>
                        Итого:
                        <CountUp
                          duration={1}
                          formattingFn={(value) =>
                            `${numeral(value).format('0,000,000')} ₽`
                          }
                          end={
                            baseTariff.price *
                            ((100 - discounts[mounth]) / 100) *
                            mounth
                          }
                        />
                        {promoCode && promoCode.code !== 'BASIC14' && (
                          <small className="old-total">
                            {` ${numeral(
                              oldBaseTariff *
                                ((100 - discounts[mounth]) / 100) *
                                mounth
                            ).format(`0,000,000 `)}`}
                          </small>
                        )}
                      </h2>
                    </div>
                  </div>
                  <div className="tariff__booking">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setActiveTariff(baseTariff);
                        chooseHandler(baseTariff);
                      }}
                    >
                      Выбрать тариф
                    </button>
                  </div>
                </div>

                <div className="tariff tariff-advanced">
                  <div className="tariff__heading">
                    <h2>{advancedTariff.title}</h2>
                  </div>
                  <div className="tariff__description">
                    <p className="tariff__description--name">
                      Средний тариф, подойдет, чтобы начать анализировать
                    </p>
                    <h3 className="price">
                      <CountUp
                        duration={1}
                        formattingFn={(value) =>
                          `${numeral(value).format('0,000,000')} ₽/мес`
                        }
                        end={
                          advancedTariff.price *
                          ((100 - discounts[mounth]) / 100)
                        }
                      />
                      {promoCode && promoCode.code !== 'BASIC14' && (
                        <small className="old-price">
                          {`${numeral(
                            oldAdvancedTariff *
                              ((100 - discounts[mounth]) / 100)
                          ).format(`0,000,000`)}`}
                        </small>
                      )}
                    </h3>
                    <p>Запросов в день: {advancedTariff.requests_limit}</p>
                    <p>
                      Анализ по категориям
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Анализ по брендам <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Остатки на складах
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Анализ по продавцам
                      <i className="fas fa-times fa-lg"></i>
                    </p>
                    <p>Данные за последние {advancedTariff.days_limit} суток</p>
                    <div className="tariff__description-total">
                      <h2>
                        Итого:
                        <CountUp
                          duration={1}
                          formattingFn={(value) =>
                            `${numeral(value).format('0,000,000')} ₽`
                          }
                          end={
                            advancedTariff.price *
                            ((100 - discounts[mounth]) / 100) *
                            mounth
                          }
                        />
                        {promoCode && promoCode.code !== 'BASIC14' && (
                          <small className="old-total">
                            {` ${numeral(
                              oldAdvancedTariff *
                                ((100 - discounts[mounth]) / 100) *
                                mounth
                            ).format(`0,000,000 `)}`}
                          </small>
                        )}
                      </h2>
                    </div>
                  </div>
                  <div className="tariff__booking">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setActiveTariff(advancedTariff);
                        chooseHandler(advancedTariff);
                      }}
                    >
                      Выбрать тариф
                    </button>
                  </div>
                </div>

                <div className="tariff tariff-professional">
                  <div className="tariff__heading">
                    <h2>{professionalTariff.title}</h2>
                  </div>
                  <div className="tariff__description">
                    <p className="tariff__description--name">
                      Данный тариф создан для мощных оборотистых компаний
                    </p>
                    <h3 className="price">
                      <CountUp
                        duration={1}
                        formattingFn={(value) =>
                          `${numeral(value).format('0,000,000')} ₽/мес`
                        }
                        end={
                          professionalTariff.price *
                          ((100 - discounts[mounth]) / 100)
                        }
                      />
                      {promoCode && promoCode.code !== 'BASIC14' && (
                        <small className="old-price">
                          {`${numeral(
                            oldProfessionalTariff *
                              ((100 - discounts[mounth]) / 100)
                          ).format(`0,000,000`)}`}
                        </small>
                      )}
                    </h3>
                    <p>Запросов в день: {professionalTariff.requests_limit}</p>
                    <p>
                      Анализ по категориям
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Анализ по брендам <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Остатки на складах
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Анализ по продавцам
                      <i className="fas fa-check fa-lg"></i>
                    </p>
                    <p>
                      Данные за последние {professionalTariff.days_limit} суток
                    </p>
                    <div className="tariff__description-total">
                      <h2>
                        Итого:
                        <CountUp
                          duration={1}
                          formattingFn={(value) =>
                            `${numeral(value).format('0,000,000')} ₽`
                          }
                          end={
                            professionalTariff.price *
                            ((100 - discounts[mounth]) / 100) *
                            mounth
                          }
                        />
                        {promoCode && promoCode.code !== 'BASIC14' && (
                          <small className="old-total">
                            {` ${numeral(
                              oldProfessionalTariff *
                                ((100 - discounts[mounth]) / 100) *
                                mounth
                            ).format(`0,000,000 `)}`}
                          </small>
                        )}
                      </h2>
                    </div>
                  </div>
                  <div className="tariff__booking">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setActiveTariff(professionalTariff);
                        chooseHandler(professionalTariff);
                      }}
                    >
                      Выбрать тариф
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTariff && (
            <>
              <i
                className="tariff__back fas fa-arrow-left  fa-7x p-b-20"
                onClick={() => {
                  setActiveTariff(null);
                  setMounth('1');
                }}
              ></i>
              <Billing
                promoCode={promoCode || ''}
                discounts={discounts}
                mounth={mounth}
                activeTariff={activeTariff}
                form={form}
                params={params}
                basic14Active={basic14Active}
              />
            </>
          )}
          <div className="pay__info">
            <div className="note note-primary m-b-15">
              <div className="note-icon">
                <i className="fa fa-dollar-sign"></i>
              </div>
              <div className="note-content">
                <h4>
                  <b>Информация об оплате</b>
                </h4>
                Принимаем платежи через Robokassa практически всеми известными
                способами (от SMS-сообщений до MasterCard):
                <ul>
                  <li>Банковские карты;</li>
                  <li>Наличные платежи через терминалы и банкоматы; </li>
                  <li>Сервисы мобильной коммерции от мобильных операторов; </li>
                  <li>Системы интернет-банкинга;</li>
                  <li>Мобильные приложения Android и iOS.</li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TariffPage;
