import axios from 'axios';

export default axios.create({
  baseURL: 'https://api.marketprofit.ru',

  withCredentials: true,
  // headers: {
  //   // 'Content-Type': 'multipart/form-data',
  // },
});
