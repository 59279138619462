import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import error from '../components/error/errorNotification';
import marketprofit from '../apis/marketprofit';
import { history } from '../app';
import Loader from '../components/loaders/Loader';
import * as displayActions from '../store/actions/display';

const RecoveryPage = (props) => {
  const [token, setToken] = useState(
    props.location.search.split('?token=').join('')
  );
  const [email, setEmail] = useState('');
  const [emailSend, setEmailSend] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayActions.setPageSidebar(false));
    dispatch(displayActions.setPageHeader(false));
    dispatch(displayActions.setPageFooter(false));
    dispatch(displayActions.setPageContentFullWidth(true));
    return () => {
      dispatch(displayActions.setPageSidebar(true));
      dispatch(displayActions.setPageHeader(true));
      dispatch(displayActions.setPageFooter(true));
      dispatch(displayActions.setPageContentFullWidth(false));
    };
  }, [dispatch]);

  const onRecover = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('email', email);
      const response = await marketprofit.put('/password_recovery', formData);
      if (!!response.data.success) {
        setEmailSend(true);
      }
    } catch (err) {
      error('Емейл не найден');
    }
    setIsLoading(false);
  };

  const onChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (password !== password2) {
      error('Пароли должны совпадать');
      setPassword('');
      setPassword2('');
      setIsLoading(false);

      return;
    } else if (password.length < 6) {
      error('Пароль слишком короткий');
      setIsLoading(false);

      return;
    }
    try {
      const formData = new FormData();
      formData.append('password', password);
      formData.append('password2', password2);
      const response = await marketprofit.post(
        `/password_recovery/${token}`,
        formData
      );
      if (!!response.data.success) {
        setIsLoading(false);
        history.push('/login');
      }
    } catch (err) {
      error('Токен не подходит');
      setIsLoading(false);
    }
  };

  return (
    <div className='login login-v1'>
      {isLoading && <Loader />}
      <ReactNotification />

      <div className='login-container'>
        <div className='login-header'>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <div className='brand'>
              <span className='logo'></span>
              <b>Market</b> Profit
              <small></small>
            </div>
          </Link>

          <div className='icon'>
            <i className='fa fa-lock'></i>
          </div>
        </div>
        <div className='login-body'>
          <div className='login-content'>
            {emailSend || token ? (
              <form
                className='margin-bottom-0'
                onSubmit={onChange}
                autoComplete='off'
              >
                {!token && (
                  <h4>
                    На вашу почту пришло письмо с токеном, введите его ниже
                  </h4>
                )}
                <input type='password' hidden />

                <div className='form-group m-b-20'>
                  <input
                    autoComplete='off'
                    type='text'
                    className='form-control form-control-lg inverse-mode'
                    placeholder='Token'
                    value={token}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                    required
                  />
                </div>

                <div className='form-group m-b-20'>
                  <input
                    autoComplete='off'
                    type='password'
                    className='form-control form-control-lg inverse-mode'
                    placeholder='Новый пароль'
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                </div>

                <div className='form-group m-b-20'>
                  <input
                    autoComplete='off'
                    type='password'
                    className='form-control form-control-lg inverse-mode'
                    placeholder='Новый пароль'
                    value={password2}
                    onChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                    required
                  />
                </div>

                <div className='login-buttons'>
                  <button
                    type='submit'
                    className='btn btn-success btn-block btn-lg'
                    onSubmit={onRecover}
                  >
                    Поменять пароль
                  </button>
                </div>
              </form>
            ) : (
              <form
                className='margin-bottom-0'
                onSubmit={onRecover}
                autocomplete='off'
              >
                <input type='password' hidden />
                <div className='form-group m-b-20'>
                  <input
                    autoComplete='off'
                    type='text'
                    className='form-control form-control-lg inverse-mode'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                </div>

                <div className='login-buttons'>
                  <button
                    type='submit'
                    className='btn btn-success btn-block btn-lg'
                    onSubmit={onRecover}
                  >
                    Восстановить пароль
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPage;
