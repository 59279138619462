import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const AdminRoute = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isLogin);
  const isAdmin = useSelector((state) => state.auth.user.role.is_admin);
  return isAuthenticated && isAdmin ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to='/notavailable' />
  );
};

export default AdminRoute;
