import numeral from 'numeral';

class Brand {
  constructor(
    num,
    img,
    id,
    name,
    AvgRating,
    firstseen,
    SumFeedbackCount,
    Products,
    SumSellQty,
    SumRevenue,
    url
  ) {
    this.num = num;
    this.img = img;
    this.id = id;
    this.name = name;
    this.AvgRating = AvgRating;
    this.firstseen = firstseen;
    this.SumFeedbackCount = SumFeedbackCount;
    this.Products = Products;
    this.SumSellQty = SumSellQty;
    this.SumRevenue = SumRevenue;
    this.url = url;
  }
}

export default Brand;

export const newBrand = (brand, i) => {
  return new Brand(
    i + 1,
    brand.image,
    brand.id,
    brand.name,
    brand.AvgRating === 'nan' ? '-' : numeral(brand.AvgRating).format(`0.00`),
    brand.firstseen,
    brand.SumFeedbackCount === null ? 0 : brand.SumFeedbackCount,
    brand.Products,
    brand.SumSellQty === null ? 0 : brand.SumSellQty,
    brand.SumRevenue === null ? 0 : brand.SumRevenue,
    brand.url
  );
};
