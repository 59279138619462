import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const isSSR = typeof window !== 'undefined';

  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 2000 : window.innerWidth,
    height: isSSR ? 1080 : window.screen.height,
  });

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.screen.height });
  };
  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
}
