import React from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';
import { historyOptions } from '../chart/chartOptions';

const ProductPosition = ({ historyPos, history, days }) => {
  const categories = historyPos.map((cat) => {
    return {
      name: cat[0].name.split('\n').join('').trim(),
      history: cat
        .map((ct) => {
          return +ct.categoryOrdnum;
        })
        .reverse(),
    };
  });

  const date = historyPos.map((cat) => {
    return cat.map((ct) => {
      return ct.createdAt;
    });
  });

  const mergeDates = (...date) => {
    let allDate = [];

    date.forEach((dat) => {
      allDate = [...allDate, ...dat];
    });
    const uniqueDate = allDate.filter(
      (item, index) => allDate.indexOf(item) === index
    );
    return uniqueDate.sort();
  };

  // const main = historyPos[0]
  //   .map((cat) => {
  //     return cat.createdAt;
  //   })
  //   .reverse();

  // const mainOrd = historyPos[0]
  //   .map((cat) => {
  //     return +cat.categoryOrdnum;
  //   })
  //   .reverse();

  // const getIndex = (arrSm, arrBig) => {
  //   let ret = [];

  //   for (let i = 0; i < arrSm.length; i += 1) {
  //     if (arrBig.indexOf(arrSm[i]) > -1) {
  //       ret.push(arrBig.indexOf(arrSm[i]));
  //     }
  //   }
  //   return ret;
  // };

  // const array_move = (arr, new_index) => {
  //   let newArr = [];
  //   for (let i = 0; i < new_index[0]; i++) {
  //     newArr.push(0);
  //   }

  //   let finalArr = [...newArr, ...arr];

  //   return finalArr;
  // };

  const getIndexOfShortArr = () => {
    const shortArr2 = categories.map((cat, i) => {
      return cat.history.length < mergeDates(...date).length ? i : -1;
    });
    const arrOfI = shortArr2.filter((arr) => {
      return arr !== -1;
    });

    return arrOfI;
  };

  const shortArrDate = getIndexOfShortArr().map((arr) => {
    return historyPos[arr]
      .map((cat) => {
        return cat.createdAt;
      })
      .reverse();
  });

  const shortArrQty = getIndexOfShortArr().map((arr) => {
    return historyPos[arr]
      .map((cat) => {
        return +cat.categoryOrdnum;
      })
      .reverse();
  });

  const getIndex = (arrSm, arrBig) => {
    let ret = [];

    for (let i = 0; i < arrSm.length; i += 1) {
      if (arrBig.indexOf(arrSm[i]) > -1) {
        ret.push(arrBig.indexOf(arrSm[i]));
      }
    }

    return ret;
  };

  const arrayModified = (arr, new_index, longArr) => {
    let newArr = [];
    for (let i = 0; i < new_index[0]; i++) {
      newArr.push(0);
    }

    let finalArr = [...newArr, ...arr];

    if (finalArr.length < longArr.length) {
      for (let i = finalArr.length; i < longArr.length; i++) {
        finalArr.push(0);
      }
    }

    return finalArr;
  };

  const areaChart = {
    options: {
      ...historyOptions,
      xaxis: {
        ...historyOptions.xaxis,
        categories: mergeDates(...date),
      },
      yaxis: [
        {
          seriesName: 'Позиция',
          reversed: true,
          labels: {
            formatter: function (val, index) {
              return `№ ${numeral(val).format(`0,000,000`)} `;
            },
          },
          title: {
            text: 'Позиция №',
          },
        },
      ],
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { height: 300 },
            yaxis: [
              {
                reversed: true,
              },
            ],
          },
        },
        {
          breakpoint: 500,
          options: {
            chart: { height: 300 },
            yaxis: [
              {
                show: false,
                reversed: true,
              },
            ],
          },
        },
      ],
    },
  };

  const series = [
    ...categories.map((cat, i) => {
      return {
        name: cat.name,
        data:
          // cat.history[0] === 0
          //   ? array_move(mainOrd, getIndex(main, mergeDates(...date)))
          //   : cat.history,
          cat.history.length < mergeDates(...date).length
            ? arrayModified(
                shortArrQty[
                  getIndexOfShortArr().findIndex((arr) => {
                    return arr === i;
                  })
                ],
                getIndex(
                  shortArrDate[
                    getIndexOfShortArr().findIndex((arr) => {
                      return arr === i;
                    })
                  ],
                  mergeDates(...date)
                ),
                mergeDates(...date)
              )
            : cat.history,
      };
    }),
  ];

  return (
    <div style={{ marginTop: '20px' }}>
      <Panel>
        <PanelHeader>{`Позиция товара по категориям за количество дней - ${days}`}</PanelHeader>
        <PanelBody className="p-0">
          <Chart type="line" options={areaChart.options} series={series} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default React.memo(ProductPosition);
