import { newBill } from '../../models/Bill';
import { newUser } from '../../models/User';
import {
  CHANGE_USER,
  CREATE_USER,
  DELETE_BILL,
  DELETE_USER,
  FETCH_BILLS,
  FETCH_ME,
  FETCH_USERS,
  LOGIN,
  LOGOUT,
  SET_TARIFF,
  SIGNUP,
  UPDATE_USER,
} from '../actions/auth';

const initialState = {
  isLogin: false,
  user: {
    id: null,
    tariff: {
      title: 'Доступ не оплачен',
      days_limit: 0,
      permissions: [],
      id: null,
    },
    tariff_expired: null,
    is_used_start_promocode: 0,
  },
  userList: [],
  billsList: [],
  billsCount: 0,
  userCounts: 0,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogin: action.payload.success,
        user: { ...state.user, id: action.payload.id },
      };
    case LOGOUT:
      return {
        ...initialState,
      };

    case SIGNUP:
      return {
        ...state,
        isLogin: action.payload.success,
        user: { ...state.user, id: action.payload.id },
      };

    case CREATE_USER:
      return {
        ...state,
      };
    case FETCH_ME:
      return {
        ...state,
        is_su: action.payload.is_su || null,
        isLogin: action.payload.success,
        user: { ...state.user, ...action.payload.user },
      };
    case FETCH_USERS:
      const userList = action.payload.list.map((user) => {
        return newUser(user);
      });
      return {
        ...state,
        userList: [...userList],
        userCounts: action.payload.count,
      };
    case UPDATE_USER:
      return {
        ...state,
      };

    case CHANGE_USER:
      return {
        ...state,
      };
    case DELETE_USER:
      return {
        ...state,
      };
    case SET_TARIFF:
      return {
        ...state,
      };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////BILLS
    case FETCH_BILLS:
      const billsList = action.payload.list.map((bill) => {
        return newBill(bill);
      });
      return {
        ...state,
        billsList: [...billsList],
        billsCount: action.payload.count,
      };
    case DELETE_BILL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default authReducer;
