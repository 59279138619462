import React, { useState, useEffect, useRef } from 'react';

const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

const ScrollToTop = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const shouldRenderChild = useDelayUnmount(isVisible, 580);
  const timeoutRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div className="scroll-to-top">
      {shouldRenderChild && (
        <div onClick={scrollToTop}>
          <i
            className={`fas fa-arrow-alt-circle-up fa-3x ${
              !isVisible ? 'fadeout' : ''
            } `}
          ></i>
        </div>
      )}
    </div>
  );
};

export default React.memo(ScrollToTop);
