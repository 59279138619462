import FileDownload from 'js-file-download';

import marketprofit from '../../apis/marketprofit';

export const saveAll = async (
  url,
  order_by,
  dir,
  date_from,
  date_to,
  filter,
  title
) => {
  try {
    let filters = [];

    for (let fil in filter) {
      filter[fil].value &&
        filters.push({
          name: fil,
          operator: filter[fil].operator,
          value: filter[fil].value,
        });
    }

    const response = await marketprofit.get(url, {
      responseType: 'blob',
      params: {
        order_by,
        dir,
        date_from,
        date_to,
        filters: JSON.stringify(filters),
        format: 'csv',
      },
    });
    FileDownload(response.data, `${title}.csv`);
  } catch (e) {
    console.log(e.message);
  }
};
