import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// import Calendar from '../../components/calendar/calendar';
import { brandsHistoryColumns } from '../../components/tables/brandsHistoryColumns';
import { categoriesColumn } from '../../components/tables/categoriesColumn';
import DonutChart from '../../components/chart/donutChart';
import error, { success } from '../../components/error/errorNotification';
import {
  filterCategories,
  filterProducts,
  filterSellers,
  filtersState,
} from '../../components/filters/filterHandlers';
import HistoryChart from '../../components/chart/historyChart';
import Loader from '../../components/loaders/Loader';
import NavigationPage from '../../components/navigation/navigationPage';
import { productColumns } from '../../components/tables/productColumns';
import Table from '../../components/tables/Table';
import TrandsChart from '../../components/chart/trandsChart';
import { trendsColumnsBrands } from '../../components/tables/trendsColumns';
import { sellersListColumns } from '../../components/tables/sellersListColumns';
import WarningLabel from '../../components/error/warningLabel';
import * as wbActions from '../../store/actions/wb';
import { saveAll } from '../../components/tables/saveAll';

const BrandPage = (props) => {
  const id = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offSet, setOffset] = useState(0);
  const [order_by, setOrder_by] = useState('SumRevenue');
  const [dir, setDir] = useState('desc');
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const permissions = useSelector(
    (state) => state.auth.user.tariff.permissions
  );
  const [date_from, setDate_from] = useState(
    moment().subtract(days_limit, 'd').format('yyyy-MM-DD')
  );
  const [date_to, setDate_to] = useState(
    moment().subtract(1, 'd').format('yyyy-MM-DD')
  );
  const [activePill, setActivePill] = useState('');
  const [filter, setFilter] = useState(filtersState);

  const brandsProductsList = useSelector(
    (state) => state.wb.brandsProductsList
  );
  const brandsCategoriesList = useSelector(
    (state) => state.wb.brandsCategoriesList
  );
  const brandsHistory = useSelector((state) => state.wb.brandsHistory);
  const count = useSelector((state) => state.wb.counts.productsCount);
  const categoriesCount = useSelector(
    (state) => state.wb.counts.categoriesCount
  );
  const trends = useSelector((state) => state.wb.trends);
  const brandsSellers = useSelector((state) => state.wb.brandsSellers);
  const brandTitles = useSelector((state) => state.wb.brandTitles);
  const days = moment(date_to).diff(moment(date_from), 'days') + 1;

  const dispatch = useDispatch();
  const titles = ['Товары', 'Категории', 'Продавцы', 'По дням', 'Тренд'];
  const links = [
    `/wildberries/brands/products/${id}`,
    `/wildberries/brands/categories/${id}`,
    `/wildberries/brands/sellers/${id}`,
    `/wildberries/brands/history/${id}`,
    `/wildberries/brands/trends/${id}`,
  ];

  useEffect(() => {
    const rendering = async () => {
      if (brandTitles.id === id) {
        return;
      }
      try {
        await dispatch(wbActions.getBrandInfo(id));
      } catch (e) {
        error('Не удалось загрузить название бренда');
      }
    };

    rendering();
  }, [dispatch, brandTitles, id]);

  useEffect(() => {
    props.location.pathname.split('/')[3] === 'products' && setActivePill('1');
    props.location.pathname.split('/')[3] === 'categories' &&
      setActivePill('2');
    props.location.pathname.split('/')[3] === 'sellers' && setActivePill('3');
    props.location.pathname.split('/')[3] === 'trends' && setActivePill('5');
    props.location.pathname.split('/')[3] === 'history' && setActivePill('4');
  }, [props.location.pathname]);

  useEffect(() => {
    const rendering = async () => {
      setIsLoading(true);

      try {
        switch (activePill) {
          case '1':
            await dispatch(
              wbActions.getBrandsProducts(
                id,
                limit,
                offSet,
                order_by,
                dir,
                date_from,
                date_to,
                filter
              )
            );

            setIsLoading(false);

            return;

          case '2':
            await dispatch(
              wbActions.getBrandsCategories(id, date_from, date_to, filter)
            );
            setIsLoading(false);

            return;

          case '3':
            permissions.includes('BrandSellers') &&
              (await dispatch(
                wbActions.getBrandsSellers(id, date_from, date_to, filter)
              ));
            setIsLoading(false);

            return;

          case '4':
            await dispatch(wbActions.getBrandsHistory(id, date_from, date_to));
            setIsLoading(false);

            return;

          case '5':
            await dispatch(wbActions.getBrandsTrends(id));
            setIsLoading(false);

            return;

          default:
            return;
        }
      } catch (e) {
        error('Не удалось загрузить данные... Попробуйте позже');
      }
    };
    rendering();
  }, [
    dispatch,
    activePill,
    id,
    date_from,
    date_to,
    limit,
    offSet,
    order_by,
    dir,
    permissions,
    filter,
  ]);

  const limitHandler = (lim) => {
    setLimit(lim);
  };

  const offsetHandler = (set) => {
    setOffset(offSet + set);
  };

  const onOffset = (set) => {
    setOffset(set);
  };

  const cleanOffset = () => {
    setOffset(0);
  };

  const lastPage = (last) => {
    setOffset(last);
  };

  const sortHandler = (by, dr) => {
    setOrder_by(by);
    !!dr ? setDir('desc') : setDir('asc');
  };

  const dateHandler = (startDate, endDate) => {
    setDate_from(moment(startDate).format('yyyy-MM-DD'));
    setDate_to(moment(endDate).format('yyyy-MM-DD'));
  };

  const togglePill = (pill) => {
    if (activePill !== pill) {
      setActivePill(pill);
    }
  };

  const saveFavoritesHandler = async (ids) => {
    setIsLoading2(true);
    try {
      await dispatch(wbActions.addFavorites(ids));
      success('Успешно сохранено');
    } catch (e) {
      error('Не удалось сохранить, попробуйте еще раз позже...');
    }
    setIsLoading2(false);
  };

  const filterProductsHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterProducts(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  const filterCategoriesHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterCategories(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  const filterSellersHandler = (allFilters) => {
    allFilters.map((fil) => {
      return filterSellers(fil.id, fil.value, fil.operator, setFilter);
    });
  };

  return (
    <div className="">
      {isLoading && <Loader />}
      <ReactNotification />
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Домой</Link>
        </li>
        <li className="breadcrumb-item active"> Страница Бренда</li>
      </ol>
      <h1 className="page-header">
        {`Страница Бренда 
         ${brandTitles.name} `}
        {/* <small>{!isLoading && `Найдено товаров - ${count} `}</small> */}
      </h1>

      <div id="navigation">
        <NavigationPage
          togglePill={togglePill}
          activePill={activePill}
          isLoading={isLoading}
          titles={titles}
          links={links}
        />
        {/* {activePill === '5' ? null : <Calendar dateHandler={dateHandler} />} */}
      </div>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// PRODUCTS */}

      {activePill === '1' && (
        <Table
          filterable
          products
          saveFavoritesHandler={saveFavoritesHandler}
          isLoading={isLoading2}
          count={count}
          limitHandler={limitHandler}
          offsetHandler={offsetHandler}
          onOffset={onOffset}
          cleanOffset={cleanOffset}
          lastPage={lastPage}
          sortHandler={sortHandler}
          pageSize={20}
          dateHandler={dateHandler}
          filterHandler={filterProductsHandler}
          title={
            isLoading
              ? ''
              : `Список товаров - ${count}, за количество дней - ${days}`
          }
          data={brandsProductsList}
          columns={productColumns}
          saveAll={() =>
            saveAll(
              `wb/brand/${id}/products`,

              order_by,
              dir,
              date_from,
              date_to,
              filter,
              `Отчет по товарам ${brandTitles.name} за ${days} дней`
            )
          }
        />
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// CATEGORIES */}
      {activePill === '2' && (
        <>
          {activePill === '2' && (
            <DonutChart data={isLoading ? [] : brandsCategoriesList} />
          )}

          <Table
            filterable
            count={categoriesCount}
            pageSize={20}
            dateHandler={dateHandler}
            filterHandler={filterCategoriesHandler}
            title={
              isLoading
                ? ''
                : `Категорий бренда - ${categoriesCount}, за количество дней - ${days}`
            }
            data={isLoading ? [] : brandsCategoriesList}
            columns={categoriesColumn}
            saveAll={() =>
              saveAll(
                `/wb/brand/${id}/categories`,

                order_by,
                dir,
                date_from,
                date_to,
                filter,
                `Отчет по категориям ${brandTitles.name}(${categoriesCount}шт.)  за ${days} дней`
              )
            }
          />
        </>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// SELLERS */}

      {activePill === '3' &&
        (permissions.includes('BrandSellers') ? (
          <>
            {activePill === '3' && (
              <DonutChart data={isLoading ? [] : brandsSellers} />
            )}
            <Table
              filterable
              pageSize={20}
              dateHandler={dateHandler}
              filterHandler={filterSellersHandler}
              title={
                isLoading
                  ? ''
                  : `Топ-100 продавцов бренда  за количество дней - ${days}`
              }
              data={isLoading ? [] : brandsSellers}
              columns={sellersListColumns}
              saveAll={() =>
                saveAll(
                  `/wb/brand/${id}/sellers`,
                  order_by,
                  dir,
                  date_from,
                  date_to,
                  filter,
                  `Отчет по продавцам ${brandTitles.name} за ${days} дней`
                )
              }
            />
          </>
        ) : (
          <WarningLabel
            header={
              'Ваш тариф не имеет возможности смотреть продавцов брендов.'
            }
            title={` Данную функцию открывает тариф
      "Профессиональный" `}
          />
        ))}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// HISTORY */}

      {activePill === '4' && (
        <div className="pill__history">
          {activePill === '4' && (
            <HistoryChart
              history={isLoading ? [] : brandsHistory}
              days={days}
            />
          )}
          <Table
            pageSize={40}
            dateHandler={dateHandler}
            title={
              isLoading ? '' : `История бренда за количество дней - ${days}`
            }
            sortBy={[{ id: 'createdAt', desc: true }]}
            data={isLoading ? [] : brandsHistory}
            columns={brandsHistoryColumns}
            footer={true}
            saveAll={() =>
              saveAll(
                `/wb/brand/${id}`,

                order_by,
                dir,
                date_from,
                date_to,
                filter,
                `Отчет по истории ${brandTitles.name} за ${days} дней`
              )
            }
          />
        </div>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// TRAND */}

      {activePill === '5' && (
        <div className="pill__trends">
          {activePill === '5' && (
            <TrandsChart days={days} history={isLoading ? [] : trends} />
          )}
          <Table
            pageSize={40}
            title={isLoading ? '' : `Тренд по неделям`}
            sortBy={[{ id: 'startWeek', desc: true }]}
            data={isLoading ? [] : trends}
            columns={trendsColumnsBrands}
            saveAll={() =>
              saveAll(
                `/wb/brand/${id}/trends`,
                order_by,
                dir,
                date_from,
                date_to,
                filter,
                `Отчет по тренду ${brandTitles.name}`
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default BrandPage;
