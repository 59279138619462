import React from 'react';
import { Link } from 'react-router-dom';

const WarningLabel = ({ header, title }) => {
  return (
    <Link to='/tariffs'>
      <div className='note note-warning note-with-right-icon m-b-15'>
        <div className='note-icon'>
          <i className='fa fa-exclamation-triangle'></i>
        </div>
        <div className='note-content text-right'>
          <h4>
            <b>{header}</b>
          </h4>
          <p>{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default WarningLabel;
