import React from 'react';

const LoadingPage = () => (
  <div className='loader'>
    <img
      className='loader__image'
      alt='loading...'
      src='/assets/img/loader.gif'
    />
  </div>
);

export default LoadingPage;
