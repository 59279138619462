import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import marketprofit from '../../../apis/marketprofit';
import { history } from '../../../app';
import Loader from '../../loaders/Loader';

const SearchForm = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const tariff = useSelector((state) => state.auth.user.tariff.id);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const searchHandler = (e) => {
    e.preventDefault();

    const searching = async () => {
      setIsLoading(true);
      let id;
      const getLastItem = (searchValue) => {
        const value = searchValue.split('/');
        return value[4];
      };
      searchValue.includes('www.wildberries.ru')
        ? (id = getLastItem(searchValue))
        : (id = searchValue);

      try {
        const response = await marketprofit.get(`/wb/product/${id}`);
        if (!!response.data.success) {
          history.push(`/wildberries/item/${id}`);
        }
      } catch (e) {
        history.push({
          pathname: '/notfound',
          search: `?search=${searchValue}`,
        });
      }

      setIsLoading(false);
    };

    !isAuthenticated
      ? history.push('/login')
      : !tariff
      ? history.push('/notavailable')
      : searching();

    setSearchValue('');
  };
  return (
    <li className='navbar-form'>
      {isLoading && <Loader />}

      <form onSubmit={searchHandler} name='search_form'>
        <div className='form-group'>
          <input
            style={{ textOverflow: 'ellipsis' }}
            type='text'
            className='form-control'
            placeholder='Поиск товара по ID'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <button type='submit' className='btn btn-search'>
            <i className='fa fa-search'></i>
          </button>
        </div>
      </form>
    </li>
  );
};

export default SearchForm;
