import React from 'react';
import { Link } from 'react-router-dom';

const FailedPage = () => {
  return (
    <div className=''>
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/'>Главная</Link>
        </li>
        <li className='breadcrumb-item active'>Ошибка платежа</li>
      </ol>
      <h1 className='page-header'>
        Ошибка при оплате <small>{/*choose your plan*/}</small>
      </h1>

      <div className='failed'>
        <h1>Платеж не прошел!</h1>
        <Link to='/main'>
          <div className='failed__logo'>
            <i class='fas  fa-exclamation-triangle fa-10x'></i>
          </div>
        </Link>
        <h3>Нам очень жаль, но мы не смогли списать необходимую сумму</h3>
      </div>
    </div>
  );
};

export default FailedPage;
