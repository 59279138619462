import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';

const Calendar = (props) => {
  const days_limit = useSelector((state) => state.auth.user.tariff.days_limit);
  const [startDate, setStartDate] = useState(
    moment().subtract(days_limit, 'd').toDate()
  );
  const [endDate, setEndDate] = useState(moment().subtract(1, 'd').toDate());

  useEffect(() => {
    props.dateHandler(startDate, endDate);
  }, [props, startDate, endDate]);

  const startDateHandler = (date) => {
    setStartDate(date);
  };

  const endDateHandler = (date) => {
    setEndDate(date);
  };

  return (
    <form className="form-horizontal form-bordered">
      <div className="form-group row">
        <div className="calendar">
          <DatePicker
            locale={ru}
            selected={startDate}
            onChange={startDateHandler}
            className="form-control"
            dateFormat="с dd-MM-yyyy"
            minDate={moment().subtract(days_limit, 'd').toDate()}
            maxDate={moment().subtract(1, 'd').toDate()}
          />
          {/* <i className='fa fa-calendar-alt fa-lg' /> */}
          <DatePicker
            locale={ru}
            selected={endDate}
            onChange={endDateHandler}
            className="form-control"
            dateFormat="по dd-MM-yyyy"
            minDate={moment()
              .subtract(days_limit + 1, 'd')
              .toDate()}
            maxDate={moment().subtract(1, 'd').toDate()}
            // todayButton='Вчера'
          />
        </div>
      </div>
    </form>
  );
};

export default React.memo(Calendar);
