import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';

const ProductDescription = ({ product, modelHandler }) => {
  return (
    <Panel>
      <PanelHeader>
        Информация по продукту {`${product.name ? product.name : ''}`}
      </PanelHeader>
      <PanelBody>
        <div className="product__body">
          <a
            className="product__body-link"
            href={product.url}
            target="_blank"
            rel="noreferrer"
          >
            <h5>Wildberries</h5>
          </a>

          <div className="product__body-description">
            <table className="table">
              <tbody>
                <tr>
                  <td className="field">
                    <i className="fa fa-info fa-lg"></i>ID номер
                  </td>
                  <td>{product.id}</td>
                </tr>
                <tr>
                  <td className="field">
                    <i className="fa fa-clipboard fa-lg"></i>Название
                  </td>
                  <td>{product.name}</td>
                </tr>

                <tr>
                  <td className="field">
                    <i className="fa fa-bold fa-lg"></i>Бренд
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: `/wildberries/brands/products/${product.siteBrandId}`,
                        state: product.brand,
                      }}
                    >
                      {product.brand}
                    </Link>
                  </td>
                </tr>

                <tr>
                  <td className="field">
                    <i className="fa fa-id-badge fa-lg"></i>Продавец
                  </td>
                  <td>
                    {' '}
                    <Link
                      to={{
                        pathname: `/wildberries/sellers/products/${product.sellerId}`,
                        state: product.sellerId,
                      }}
                    >
                      {product.seller}
                    </Link>
                  </td>
                </tr>

                {Object.keys(product).length > 0 &&
                  product.categories.length > 0 && (
                    <tr>
                      <td className="field">
                        <i className="fa fa-clipboard-list fa-lg"></i>Категории
                      </td>
                      <td>
                        {product.categories.map((cat, i) => {
                          return (
                            <div key={cat.name + i}>
                              {cat.parentId && cat.parent.parentId && (
                                <Link
                                  to={{
                                    pathname: `/wildberries/categories/products/${cat.parent.parentId}`,
                                  }}
                                >{`${cat.parent.parent.name}/`}</Link>
                              )}
                              {cat.parentId && (
                                <Link
                                  to={{
                                    pathname: `/wildberries/categories/products/${cat.parentId}`,
                                  }}
                                >{`${cat.parent.name}/`}</Link>
                              )}
                              <Link
                                to={{
                                  pathname: `/wildberries/categories/products/${cat.categoryId}`,
                                }}
                              >
                                {`${cat.name}`}
                              </Link>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  )}

                {product.price !== product.finishPrice && (
                  <tr>
                    <td className="field">
                      <i className="fa fa-dollar-sign fa-lg"></i>Цена до скидки
                    </td>
                    <td>
                      {product.price} ₽, скидка{' '}
                      {numeral(
                        ((product.price - product.finishPrice) /
                          product.price) *
                          100
                      ).format(`00`)}
                      {/* {product.sale ? product.sale : 0} */}%
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="field">
                    <i className="fa fa-dollar-sign fa-lg"></i>Цена
                  </td>
                  <td>{product.finishPrice} ₽</td>
                </tr>
                <tr>
                  <td className="field">
                    <i className="fa fa-calendar-alt fa-lg"></i>Создан
                  </td>
                  <td>{moment(product.firstseen).format('DD.MM.YYYY')}</td>
                </tr>
                <tr>
                  <td className="field ">
                    <i className="fa fa-sticky-note fa-lg"></i>
                    Дополнительно
                  </td>
                  <td className="overw">
                    <span
                      className="overview rating"
                      style={{
                        background: !product.rating
                          ? '#ccc'
                          : product.rating >= 4
                          ? '#32a932'
                          : '#da4441',
                      }}
                    >
                      Рейтинг: {product.rating ? product.rating : '-'}
                    </span>
                    <span className="overview feedback">
                      Комментариев:{' '}
                      {product.feedbackCount ? product.feedbackCount : '0'}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {product.images && (
            <div className="product__body-gallery">
              {product.images.map((image, index) => {
                return (
                  <div className="product__body-gallery--img" key={index}>
                    <img
                      onClick={() => {
                        modelHandler(image.big);
                      }}
                      src={image.small}
                      alt="Product"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </PanelBody>
    </Panel>
  );
};

export default React.memo(ProductDescription);
